<template>
    <div class="category-card scale-on-hover" @click="navigateTo(link)">
        <div class="image-cont">
            <img :src="categoryImage" alt="Category Image" loading="lazy" class="category-image">
        </div>
        <div class="card-content">
            <p class="category">
                Kategorie
            </p>
            <p class="category-name">
                {{ category.name }}
            </p>
            <RatingStarsWithCount
                v-if="category.averageRating > 0"
                :total-reviews="category.totalReviews"
                :average-rating="category.averageRating"
                :hide-long-text="true"
            />
            <p class="description">
                {{ category.topSnippet }}
            </p>
            <NuxtLink :to="link" role="button" class="link outline small">
                {{ linkText }}
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
const { category } = defineProps({
    category: { type: Object, required: true }
})
/** The CDN path to the category image */
const SPACES_URL = useRuntimeConfig().public.spacesUrl
const categoryImage = getCategoryImagePath(category.image, SPACES_URL)

const localePath = useLocalePath()
const link = localePath({
    name: 'categoryName',
    params: { categoryName: category.permanentName }
})

const linkText = `${category.totalServices} Angebot${category.totalServices !== 1 ? 'e' : ''} ansehen`
</script>

<style scoped>
.image-cont {
    border-radius: var(--border-radius);
    position: relative;
    overflow: hidden;
    margin-bottom: 0.2rem;
}
.category-image {
    width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}
.category-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    background-color: var(--white);
    box-shadow: var(--small-shadow);
    cursor: pointer;
    color: var(--dark-blue);
}
.card-content {
    flex: 1;
    padding: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
}
.category-name {
    font-size: 1rem;
    font-weight: bold;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.3rem;
}
.category {
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
}
.link {
    margin-top: auto;
}
</style>
