<template>
    <div class="top-action">
        <a role="button" class="outline small" :href="linkToServicePageReviews">
            <img src="/icons/stars/star.svg" alt="Star Icon"> Bewerten
        </a>
        <button class="outline small" @click="toggleShare">
            <img src="assets/icons/share-icons/share-primary.svg" alt="Share Icon"> Teilen
        </button>
        <button class="outline small" @click="toggleFavorites">
            <img :src="favoritesIcon" alt="Heart Icon">
            <span v-if="isFavorite">Gespeichert</span>
            <span v-else>Speichern</span>
        </button>
    </div>
</template>

<script setup>
const { service } = defineProps({
    service: { type: Object, required: true }
})
const { favoritesIcon, isFavorite, toggleFavorites } = useFavoriteService(service)
const localePath = useLocalePath()

const linkToServicePageReviews = `${localePath({
    name: 'provider-name-mobile-serviceName',
    params: {
        name: `${service.business.permanentName}-${service.business.id}`,
        serviceName: `${service.permanentName}-${service.id}`
    }
})}#reviews`

function toggleShare () {
    const text = `Direkt sehen ob ${service?.name} zu Dir kommt, Kundenbewertungen, Kontaktdaten und vieles mehr!` +
                              ' Entdecke alle mobilen Services auf allekommen.at'
    handleShare(text, window.location, `${service?.name} in ${service?.business?.address?.city} auf allekommen.at`)
}
</script>

<style scoped>
.top-action {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.5rem;
}
.top-action button, .top-action a[role="button"] {
    display: grid;
    place-items: center;
}
.top-action img {
    width: 1.6rem;
    height: 1.6rem;
}
</style>
