<template>
    <section>
        <NavigationGoBack />
        <StaticContentLoader class="mt-1" name="Pressebereich-oben" :show-last-changed="false" />
        <section>
            <h2>Schreib uns</h2>
            <p>
                Um Dir schnell und effektiv antworten zu können, bitten wir Dich, das folgende Formular
                auszufüllen.
            </p>
            <NuxtLink role="button" :to="localePath('customer-support-form')">
                Zum Formular
            </NuxtLink>
        </section>

        <section>
            <h2 class="mt-1">
                Ausgewählte Presseberichte
            </h2>
            <div class="press-img-wrapper">
                <a href="https://retailreport.at/noch-penny-chef-maerzinger-startet-mit-locaverseat" target="_blank">
                    <img src="/retail-report-logo.png" alt="allekommen.at im Retail Report" loading="lazy">
                </a>
                <a href="https://www.gewinn.com/artikel/plattform-fuer-mobile-services" target="_blank">
                    <img src="/gewinn-logo.png" alt="allekommen.at im Gewinn Magazin" loading="lazy">
                </a>
                <a href="https://brutkasten.com/artikel/es-ist-vorbei-das-passiert-mit-dem-online-marktplatz-des-ex-penny-oesterreich-chefs" target="_blank">
                    <img src="/brutkasten_logo.png" alt="allekommen.at im Brutkasten" loading="lazy">
                </a>
            </div>
        </section>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
useHead({
    title: 'Presse'
})
</script>

<style scoped>
.press-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    row-gap: calc(2 * var(--spacing));
    column-gap: var(--spacing);
}
.press-img-wrapper img {
    width: 14rem;
}
.block { display: block; }
</style>
