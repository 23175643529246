<template>
    <NuxtLink
        exact
        :to="localePath('index')"
    >
        <img
            class="navbar-logo"
            :src="`${SPACES_URL}/images/logo.svg`"
            alt="allekommen"
        >
    </NuxtLink>
</template>

<script setup>
const localePath = useLocalePath()
const SPACES_URL = useRuntimeConfig().public.spacesUrl
</script>

<style scoped>
.navbar-logo {
    height: 2rem;
    width: 9rem;
}
@media (min-width: 375px) {
    .navbar-logo {
        width: 12rem;
    }
}
</style>
