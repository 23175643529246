export function createUserInfoFormData (userInfo, file) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('userInfo', JSON.stringify(userInfo))
    return formData
}

export function isInternalUser (user) {
    return user?.email.includes('locaverse') || user?.email.includes('allekommen')
}
