<template>
    <img
        class="like"
        :src="hover ? FILLED_HEART_ICON : favoritesIcon"
        loading="lazy"
        alt="Heart Icon"
        @mouseover="setHover(true)"
        @mouseleave="setHover(false)"
    >
</template>

<script setup>
import { FILLED_HEART_ICON } from '~/constants'

defineProps({
    favoritesIcon: { type: String, required: true }
})

const hover = ref(false)
const { isMobile } = useDevice()
function setHover (value) {
    if (isMobile) { return }
    hover.value = value
}
</script>

<style scoped>
.like {
    height: 3rem;
    width: 3rem;
    padding: 0.5rem;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0 var(--border-radius) 0 1rem;
    z-index: 5;
    cursor: pointer;

}
@media (min-width: 360px) {
    .like {
        height: 2.5rem;
        width: 2.7rem;
    }
}
</style>
