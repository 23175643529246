<template>
    <div class="search-bar">
        <img src="/icons/magnifying-glasses/magnifying-glass-solid.svg" alt="Search icon">
        <input
            v-model="tempSearchTerm"
            class="search-input"
            type="search"
            name="search"
            :placeholder="placeholder"
            @input="search"
        >
    </div>
</template>

<script setup>
const { placeholder } = defineProps({
    placeholder: { type: String, default: 'Suchbegriff eingeben' }
})

const searchTerm = defineModel({ type: String, default: '' })
const { tempSearchTerm, searchWithDebounce } = useDebouncedInput()

function search () {
    searchWithDebounce(searchTerm)
}
</script>

<style scoped>
.search-bar {
    position: relative;
}
.search-bar img {
    width: 1rem;
    height: 1rem;
    position: absolute;
    left: 10px;
    top: 10px;
}
.search-input {
    margin: 0;
    padding-left: 2rem;
}
@media (min-width: 768px) {
    .search-bar {
        position: relative;
        width: 30rem;
    }
}
@media (min-width: 1024px) {
    .search-bar {
        position: relative;
        width: 40rem;
    }
}
</style>
