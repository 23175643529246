<template>
    <div class="sort-box">
        <label for="sorting">Sortieren</label>
        <select id="sorting" v-model="sortParameter" class="small" @change="changeSort">
            <option value="recommended">
                Empfohlen
            </option>
            <option value="new">
                Neu
            </option>
            <option value="rating">
                Bestbewertet
            </option>
            <option value="priceAsc">
                Preis: Aufsteigend
            </option>
            <option value="priceDesc">
                Preis: Absteigend
            </option>
        </select>
    </div>
</template>

<script setup>
const sortParameter = ref('recommended')
const route = useRoute()
const router = useRouter()

onMounted(() => {
    sortParameter.value = route.query.sortBy || 'recommended'
})

function changeSort () {
    router.replace({ path: route.path, query: { ...route.query, sortBy: sortParameter.value } })
}
</script>

<style scoped>
label { display: none; font-weight: bold; }
select {
    width: 100%;
    min-width: 11rem;
    margin-bottom: 0;
    cursor: pointer;
}
@media (min-width: 768px) {
    label { font-weight: normal; margin-bottom: 0; display: block; }
    .sort-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 0.5rem;
    }
}
</style>
