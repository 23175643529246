<template>
    <section class="page">
        <NavigationGoBack />
        <h1 class="mt-1">
            Passwort ändern
        </h1>
        <p>
            Hier kannst du dein Passwort ändern. Ein regelmäßiges Ändern deines Passworts erhöht die
            Sicherheit deines Profils!
        </p>
        <form method="post" @submit.prevent="update">
            <label for="current_password">
                Aktuelles Passwort *
                <input
                    id="current_password"
                    v-model="currentPassword"
                    type="password"
                    name="current_password"
                    placeholder="Aktuelles Passwort"
                    required
                >
            </label>
            <label for="password">
                Neues Passwort *
                <input
                    id="password"
                    v-model="newPassword"
                    :aria-invalid="String(passwordsDifferent)"
                    type="password"
                    name="password"
                    placeholder="Neues Passwort"
                    required
                >
                <small v-if="passwordsTooShort" class="input-warning">
                    Passwort muss mindestens 6 Zeichen haben.
                </small>
                <small v-else-if="passwordsDifferent" class="input-warning">
                    Passwörter stimmen nicht überein.
                </small>
            </label>
            <label for="repeat_password">
                Password wiederholen *
                <input
                    id="repeat_password"
                    v-model="repeatPassword"
                    :aria-invalid="String(passwordsDifferent)"
                    type="password"
                    name="repeat_password"
                    placeholder="Neues Passwort wiederholen"
                    required
                >
                <small v-if="passwordsDifferent && !passwordsTooShort" class="input-warning">
                    Passwörter stimmen nicht überein.
                </small>
            </label>
            <button :disabled="!allowSubmit" :aria-busy="loading" type="submit">
                Neues Passwort speichern
            </button>
        </form>
    </section>
</template>

<script setup>
const toast = useToast()

const loading = ref(false)
const newPassword = ref('')
const repeatPassword = ref('')
const currentPassword = ref('')

const passwordsDifferent = computed(() => {
    return newPassword.value !== repeatPassword.value || (newPassword.value === '' && repeatPassword.value === '')
})
const passwordsTooShort = computed(() => {
    return newPassword.value.length < 6
})
const allowSubmit = computed(() => {
    return !passwordsDifferent.value && !passwordsTooShort.value
})

const { $backendApi } = useNuxtApp()
const router = useRouter()
const localePath = useLocalePath()
async function update () {
    if (passwordsDifferent.value) {
        resetInputs()
        return toast.error('Passwörter stimmen nicht überein!')
    }
    if (passwordsTooShort.value) {
        return toast.error('Die Passwörter müssen mindestens 6 zeichen lang sein.')
    }
    loading.value = true
    try {
        await $backendApi('/users/change-password', {
            method: 'PUT',
            body: { newPassword: newPassword.value, currentPassword: currentPassword.value }
        })
        toast.success('Passwort geändert.')
        await router.push(localePath('profile'))
    } catch (error) {
        handleBackendError({ error, toast })
    }
    resetInputs()
    loading.value = false
}
function resetInputs () {
    newPassword.value = ''
    currentPassword.value = ''
    repeatPassword.value = ''
}

definePageMeta({
    middleware: 'auth'
})
useHead({
    title: 'Passwort ändern',
    meta: [{ name: 'robots', content: 'noindex' }]
})
</script>

<style scoped>
.input-warning {
    color: var(--form-element-invalid-border-color);
    font-size: 0.8rem;
}
</style>
