<template>
    <p v-if="pending" class="centered mt-2" aria-busy="true">
        Lade Anbieter ...
    </p>
    <p v-else-if="error" class="centered mt-2">
        Anbieter können nicht angezeigt werden 😔
    </p>
    <section v-else>
        <NavigationBreadCrumbs :breadcrumbs="breadcrumbs" />
        <h1>Anbieter mit mobilen Dienstleistungen und Lieferservices kommen zu dir{{ pageString }}</h1>
        <SearchPaginationLinks
            :total="data.total"
            :page="Number(route.query._page) || 1"
            :per-page="100"
        />
        <ul>
            <li v-for="(item, index) in data.items" :key="index">
                <NuxtLink
                    :to="localePath({
                        name: 'provider-name',
                        params: { name: `${item.permanentName}-${item.id}` }
                    })"
                >
                    {{ item.name }}
                </NuxtLink>
            </li>
        </ul>
        <SearchPaginationLinks
            :total="data.total"
            :page="Number(route.query._page) || 1"
            :per-page="100"
        />
    </section>
</template>

<script setup>
import { ALLEKOMMEN_ROOT } from '~/constants'

const localePath = useLocalePath()
const route = useRoute()
const breadcrumbs = [{ name: 'Alle Anbieter' }]

const { pageString } = useQueryPageString()

const pageQuery = computed(() => route.query._page || 1)
// fetch data from backend
const { data, pending, error } = await useIFetch('/businesses/index', {
    query: { _page: pageQuery }
})
if (error.value) { throw error.value }

useSchemaOrg([
    defineBreadcrumb({
        itemListElement: breadcrumbs
    })
])
useHead({
    title: 'Anbieter mit mobilen Dienstleistungen und Lieferservices kommen zu dir',
    meta: [
        { name: 'description', content: '✓ große Auswahl ✓ Beste Anbieter ✓ Kundenbewertungen ✓ Zuverlässig ✓ Bequem - kommen zu dir ✓ nach Hause ✓ ins Büro ✓ zu Verwandten.' }
    ],
    link: () => {
        // the paginated version is the canonical link
        const pageQuery = route.query._page > 1 ? `?_page=${route.query._page}` : ''
        return { rel: 'canonical', href: `${ALLEKOMMEN_ROOT}${route.path}${pageQuery}` }
    }
})
</script>

<style scoped>
section {
    margin-bottom: 1rem;
}
ul {
    margin-top: 2rem;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
li {
    list-style: none;
}
@media (min-width: 768px) {
    ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: var(--spacing);
    }
}
@media (min-width: 1440px) {
    ul {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
</style>
