<template>
    <LazyBoxWithSlot class="mb-1" :styles="{'border-top': '8px solid var(--red)', 'background-color': 'var(--yellow-100)'}">
        <template #content>
            <div class="space-between">
                <span><b>Achtung:</b> dieses Angebot ist aktuell nicht mehr verfügbar!</span>
                <NuxtLink
                    :to="localePath({ name: 'categoryName', params: { categoryName: categoryName } })"
                    role="button"
                >
                    Alternative finden
                    <img src="/icons/magnifying-glasses/magnifying-glass-white.svg" alt="Suchen">
                </NuxtLink>
            </div>
        </template>
    </LazyBoxWithSlot>
</template>

<script setup>
defineProps({
    categoryName: { type: String, required: true }
})
const localePath = useLocalePath()
</script>

<style scoped>
.space-between {
    flex-wrap: wrap;
    row-gap: var(--spacing);
}
.space-between a img {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: -0.2rem;
    margin-left: 0.2rem;
}
@media (min-width: 1024px) {
    .space-between { flex-wrap: nowrap; column-gap: var(--spacing); }
    .space-between a {
        min-width: 12rem;
    }
}
</style>
