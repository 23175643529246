<template>
    <div class="top-categories">
        <!-- TODO: change once all categories are added -->
        <p
            v-if="locale === 'at'"
            class="hamburger"
            @click="searchStore.setSidebarOpen(true)"
        >
            <img
                class="hamburger-icon"
                src="/assets/icons/bars-solid.svg"
                alt="Hamburger menu"
                loading="lazy"
            >
            <span>Alle</span>
        </p>
        <NuxtLink
            v-for="category in categories[locale]"
            :key="category.name"
            :to="localePath({
                name: 'categoryName',
                params: { categoryName: category.permanentName } }
            )"
        >
            {{ category.name }}
            <LazyActionTag v-if="category.action" />
            <LazyNewTag v-if="category.new" />
        </NuxtLink>
    </div>
</template>

<script setup>
const locale = useRuntimeConfig().public.locale

const localePath = useLocalePath()
const categories = {
    at: [
        { name: 'Essen bestellen', permanentName: 'essen-bestellen' },
        { name: 'Lebensmittel bestellen', permanentName: 'lebensmittel-bestellen', new: true },
        { name: 'Haushaltshilfe', permanentName: 'haushaltshilfe', new: true },
        { name: 'Personenbetreuung', permanentName: 'personenbetreuung' },
        { name: 'Nachhilfe', permanentName: 'nachhilfe', action: true },
        { name: 'Kinderbetreuung', permanentName: 'mobile-kinderbetreuung', action: true },
        { name: 'Tierbetreuung', permanentName: 'tierbetreuung' }
    ],
    de: [
        { name: 'Haushaltshilfe', permanentName: 'haushaltshilfe', new: true },
        { name: 'Essen bestellen', permanentName: 'essen-bestellen', new: true },
        { name: 'Blumen liefern', permanentName: 'blumen-liefern-lassen', new: true },
        { name: 'Lebensmittel bestellen', permanentName: 'lebensmittel-bestellen' },
        { name: 'Babysitter', permanentName: 'babysitter', new: true }
    ]
}

const searchStore = useSearchStore()
</script>

<style scoped>
.top-categories {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.top-categories::-webkit-scrollbar {
    display: none;
}
.top-categories {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: space-between;
    align-items: center;
    mask-image: linear-gradient(to right, transparent, black 0, black 90%, transparent);
    padding-right: 2.5rem;
}
.top-categories a {
    color: var(--white);
    min-width: fit-content;
    text-decoration: none;
    padding: 0.5rem;
}
.hamburger {
    display: flex;
    align-items: center;
    gap: calc(var(--spacing) * 0.7);
    color: var(--white);
    margin: 0;
    padding: 0.5rem;
    min-width: fit-content;
    cursor: pointer;
}
.top-categories a,
.hamburger {
    border: 1px solid var(--dark-blue);
    border-radius: var(--border-radius);
    transition: 0.3s;
}
.top-categories a:hover,
.hamburger:hover {
    border: 1px solid var(--white);
}
.hamburger img {
    width: 1.5rem;
}
@media (min-width: 576px) {
    .hamburger {
        gap: 0.3rem;
        padding: 0.4rem;
    }
    .top-categories a {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
    }
}
/*@media (min-width: 1440px) {
    .top-categories {
        mask-image: linear-gradient(to right, transparent, black 0, black 95%, transparent);
        padding-right: 2rem;
    }
}*/
@media (min-width: 1440px) {
    .top-categories {
        mask-image: none;
        padding-right: 0;
    }
}
</style>
