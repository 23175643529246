<template>
    <div>
        <h3>Sei dabei und verpasse keine Neuigkeiten! </h3>
        <div class="form-container">
            <p>
                Melde dich für unseren allekommen.at Newsletter an und entdecke spannende Aktionen,
                innovative Features und wertvolle Tipps. Gemeinsam gestalten wir die Zukunft!
            </p>
            <div>
                <form class="newsletter-form" @submit.prevent="subscribe">
                    <input v-model="email" type="email" name="email" placeholder="Deine E-Mail" required>
                    <button :aria-busy="loading" type="submit">
                        <span v-if="!loading">Jetzt anmelden</span>
                        <span v-else>Anmeldung</span>
                    </button>
                </form>
                <i>Abmeldung jederzeit möglich.</i>
            </div>
        </div>
    </div>
</template>

<script setup>
const { $backendApi } = useNuxtApp()

const toast = useToast()
const email = ref('')
const loading = ref(false)

async function subscribe () {
    loading.value = true
    try {
        await $backendApi('/hubspot/subscribe-to-newsletter', {
            method: 'POST',
            body: { email: email.value, userType: 'user' }
        })
        toast.success('Newsletter erfolgreich abonniert!')
    } catch (error) {
        const errorMsg = error.response ? error.response._data.msg : error
        toast.error(`Anfrage fehlgeschlagen. ${errorMsg}`)
    }
    loading.value = false
}
</script>

<style scoped>
input[type="email"], button {
    margin-bottom: 0;
}
input[type="email"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
button {
    padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 14rem;
}
button span {
    font-size: 0.9rem;
}
.newsletter-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
@media (min-width: 1024px) {
    .form-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: var(--spacing);
        width: calc(100% - var(--spacing));
    }
}
</style>
