<template>
    <div class="card-wrapper">
        <div v-if="showQuickView" class="top-overlay" @click="toggleQuickView" />
        <ServiceQuickView
            :service="service"
            :image="image"
            :service-page-link="link"
            :show="showQuickView"
            @close="toggleQuickView"
        />
        <div class="service-card scale-on-hover" @click="useClickout(service.bookingLink, service.trackingLink)">
            <div class="image-cont">
                <FavoritesIcon :favorites-icon="favoritesIcon" @click.stop.prevent="toggleFavorites" />
                <img :src="image" :alt="service.name" :loading="noLazyLoad ? 'eager' : 'lazy'" class="service-image">
                <span v-if="service.hasCoupons || service.provider === 'idealo-deals'" class="angebot">Angebot</span>
                <span v-if="!service.mainImage" class="demo-image">Symbolbild</span>
            </div>
            <div class="service-card-content">
                <p v-if="service.minPrice">
                    <strong>ab € {{ service.minPrice.toFixed(2) }}</strong>
                </p>
                <p role="link" class="service-name">
                    {{ service.name }}
                    <ServiceSustainabilityBadge v-if="service.business.sustainabilityDescription" />
                </p>
                <p v-if="showBusinessName" class="business-name-text">
                    {{ service.business.name }}
                </p>
            </div>
            <div class="mt-auto service-card-content">
                <RatingStarsWithCount
                    v-if="service.totalReviews > 0"
                    :total-reviews="service.totalReviews"
                    :average-rating="service.rating"
                    :hide-long-text="true"
                />
                <ServiceComingToYouIndicator :comes-to-you="Boolean(service.comesToYou)" />
                <div class="buttons" @click.stop.prevent>
                    <NuxtLink v-if="['allekommen', 'lieferando'].includes(service.provider)" :to="link" role="button" class="outline small">
                        <span class="details-link">Zur</span> Detailseite
                    </NuxtLink>
                    <button v-else class="outline small" @click="navigateTo(link)">
                        <span class="details-link">Zur</span> Detailseite
                    </button>
                    <InfoIcon @click="toggleQuickView" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    service: { type: Object, required: true },
    noLazyLoad: { type: Boolean, required: false, default: false }
})

const { favoritesIcon, toggleFavorites } = useFavoriteService(props.service)
const showQuickView = ref(false)

const SPACES_URL = useRuntimeConfig().public.spacesUrl
const image = getServiceImagePath(props.service, SPACES_URL)
const link = getServicePageLink(props.service)

const showBusinessName = computed(() => {
    return props.service.provider === 'allekommen' && !['myhammer'].includes(props.service.business.permanentName)
})

function toggleQuickView () {
    showQuickView.value = !showQuickView.value
    showQuickView.value === true
        ? document.body.classList.add('no-scroll')
        : document.body.classList.remove('no-scroll')
}
</script>

<style scoped>
a { text-decoration: none; }
p[role=link] { color: var(--color); }
p { margin-bottom: 0; color: var(--dark-blue); }
.image-cont {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    position: relative;
}
.service-card {
    height: 100%;
    display: grid;
    border-radius: var(--border-radius);
    background-color: var(--white);
    box-shadow: var(--small-shadow);
    cursor: pointer;
    color: var(--dark-blue);
}
.service-card-content {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
.service-name {
    font-size: 1rem;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.business-name-text {
    margin-bottom: auto;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.demo-image {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: calc(0.6 * var(--font-size));
    background-color: var(--white);
    border-radius: var(--border-radius) 0 0 0;
    padding: 0 0.2rem;
}
.mt-auto { margin-top: auto; }
.angebot {
    display: grid;
    place-items: center;
    background-color: var(--red);
    color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    width: 5rem;
    height: 1.7rem;
    text-align: center;
    font-weight: bold;
    border-radius: var(--border-radius) 0 var(--border-radius) 0;
}
.service-image {
    width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}
.buttons {
    display: grid;
    grid-template-columns: auto 2rem;
    column-gap: 0.3rem;
    align-items: center;
}
.top-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 3rem;
}
@media (min-width: 360px) {
    .details-link { display: none; }
}
@media (min-width: 375px) {
    .details-link { display: inline; }
}
@media (min-width: 1024px) {
    .top-overlay {
        height: 100%;
    }
}
</style>
