<template>
    <CategoryFilteredOverviewPage
        category-type="home-visit"
        h1="Finde mobile Dienstleister in der Nähe, die Hausbesuche anbieten"
        top-snippet="Entdecke Dienstleistungen zahlreicher Kategorien für Hausbesuche"
    />
</template>

<script setup>
const metaTitle = 'Hausbesuche in der Nähe'
const metaDescription = 'Mobile Dienstleister für Hausbesuche in der Nähe'
useHead({
    title: metaTitle,
    meta: [{ name: 'description', content: metaDescription }]
})
</script>

<style scoped>

</style>
