<template>
    <section>
        <h1>Datenschutzerklärung</h1>
        <section>
            <h2>
                Einleitung und Überblick
            </h2>
            <p>
                Wir haben diese Datenschutzerklärung (Fassung 24.04.2023-122482172) verfasst, um
                Ihnen gemäß der Vorgaben der <a
                    href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=122482172#d1e2269-1-1"
                    target="_blank"
                >Datenschutz-Grundverordnung (EU) 2016/679</a> und
                anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz
                Daten) wir als Verantwortliche – und die von uns beauftragten Auftragsverarbeiter (z. B.
                Provider) – verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen
                Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen. <br>
                <strong>Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.
            </p>
            <p>
                Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische
                Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so
                einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist,
                werden technische <strong>Begriffe leserfreundlich erklärt</strong>, Links zu weiterführenden
                Informationen geboten und <strong>Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer
                und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann
                personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage
                gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und
                juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es
                um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und
                informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht
                kannten. <br>
                Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im
                Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen
                und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie
                selbstverständlich auch im Impressum.
            </p>
        </section>
        <section>
            <h2>
                Anwendungsbereich
            </h2>
            <p>
                Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten
                personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte
                Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir
                Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse
                und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt
                dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei
                es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:
            </p>
            <ul>
                <li>alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
                <li>Social Media Auftritte und E-Mail-Kommunikation</li>
                <li>mobile Apps für Smartphones und andere Geräte</li>
            </ul>
            <p>
                <strong>Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene
                Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten
                wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie
                gegebenenfalls gesondert informieren.
            </p>
        </section>
        <section>
            <h2>
                Rechtsgrundlagen
            </h2>
            <p>
                In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den
                rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der
                Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu
                verarbeiten.
                Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES
                EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese
                Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex,
                dem Zugang zum EU-Recht, unter
                <a
                    href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679"
                    target="_blank"
                >https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679 nachlesen.</a>
            </p>
            <p>
                Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:
            </p>
            <div
                class="recht"
                style="padding-left: 1.5rem;"
            >
                <p>
                    11.. <strong>Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung
                    gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die
                    Speicherung Ihrer eingegebenen Daten eines Kontaktformulars. <br>
                    22.. <strong>Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche
                    Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum
                    Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab
                    personenbezogene Informationen. <br>
                    33.. <strong>Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer
                    rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind
                    wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese
                    enthalten in der Regel personenbezogene Daten. <br>
                    44.. <strong>Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter
                    Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die
                    Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse
                    Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu
                    können. Diese Verarbeitung ist somit ein berechtigtes Interesse.
                </p>
            </div>
            <p>
                Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und
                Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in
                der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird
                diese an der entsprechenden Stelle ausgewiesen.
            </p>
            <p>
                Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:
            </p>
            <ul>
                <li>
                    In <strong>Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der
                    Verarbeitung personenbezogener Daten <strong>(Datenschutzgesetz)</strong>, kurz <strong>DSG</strong>.
                </li>
                <li>
                    In <strong>Deutschland</strong> gilt das <strong>Bundesdatenschutzgesetz</strong>, kurz <strong>BDSG</strong>.
                </li>
            </ul>
            <p>
                Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir
                Sie in den folgenden Abschnitten darüber.
            </p>
        </section>
        <section>
            <h2>
                Kontaktdaten des Verantwortlichen
            </h2>
            <p>
                Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten
                haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle: <br> <br>
                <strong>Michael Pisnyachevskiy</strong> <br>
                E-Mail: <a href="mailto:michael@allekommen.at">michael@allekommen.at</a> <br>
                Telefon: +4367761683900 <br>
                <NuxtLink :to="localePath('imprint')">
                    Impressum
                </NuxtLink>
            </p>
        </section>
        <section>
            <h2>
                Speicherdauer
            </h2>
            <p>
                Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung
                unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium
                bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die
                Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu
                verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern,
                zum Beispiel zu Zwecken der Buchführung.
            </p>
            <p>
                Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung
                widerrufen, werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung
                besteht, gelöscht.
            </p>
            <p>
                Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten,
                sofern wir weitere Informationen dazu haben.
            </p>
        </section>
        <section>
            <h2>
                Rechte laut Datenschutz-Grundverordnung
            </h2>
            <p>
                Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen
                zustehen, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:
            </p>
            <ul>
                <li>
                    Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen
                    verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu
                    erhalten und die folgenden Informationen zu erfahren:
                    <ul>
                        <li>zu welchem Zweck wir die Verarbeitung durchführen;</li>
                        <li>die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
                        <li>
                            wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden,
                            wie die Sicherheit garantiert werden kann;
                        </li>
                        <li>
                            wie lange die Daten gespeichert werden;
                        </li>
                        <li>
                            das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung
                            der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;
                        </li>
                        <li>
                            dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu
                            diesen Behörden finden Sie weiter unten);
                        </li>
                        <li>die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
                        <li>
                            ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet
                            werden, um zu einem persönlichen Profil von Ihnen zu gelangen.
                        </li>
                    </ul>
                </li>
                <li>
                    Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was
                    bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.
                </li>
                <li>
                    Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
                    Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten
                    verlangen dürfen.
                </li>
                <li>
                    Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung,
                    was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter
                    verwenden.
                </li>
                <li>
                    Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet,
                    dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung
                    stellen.
                </li>
                <li>
                    Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach
                    Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
                    <ul>
                        <li>
                            Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches
                            Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f
                            (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung
                            Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir
                            diesem Widerspruch rechtlich nachkommen können.
                        </li>
                        <li>
                            Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie
                            jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen
                            Ihre Daten danach nicht mehr für Direktmarketing verwenden.
                        </li>
                        <li>
                            Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit
                            gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten
                            danach nicht mehr für Profiling verwenden.
                        </li>
                    </ul>
                </li>
                <li>
                    Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer
                    ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling)
                    beruhenden Entscheidung unterworfen zu werden.
                </li>
                <li>
                    Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können
                    sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind,
                    dass die Datenverarbeitung von personenbezogenen Daten gegen die DSGVO
                    verstößt.
                </li>
            </ul>
            <p>
                <strong>Kurz gesagt:</strong> Sie haben Rechte – zögern Sie nicht, die oben gelistete verantwortliche Stelle
                bei uns zu kontaktieren!
            </p>
            <p>
                Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt
                oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind,
                können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die
                Datenschutzbehörde, deren Website Sie unter <a
                    href="https://www.dsb.gv.at/"
                    target="_blank"
                >https://www.dsb.gv.at/</a> finden. In Deutschland
                gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen
                können Sie sich an die <a
                    href="https://www.bfdi.bund.de/DE/Home/home_node.html"
                    target="_blank"
                >
                    Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
                    (BfDI)
                </a> wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde
                zuständig:
            </p>
            <h2>
                Österreichische Datenschutzbehörde
            </h2>
            <p>
                <strong>Leiterin:</strong> Mag. Dr. Andrea Jelinek <br>
                <strong>Adresse:</strong> Barichgasse 40-42, 1030 Wien <br>
                <strong>Telefonnr.:</strong> +43 1 52 152-0 <br>
                <strong>E-Mail-Adresse:</strong> <a href="mailto:dsb@dsb.gv.at">dsb@dsb.gv.at</a> <br>
                <strong>Website:</strong> <a
                    href="https://www.dsb.gv.at/"
                    target="_blank"
                >https://www.dsb.gv.at/</a>
            </p>
        </section>
        <section>
            <h2>
                Sicherheit der Datenverarbeitung
            </h2>
            <p>
                Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch
                organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln oder
                pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen
                unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf
                persönliche Informationen schließen können.
            </p>
            <p>
                Art. 25 DSGVO spricht hier von “Datenschutz durch Technikgestaltung und durch
                datenschutzfreundliche Voreinstellungen” und meint damit, dass man sowohl bei Software
                (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit
                denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich,
                noch auf konkrete Maßnahmen ein.
            </p>
        </section>
        <section>
            <h2>TLS-Verschlüsselung mit https</h2>
            <p>
                TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden
                HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres
                Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu übertragen.
                Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem
                Webserver abgesichert ist – niemand kann “mithören”.
            </p>
            <p>
                Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen den Datenschutz
                durch Technikgestaltung <a
                    href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=122482172"
                    target="_blank"
                >(Artikel 25 Absatz 1 DSGVO)</a>. Durch den Einsatz von TLS
                (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung
                im Internet, können wir den Schutz vertraulicher Daten sicherstellen.
                Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen
                Schlosssymbol links oben im Browser, links von der Internetadresse (z. B.
                beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer
                Internetadresse. <br>
                Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google
                Suche nach “Hypertext Transfer Protocol Secure wiki” um gute Links zu weiterführenden
                Informationen zu erhalten.
            </p>
        </section>
        <section>
            <h2>
                Kommunikation
            </h2>
            <p class="with-border">
                <strong>Kommunikation Zusammenfassung</strong> <br> <br>
                👥 Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren <br>
                📓 Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene <br>
                Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart <br>
                🤝 Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw. <br>
                📅 Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften <br>
                ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b <br>
                DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
            </p>
            <p>
                Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular
                kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen. <br>
                Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit
                zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während eben solange
                gespeichert bzw. solange es das Gesetz vorschreibt.
            </p>
            <h3>
                Betroffene Personen
            </h3>
            <p>
                Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten
                Kommunikationswege den Kontakt zu uns suchen.
            </p>
            <h3>
                Telefon
            </h3>
            <p>
                Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim
                eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können
                Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur
                Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der
                Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
            </p>
            <h3>
                E-Mail
            </h3>
            <p>
                Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem
                jeweiligen Endgerät (Computer, Laptop, Smartphone,…) gespeichert und es kommt zur
                Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der
                Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
            </p>
            <h3>
                Online Formulare
            </h3>
            <p>
                Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem
                Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet.
                Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche
                Vorgaben erlauben.
            </p>
            <h3>
                Rechtsgrundlagen
            </h3>
            <p>
                Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:
            </p>
            <ul>
                <li>
                    Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten
                    zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;
                </li>
                <li>
                    Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung
                    eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem
                    Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B.
                    die Vorbereitung eines Angebots, verarbeiten;
                </li>
                <li>
                    Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und
                    geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind
                    gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server
                    und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu
                    können.
                </li>
            </ul>
        </section>
        <section>
            <h2>
                Auftragsverarbeitungsvertrag (AVV)
            </h2>
            <p>
                In diesem Abschnitt möchten wir Ihnen erklären, was ein Auftragsverarbeitungsvertrag ist
                und warum dieser benötigt wird. Weil das Wort “Auftragsverarbeitungsvertrag” ein ziemlicher
                Zungenbrecher ist, werden wir hier im Text auch öfters nur das Akronym AVV benutzen. Wie
                die meisten Unternehmen arbeiten wir nicht alleine, sondern nehmen auch selbst
                Dienstleistungen anderer Unternehmen oder Einzelpersonen in Anspruch. Durch die
                Einbeziehung verschiedener Unternehmen bzw. Dienstleister kann es sein, dass wir
                personenbezogene Daten zur Verarbeitung weitergeben. Diese Partner fungieren dann als
                Auftragsverarbeiter, mit denen wir einen Vertrag, den sogenannten
                Auftragsverarbeitungsvertrag (AVV), abschließen. Für Sie am wichtigsten zu wissen ist, dass
                die Verarbeitung Ihrer personenbezogenen Daten ausschließlich nach unserer Weisung
                erfolgt und durch den AVV geregelt werden muss.
            </p>
            <h3>
                Wer sind Auftragsverarbeiter?
            </h3>
            <p>
                Wir sind als Unternehmen und Websiteinhaber für alle Daten, die wir von Ihnen verarbeiten
                verantwortlich. Neben den Verantwortlichen kann es auch sogenannte Auftragsverarbeiter
                geben. Dazu zählt jedes Unternehmen bzw. jede Person, die in unserem Auftrag
                personenbezogene Daten verarbeitet. Genauer und nach der DSGVO-Definition gesagt:
                jede natürliche oder juristische Person, Behörde, Einrichtung oder eine andere Stelle, die in
                unserem Auftrag personenbezogene Daten verarbeitet, gilt als Auftragsverarbeiter.
                Auftragsverarbeiter können folglich Dienstleister wie Hosting- oder Cloudanbieter,
                Bezahlungs- oder Newsletter-Anbieter oder große Unternehmen wie beispielsweise Google
                oder Microsoft sein.
            </p>
            <p>
                Zur besseren Verständlichkeit der Begrifflichkeiten hier ein Überblick über die drei Rollen in
                der DSGVO:
            </p>
            <strong>Betroffener</strong> (Sie als Kunde oder Interessent) → <strong>Verantwortlicher</strong> (wir als Unternehmen
            und Auftraggeber) → <strong>Auftragsverarbeiter</strong> (Dienstleister wie z. B. Webhoster oder
            Cloudanbieter)
            <h3>
                Inhalt eines Auftragsverarbeitungsvertrages
            </h3>
            <p>
                Wie bereits oben erwähnt, haben wir mit unseren Partnern, die als Auftragsverarbeiter
                fungieren, einen AVV abgeschlossen. Darin wird allen voran festgehalten, dass der
                Auftragsverarbeiter die zu bearbeitenden Daten ausschließlich gemäß der DSGVO
                verarbeitet. Der Vertrag muss schriftlich abgeschlossen werden, allerdings gilt in diesem
                Zusammenhang auch der elektronische Vertragsabschluss als „schriftlich“. Erst auf der
                Grundlage des Vertrags erfolgt die Verarbeitung der personenbezogenen Daten. Im Vertrag
                muss folgendes enthalten sein:
            </p>
            <ul>
                <li>Bindung an uns als Verantwortlichen</li>
                <li>Pflichten und Rechte des Verantwortlichen</li>
                <li>Kategorien betroffener Personen</li>
                <li>Art der personenbezogenen Daten</li>
                <li>Art und Zweck der Datenverarbeitung</li>
                <li>Gegenstand und Dauer der Datenverarbeitung</li>
                <li>Durchführungsort der Datenverarbeitung</li>
            </ul>
            <p>
                Weiters enthält der Vertrag alle Pflichten des Auftragsverarbeiters. Die wichtigsten Pflichten
                sind:
            </p>
            <ul>
                <li>Maßnahmen zur Datensicherheit zu gewährleisten</li>
                <li>
                    mögliche technische und organisatorischen Maßnahmen zu treffen, um die Rechte
                    der betroffenen Person zu schützen
                </li>
                <li>ein Daten-Verarbeitungsverzeichnis zu führen</li>
                <li>auf Anfrage der Datenschutz-Aufsichtsbehörde mit dieser zusammenzuarbeiten</li>
                <li>
                    eine Risikoanalyse in Bezug auf die erhaltenen personenbezogenen Daten
                    durchzuführen
                </li>
                <li>
                    Sub-Auftragsverarbeiter dürfen nur mit schriftlicher Genehmigung des
                    Verantwortlichen beauftragt werden
                </li>
            </ul>
        </section>
        <section>
            <h2>
                Webhosting Einleitung
            </h2>
            <p class="with-border">
                <strong>Webhosting Zusammenfassung</strong> <br> <br>
                👥 Betroffene: Besucher der Website <br>
                🤝 Zweck: professionelles Hosting der Website und Absicherung des Betriebs <br>
                📓 Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser <br>
                und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils
                eingesetzten Webhosting Provider. <br>
                📅 Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen <br>
                ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
            </p>
            <h3>
                Was ist Webhosting?
            </h3>
            <p>
                Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen – auch
                personenbezogene Daten – automatisch erstellt und gespeichert, so auch auf dieser
                Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet
                werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain,
                d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier).
                Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.
            </p>
            <p>
                Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen möchten,
                verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich
                einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und
                Apple Safari. Wir sagen kurz Browser oder Webbrowser dazu.
            </p>
            <p>
                Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer verbinden,
                wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist
                eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen
                Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit
                für eine verlässliche und fehlerfreie Speicherung der Daten von Websites. Eine ganze
                Menge Fachbegriffe, aber bitte bleiben Sie dran, es wird noch besser!
            </p>
            <p>
                Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Tablet
                oder Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu
                einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer
                Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen
                ordentlichen Betrieb zu gewährleisten.
            </p>
            <p>
                Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur Veranschaulichung
                das Zusammenspiel zwischen Browser, dem Internet und dem Hosting-Provider.
            </p>
            <h3>
                Warum verarbeiten wir personenbezogene Daten?
            </h3>
            <p>
                Die Zwecke der Datenverarbeitung sind:
            </p>
            <p style="padding: 1.5rem;">
                11..Professionelles Hosting der Website und Absicherung des Betriebs <br>
                22.. zur Aufrechterhaltung der Betriebs- und IT-Sicherheit <br>
                33.. Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres <br>
                Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen
            </p>
            <h3>
                Welche Daten werden verarbeitet?
            </h3>
            <p>
                Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das
                ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten
                wie
            </p>
            <ul>
                <li>die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
                <li>
                    Browser und Browserversion (z. B. Chrome 87)
                </li>
                <li>das verwendete Betriebssystem (z. B. Windows 10)</li>
                <li>
                    die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.
                    <a
                        href="https://www.beispielquellsite.de/vondabinichgekommen/"
                        target="_blank"
                    >https://www.beispielquellsite.de/vondabinichgekommen/</a>)
                </li>
                <li>
                    den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z.
                    B. COMPUTERNAME und 194.23.43.121)
                </li>
                <li>
                    Datum und Uhrzeit
                </li>
                <li>in Dateien, den sogenannten Webserver-Logfiles</li>
            </ul>
            <h3>
                Wie lange werden Daten gespeichert?
            </h3>
            <p>
                In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach
                automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht
                ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden
                eingesehen werden.
            </p>
            <p>
                <strong>Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf
                speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht
                ohne Zustimmung weiter!
            </p>
            <h3>
                Rechtsgrundlage
            </h3>
            <p>
                Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des
                Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten
                Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist
                notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und
                Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.
            </p>
            <p>
                Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag über die
                Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz
                gewährleistet und Datensicherheit garantiert
            </p>
        </section>
        <section>
            <h2>
                Webhosting-Provider Extern Datenschutzerklärung
            </h2>
            <p>
                Nachfolgend finden Sie die Kontaktdaten unseres externen Hosting-Providers, wo Sie,
                zusätzlich zu den Informationen oben, mehr zur Datenverarbeitung erfahren können:
            </p>
            <p>
                Microsoft Azure
            </p>
            <p>
                Diese Webseite nutzt eine Cloud-Plattform für virtuelle Server von Microsoft Azure. Der Serverstandort ist eine ISO/IEC 27001 zertifiziertes
                Rechenzentrum in Frankfurt, in welchem Microsoft Azure Stellplätze anmietet.
            </p>
            <p>
                Zur Identifikation von Benutzern der Webseite werden personenbezogene Daten wie
                E-Mail-Adresse und Name, für die Autorisierung die E-Mail-Adresse via Redis Cache und
                Logs mit IP Adressen im Rechenzentrum in Frankfurt verarbeitet und gespeichert.
            </p>
            <p>
                Der Einsatz von Microsoft Azure beruht auf unserem berechtigten Interesse an einer möglichst
                fehlerfreien und sicheren Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit.f DSGVO).
                Die Datenübertragung in die USA wird auf die neuen Standardvertragsklauseln der
            </p>
        </section>
        <section>
            <h2>
                E-Mail-Marketing Einleitung
            </h2>
            <p class="with-border">
                <strong>E-Mail-Marketing Zusammenfassung</strong> <br> <br>
                👥 Betroffene: Newsletter-Abonnenten <br>
                🤝 Zweck: Direktwerbung per E-Mail, Benachrichtigung über systemrelevante Ereignisse <br>
                📓 Verarbeitete Daten: Eingegebene Daten bei der Registrierung jedoch mindestens die
                E-Mail-Adresse. Mehr Details dazu finden Sie beim jeweils eingesetzten
                E-Mail-Marketing-Tool. <br>
                📅 Speicherdauer: Dauer des Bestehens des Abonnements <br>
                ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO
                (berechtigte Interessen)
            </p>
            <h3>
                Was ist E-Mail-Marketing?
            </h3>
            <p>
                Um Sie stets auf dem Laufenden zu halten, nutzen wir auch die Möglichkeit des
                E-Mail-Marketings. Dabei werden, sofern Sie dem Empfang unserer E-Mails bzw. Newsletter
                zugestimmt haben, auch Daten von Ihnen verarbeitet und gespeichert. E-Mail-Marketing ist
                ein Teilbereich des Online-Marketings. Dabei werden Neuigkeiten oder allgemeine
                Informationen über ein Unternehmen, Produkte oder Dienstleistungen per E-Mail an eine
                bestimmte Gruppe an Menschen, die sich dafür interessieren, gesendet.
            </p>
            <p>
                Wenn Sie an unserem E-Mail-Marketing (meist per Newsletter) teilnehmen wollen, müssen
                Sie sich im Normalfall einfach nur mit Ihrer E-Mail-Adresse anmelden. Dafür füllen Sie ein
                Online-Formular aus und senden es ab. Es kann aber auch vorkommen, dass wir Sie etwa
                um Ihre Anrede und Ihren Namen bitten, damit wir Sie auch persönlich anschreiben können.
            </p>
            <p>
                Grundsätzlich funktioniert das Anmelden zu Newslettern mit Hilfe des sogenannten
                „Double-Opt-In-Verfahrens“. Nachdem Sie sich für unseren Newsletter auf unserer Website
                angemeldet haben, bekommen Sie eine E-Mail, über die Sie die Newsletter-Anmeldung
                bestätigen. So wird sichergestellt, dass Ihnen die E-Mail-Adresse gehört und sich niemand
                mit einer fremden E-Mail-Adresse angemeldet hat. Wir oder ein von uns verwendetes
                Benachrichtigungs-Tool protokolliert jede einzelne Anmeldung. Dies ist nötig, damit wir den
                rechtlich korrekten Anmeldevorgang auch nachweisen können. Dabei wird in der Regel der
                Zeitpunkt der Anmeldung, der Zeitpunkt der Anmeldebestätigung und Ihre IP-Adresse
                gespeichert. Zusätzlich wird auch protokolliert, wenn Sie Änderungen Ihrer gespeicherten
                Daten vornehmen.
            </p>
            <h3>
                Warum nutzen wir E-Mail-Marketing?
            </h3>
            <p>
                Wir wollen natürlich mit Ihnen in Kontakt bleiben und Ihnen stets die wichtigsten Neuigkeiten
                über unser Unternehmen präsentieren. Dafür nutzen wir unter anderem E-Mail-Marketing –
                oft auch nur “Newsletter” bezeichnet – als wesentlichen Bestandteil unseres
                Online-Marketings. Sofern Sie sich damit einverstanden erklären oder es gesetzlich erlaubt
                ist, schicken wir Ihnen Newsletter, System-E-Mails oder andere Benachrichtigungen per
                E-Mail. Wenn wir im folgenden Text den Begriff „Newsletter“ verwenden, meinen wir damit
                hauptsächlich regelmäßig versandte E-Mails. Natürlich wollen wir Sie mit unseren
                Newsletter in keiner Weise belästigen. Darum sind wir wirklich stets bemüht, nur relevante
                und interessante Inhalte zu bieten. So erfahren Sie etwa mehr über unser Unternehmen,
                unsere Leistungen oder Produkte. Da wir unsere Angebote auch immer verbessern,
                erfahren Sie über unseren Newsletter auch immer, wenn es Neuigkeiten gibt oder wir gerade
                spezielle, lukrative Aktionen anbieten. Sofern wir einen Dienstleister, der ein professionelles
                Versand-Tool anbietet, für unser E-Mail-Marketing beauftragen, machen wir das, um Ihnen
                schnelle und sichere Newsletter bieten zu können. Zweck unseres E-Mail-Marketings ist
                grundsätzlich, Sie über neue Angebote zu informieren und auch unseren unternehmerischen
                Zielen näher zu kommen.
            </p>
            <h3>
                Welche Daten werden verarbeitet?
            </h3>
            <p>
                Wenn Sie über unsere Website Abonnent unseres Newsletters werden, bestätigen Sie per
                E-Mail die Mitgliedschaft in einer E-Mail-Liste. Neben IP-Adresse und E-Mail-Adresse
                können auch Ihre Anrede, Ihr Name, Ihre Adresse und Ihre Telefonnummer gespeichert
                werden. Allerdings nur, wenn Sie dieser Datenspeicherungen zustimmen. Die als solche
                markierten Daten sind notwendig, damit Sie an dem angebotenen Dienst teilnehmen
                können. Die Angabe ist freiwillig, die Nichtangabe führt jedoch dazu, dass Sie den Dienst
                nicht nutzen können. Zusätzlich können etwa auch Informationen zu Ihrem Gerät oder zu
                Ihren bevorzugten Inhalten auf unserer Website gespeichert werden. Mehr zur Speicherung
                von Daten, wenn Sie eine Website besuchen, finden Sie im Abschnitt “Automatische
                Datenspeicherung”. Ihre Einwilligungserklärung zeichnen wir auf, damit wir stets nachweisen
                können, dass dieser unseren Gesetzen entspricht.
            </p>
            <h3>
                Dauer der Datenverarbeitung
            </h3>
            <p>
                Wenn Sie Ihre E-Mail-Adresse aus unserem E-Mail/Newsletter-Verteiler austragen, dürfen
                wir Ihre Adresse bis zu drei Jahren auf Grundlage unserer berechtigten Interessen
                speichern, damit wir Ihre damalige Einwilligung noch nachweisen können. Verarbeiten
                dürfen wir diese Daten nur, wenn wir uns gegen etwaige Ansprüche wehren müssen.
            </p>
            <p>
                Wenn Sie allerdings bestätigen, dass Sie uns die Einwilligung zur Newsletter-Anmeldung
                gegeben haben, können Sie jederzeit einen individuellen Löschantrag stellen.
                Widersprechen Sie der Einwilligung dauerhaft, behalten wir uns das Recht vor, Ihre
                E-Mail-Adresse in einer Sperrliste zu speichern. Solange Sie freiwillig unseren Newsletter
                abonniert haben, solange behalten wir selbstverständlich auch Ihre E-Mail-Adresse.
            </p>
            <h3>
                Widerspruchsrecht
            </h3>
            <p>
                Sie haben jederzeit die Möglichkeit Ihre Newsletter-Anmeldung zu kündigen. Dafür müssen
                Sie lediglich Ihre Einwilligung zur Newsletter-Anmeldung widerrufen. Das dauert im
                Normalfall nur wenige Sekunden bzw. einen oder zwei Klicks. Meistens finden Sie direkt am
                Ende jeder E-Mail einen Link, um das Newsletter-Abonnement zu kündigen. Wenn der Link
                im Newsletter wirklich nicht zu finden ist, kontaktieren Sie uns bitte per Mail und wir werden
                Ihr Newsletter-Abo unverzüglich kündigen.
            </p>
            <h3>
                Rechtsgrundlage
            </h3>
            <p>
                Das Versenden unseres Newsletters erfolgt auf Grundlage Ihrer Einwilligung (Artikel 6 Abs.
                1 lit. a DSGVO). Das heißt, wir dürfen Ihnen nur dann einen Newsletter schicken, wenn Sie
                sich zuvor aktiv dafür angemeldet haben. Gegebenenfalls können wir Ihnen auch
                Werbenachrichten senden, sofern Sie unser Kunde geworden sind und der Verwendung
                Ihrer E-Mailadresse für Direktwerbung nicht widersprochen haben.
            </p>
            <p>
                Informationen zu speziellen E-Mail-Marketing Diensten und wie diese personenbezogene
                Daten verarbeiten, erfahren Sie – sofern vorhanden – in den folgenden Abschnitten.
            </p>
        </section>
        <section>
            <h2>
                SendGrid Datenschutzerklärung
            </h2>
            <p>
                Wir verwenden auf unserer Website SendGrid, ein Dienst zur E-Mail Delivery. Dienstanbieter
                ist das amerikanische Unternehmen Twilio Inc., 889 Winslow St, Redwood City, California
                94063, USA.
            </p>
            <p>
                SendGrid verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass
                nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für
                den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die
                Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.
            </p>
            <p>
                Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der
                Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder
                einer Datenweitergabe dorthin verwendet SendGrid sogenannte Standardvertragsklauseln
                (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses
                – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen
                sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards
                entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort
                gespeichert werden. Durch diese Klauseln verpflichtet sich SendGrid, bei der Verarbeitung
                Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die
                Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren
                auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die
                entsprechenden Standardvertragsklauseln u.a. hier: <br>
                <a
                    href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                    target="_blank"
                >https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
            </p>
            <p>
                Die Datenverarbeitungsbedingungen (Data Processing Addendum), welche den
                Standardvertragsklauseln entsprechen, finden Sie unter
                <a
                    href="https://www.twilio.com/legal/data-protection-addendum"
                    target="_blank"
                >https://www.twilio.com/legal/data-protection-addendum</a>.
            </p>
            <p>
                Mehr über die Daten, die durch die Verwendung von SendGrid verarbeitet werden, erfahren
                Sie in der Privacy Policy auf <a
                    href="https://www.twilio.com/legal/privacy"
                    target="_blank"
                >https://www.twilio.com/legal/privacy</a>.
            </p>
        </section>
        <section>
            <h2>
                Online-Marketing Einleitung
            </h2>
            <p class="with-border">
                <strong>Online-Marketing Datenschutzerklärung Zusammenfassung</strong> <br>
                👥 Betroffene: Besucher der Website <br>
                🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots. <br>
                📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe,
                Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und
                IP-Adressen enthalten. Auch personenbezogene Daten wie Name oder E-Mail-Adresse
                können verarbeitet werden. Mehr Details dazu finden Sie beim jeweils eingesetzten
                Online-Marketing-Tool. <br>
                📅 Speicherdauer: abhängig von den eingesetzten Online-Marketing-Tools <br>
                ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO
                (Berechtigte Interessen)
            </p>
            <h3>
                Was ist Online-Marketing?
            </h3>
            <p>
                Unter Online-Marketing bezeichnet man alle Maßnahmen, die online durchgeführt werden,
                um Marketingziele wie etwa die Steigerung der Markenbekanntheit oder einen
                Geschäftsabschluss zu erreichen. Weiters zielen unserer Online-Marketing-Maßnahmen
                darauf ab, Menschen auf unsere Website aufmerksam zu machen. Um unser Angebot vielen
                interessierten Menschen zeigen zu können, betreiben wir also Online-Marketing. Meistens
                handelt es sich dabei um Onlinewerbung, Content-Marketing oder
                Suchmaschinenoptimierung. Damit wir Online-Marketing effizient und zielgerecht einsetzen
                können, werden auch personenbezogene Daten gespeichert und verarbeitet. Die Daten
                helfen uns einerseits unsere Inhalte nur wirklich jenen Personen zu zeigen, die sich dafür
                auch interessieren und andererseits können wir den Werbeerfolg unserer
                Online-Marketing-Maßnahmen messen.
            </p>
            <h3>
                Warum nutzen wir Online-Marketing-Tools?
            </h3>
            <p>
                Wir wollen jedem Menschen, der sich für unser Angebot interessiert, unsere Website zeigen.
                Uns ist bewusst, dass dies ohne bewusst gesetzte Maßnahmen nicht möglich ist. Darum
                machen wir Online-Marketing. Es gibt verschiedene Tools, die uns die Arbeit an unseren
                Online-Marketing-Maßnahmen erleichtern und zusätzlich über Daten stets
                Verbesserungsvorschläge liefern. So können wir unsere Kampagnen genauer an unsere
                Zielgruppe richten. Zweck dieser eingesetzten Online-Marketing-Tools ist also letztlich die
                Optimierung unseres Angebots.
            </p>
            <h3>
                Welche Daten werden verarbeitet?
            </h3>
            <p>
                Damit unser Online-Marketing funktioniert und der Erfolg der Maßnahmen gemessen
                werden kann, werden Userprofile erstellt und Daten beispielsweise in Cookies (das sind
                kleine Text-Dateien) gespeichert. Mit Hilfe dieser Daten können wir nicht nur Werbung in
                klassischer Hinsicht schalten, sondern auch direkt auf unserer Website, unsere Inhalte so
                darstellen, wie es Ihnen am liebsten ist. Dafür gibt es verschiedene Drittanbieter-Tools, die
                diese Funktionen anbieten und entsprechend auch Daten von Ihnen sammeln und
                speichern. In den benannten Cookies wird zum Beispiel gespeichert, welche Webseiten Sie
                auf unserer Website besucht haben, wie lange Sie diese Seiten angesehen haben, welche
                Links oder Buttons Sie klicken oder von welcher Website sie zu uns gekommen sind.
                Zusätzlich können auch technische Informationen gespeichert werden. Etwa Ihre
                IP-Adresse, welchen Browser Sie nutzen, von welchem Endgerät Sie unsere Website
                besuchen oder die Uhrzeit, wann Sie unserer Website aufgerufen haben und wann Sie sie
                wieder verlassen haben. Wenn Sie eingewilligt haben, dass wir auch Ihren Standort
                bestimmen dürfen, können wir auch diesen speichern und verarbeiten.
            </p>
            <p>
                Ihre IP-Adresse wird in pseudonymisierter Form (also gekürzt) gespeichert. Eindeutige
                Daten, die Sie als Person direkt identifizieren, wie etwa Name, Adresse oder
                E-Mail-Adresse, werden im Rahmen der Werbe- und Online-Marketing-Verfahren auch nur
                in pseudonymisierter Form gespeichert. Wir können Sie also nicht als Person identifizieren,
                sondern wir haben nur die pseudonymisierten, gespeicherten Informationen in den
                Userprofilen gespeichert.
            </p>
            <p>
                Die Cookies können unter Umständen auch auf anderen Websites, die mit denselben
                Werbetools arbeiten, eingesetzt, analysiert und für Werbezwecke verwendet werden. Die
                Daten können dann auch auf den Servern der Werbetools-Anbieter gespeichert werden.
            </p>
            <p>
                In Ausnahmefällen können auch eindeutige Daten (Namen, Mail-Adresse usw.) in den
                Userprofilen gespeichert werden. Zu dieser Speicherung kommt es etwa, wenn Sie Mitglied
                eines Social-Media-Kanals sind, dass wir für unsere Online-Marketing-Maßnahmen nutzen
                und das Netzwerk bereits früher eingegangene Daten mit dem Userprofil verbindet.
            </p>
            <p>
                Bei allen von uns verwendeten Werbetools, die Daten von Ihnen auf deren Servern
                speichert, erhalten wir immer nur zusammengefasste Informationen und nie Daten, die Sie
                als Einzelperson erkennbar machen. Die Daten zeigen lediglich, wie gut gesetzte
                Werbemaßnahmen funktionierten. Zum Beispiel sehen wir, welche Maßnahmen Sie oder
                andere User dazu bewogen haben, auf unsere Website zu kommen und dort eine
                Dienstleistung oder ein Produkt zu erwerben. Anhand der Analysen können wir unser
                Werbeangebot in Zukunft verbessern und noch genauer an die Bedürfnisse und Wünsche
                interessierter Personen anpassen.
            </p>
            <h3>
                Dauer der Datenverarbeitung
            </h3>
            <p>
                Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere
                Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange
                wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist.
                Daten, die in Cookies gespeichert werden, werden unterschiedlich lange gespeichert.
                Manche Cookies werden bereits nach dem Verlassen der Website wieder gelöscht, anderen
                können über einige Jahre in Ihrem Browser gespeichert sein. In den jeweiligen
                Datenschutzerklärungen der einzelnen Anbieter erhalten Sie in der Regel genaue
                Informationen über die einzelnen Cookies, die der Anbieter verwendet.
            </p>
            <h3>
                Rechtsgrundlage
            </h3>
            <p>
                Wenn Sie eingewilligt haben, dass Drittanbieter eingesetzt werden dürfen, ist die
                Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese
                Einwilligung stellt laut Art. <strong>6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für
                die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch
                Online-Marketing-Tools vorkommen kann, dar.
            </p>
            <p>
                Von unserer Seite besteht zudem ein berechtigtes Interesse, Online-Marketing-Maßnahmen
                in anonymisierter Form zu messen, um mithilfe der gewonnenen Daten unser Angebot und
                unsere Maßnahmen zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong>
                    Art. 6
                    Abs. 1 lit. f DSGVO (Berechtigte Interessen)
                </strong>. Wir setzen die Tools gleichwohl nur ein,
                soweit Sie eine Einwilligung erteilt haben.
            </p>
            <p>
                Informationen zu speziellen Online-Marketing-Tools erhalten Sie – sofern vorhanden – in
                den folgenden Abschnitten.
            </p>
        </section>
        <section>
            <h2>
                HubSpot Datenschutzerklärung
            </h2>
            <p>
                Wir verwenden auf unserer Website HubSpot, ein Tool für digitales Marketing.
                Dienstanbieter ist das amerikanische Unternehmen HubSpot, Inc., 25 First St 2nd Floor
                Cambridge, MA, USA. Das Unternehmen hat unter anderem auch in Irland einen Firmensitz
                mit der Adresse 1 Sir John Rogerson’s Quay, Dublin 2, Irland.
            </p>
            <p>
                HubSpot verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass
                nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für
                den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die
                Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.
            </p>
            <p>
                Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der
                Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder
                einer Datenweitergabe dorthin verwendet HubSpot sogenannte Standardvertragsklauseln (=
                Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses –
                SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen,
                dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn
                diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden.
                Durch diese Klauseln verpflichtet sich HubSpot, bei der Verarbeitung Ihrer relevanten Daten,
                das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA
                gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem
                Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die
                entsprechenden Standardvertragsklauseln u.a. hier: <br>
                <a
                    href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                    target="_blank"
                >https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
            </p>
            <p>
                Die Datenverarbeitungsbedingung (Data Processing Agreement), welche den
                Standardvertragsklauseln entspricht, finden Sie unter <a
                    href="https://legal.hubspot.com/dpa"
                    target="_blank"
                >https://legal.hubspot.com/dpa</a>.
            </p>
            <p>
                Mehr über die Daten, die durch die Verwendung von HubSpot verarbeitet werden, erfahren
                Sie in der Privacy Policy auf <a
                    href="https://legal.hubspot.com/de/privacy-policy"
                    target="_blank"
                >https://legal.hubspot.com/de/privacy-policy</a>.
            </p>
        </section>
        <section>
            <h2>
                Auftragsverarbeitungsvertrag (AVV) HubSpot
            </h2>
            <p>
                Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit
                HubSpot einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist
                und vor allem was in einem AVV enthalten sein muss, können Sie in unserem allgemeinen
                Abschnitt „Auftragsverarbeitungsvertrag (AVV)“ nachlesen.
            </p>
            <p>
                Dieser Vertrag ist gesetzlich vorgeschrieben, weil HubSpot in unserem Auftrag
                personenbezogene Daten verarbeitet. Darin wird geklärt, dass HubSpot Daten, die sie von
                uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss.
                Den Link zum Auftragsverarbeitungsvertrag (AVV) finden Sie unter <br>
                <a
                    href="https://legal.hubspot.com/dpa"
                    target="_blank"
                >https://legal.hubspot.com/dpa</a>.
            </p>
        </section>
        <section>
            <h2>
                Erklärung verwendeter Begriffe
            </h2>
            <p>
                Wir sind stets bemüht unsere Datenschutzerklärung so klar und verständlich wie möglich zu
                verfassen. Besonders bei technischen und rechtlichen Themen ist das allerdings nicht
                immer ganz einfach. Es macht oft Sinn juristische Begriffe (wie z. B. personenbezogene
                Daten) oder bestimmte technische Ausdrücke (wie z. B. Cookies, IP-Adresse) zu
                verwenden. Wir möchte diese aber nicht ohne Erklärung verwenden. Nachfolgend finden Sie
                nun eine alphabetische Liste von wichtigen verwendeten Begriffen, auf die wir in der
                bisherigen Datenschutzerklärung vielleicht noch nicht ausreichend eingegangen sind. Falls
                diese Begriffe der DSGVO entnommen wurden und es sich um Begriffsbestimmungen
                handelt, werden wir hier auch die DSGVO-Texte anführen und gegebenenfalls noch eigene
                Erläuterungen hinzufügen.
            </p>
        </section>
        <section>
            <h2>
                Auftragsverarbeiter
            </h2>
            <h3>
                Begriffsbestimmung nach Artikel 4 der DSGVO
            </h3>
            <p>
                Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            </p>
            <p class="italic">
                <strong>„Auftragsverarbeiter“</strong> eine natürliche oder juristische Person, Behörde,
                Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des
                Verantwortlichen verarbeitet;
            </p>
            <p>
                <strong>Erläuterung:</strong>ls Unternehmen und Websiteinhaber für alle Daten, die wir von
                Ihnen verarbeiten verantwortlich. Neben den Verantwortlichen kann es auch sogenannte
                Auftragsverarbeiter geben. Dazu zählt jedes Unternehmen bzw. jede Person, die in unserem
                Auftrag personenbezogene Daten verarbeitet. Auftragsverarbeiter können folglich, neben
                Dienstleistern wie Steuerberater, etwa auch Hosting- oder Cloudanbieter, Bezahlungs- oder
                Newsletter-Anbieter oder große Unternehmen wie beispielsweise Google oder Microsoft
                sein.
            </p>
        </section>
        <section>
            <h2>
                Einwilligung
            </h2>
            <h3>
                Begriffsbestimmung nach Artikel 4 der DSGVO
            </h3>
            <p>
                Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            </p>
            <p class="italic">
                <strong>„Einwilligung“</strong> der betroffenen Person jede freiwillig für den bestimmten Fall, in
                informierter Weise und unmissverständlich abgegebene Willensbekundung in
                Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung,
                mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung
                der sie betreffenden personenbezogenen Daten einverstanden ist;
            </p>
            <p>
                <strong>Erläuterung:</strong> In der Regel erfolgt bei Websites eine solche Einwilligung über ein
                Cookie-Consent-Tool. Sie kennen das bestimmt. Immer wenn Sie erstmals eine Website
                besuchen, werden Sie meist über einen Banner gefragt, ob Sie der Datenverarbeitung
                zustimmen bzw. einwilligen. Meist können Sie auch individuelle Einstellungen treffen und so
                selbst entscheiden, welche Datenverarbeitung Sie erlauben und welche nicht. Wenn Sie
                nicht einwilligen, dürfen auch keine personenbezogene Daten von Ihnen verarbeitet werden.
                Grundsätzlich kann eine Einwilligung natürlich auch schriftlich, also nicht über ein Tool,
                erfolgen.
            </p>
        </section>
        <section>
            <h2>
                Personenbezogene Daten
            </h2>
            <h3>
                Begriffsbestimmung nach Artikel 4 der DSGVO
            </h3>
            <p>
                Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            </p>
            <p class="italic">
                <strong>„personenbezogene Daten“</strong> alle Informationen, die sich auf eine identifizierte
                oder identifizierbare natürliche Person (im Folgenden „betroffene Person“)
                beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt
                oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem
                Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
                oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der
                physischen, physiologischen, genetischen, psychischen, wirtschaftlichen,
                kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert
                werden kann;
            </p>
            <p>
                <strong>Erläuterung:</strong> Personenbezogene Daten sind also all jene Daten, die Sie als Person
                identifizieren können. Das sind in der Regel Daten wie etwa:
            </p>
            <ul>
                <li>Name</li>
                <li>Adresse</li>
                <li>E-Mail-Adresse</li>
                <li>Post-Anschrift</li>
                <li>Telefonnummer</li>
                <li>Geburtsdatum</li>
                <li>
                    Kennnummern wie Sozialversicherungsnummer, Steueridentifikationsnummer,
                    Personalausweisnummer oder Matrikelnummer
                </li>
                <li>Bankdaten wie Kontonummer, Kreditinformationen, Kontostände uvm.</li>
            </ul>
            <p>
                Laut Europäischem Gerichtshof (EuGH) zählt auch Ihre <strong>
                    IP-Adresse zu den
                    personenbezogenen Daten
                </strong>. IT-Experten können anhand Ihrer IP-Adresse zumindest den
                ungefähren Standort Ihres Geräts und in weiterer Folge Sie als Anschlussinhabers
                feststellen. Daher benötigt auch das Speichern einer IP-Adresse eine Rechtsgrundlage im
                Sinne der DSGVO. Es gibt auch noch sogenannte <strong>„besondere Kategorien“</strong> der
                personenbezogenen Daten, die auch besonders schützenswert sind. Dazu zählen:
            </p>
            <ul>
                <li>rassische und ethnische Herkunft</li>
                <li>politische Meinungen</li>
                <li>religiöse bzw. weltanschauliche Überzeugungen</li>
                <li>die Gewerkschaftszugehörigkeit</li>
                <li>
                    genetische Daten wie beispielsweise Daten, die aus Blut- oder Speichelproben
                    entnommen werden
                </li>
                <li>
                    biometrische Daten (das sind Informationen zu psychischen, körperlichen oder
                    verhaltenstypischen Merkmalen, die eine Person identifizieren können).
                    Gesundheitsdaten
                </li>
                <li>Daten zur sexuellen Orientierung oder zum Sexualleben</li>
            </ul>
        </section>
        <section>
            <h2>Profiling</h2>
            <h3>
                Begriffsbestimmung nach Artikel 4 der DSGVO
            </h3>
            <p>
                Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            </p>
            <p class="italic">
                <strong>„Profiling“</strong> jede Art der automatisierten Verarbeitung personenbezogener
                Daten, die darin besteht, dass diese personenbezogenen Daten verwendet
                werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person
                beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung,
                wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen,
                Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen
                Person zu analysieren oder vorherzusagen;
            </p>
            <p>
                <strong>Erläuterung:</strong> Beim Profiling werden verschiedene Informationen über eine Person
                zusammengetragen, um daraus mehr über diese Person zu erfahren. Im Webbereich wird
                Profiling häufig für Werbezwecke oder auch für Bonitätsprüfungen angewandt. Web- bzw.
                Werbeanalyseprogramme sammeln zum Beispiel Daten über Ihre Verhalten und Ihre
                Interessen auf einer Website. Daraus ergibt sich ein spezielles Userprofil, mit dessen Hilfe
                Werbung gezielt an eine Zielgruppe ausgespielt werden kann.
            </p>
        </section>
        <section>
            <h2>
                Verantwortlicher
            </h2>
            <h3>
                Begriffsbestimmung nach Artikel 4 der DSGVO
            </h3>
            <p>
                Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            </p>
            <p class="italic">
                <strong>„Verantwortlicher“</strong> die natürliche oder juristische Person, Behörde, Einrichtung
                oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
                Mittel der Verarbeitung von personenbezogenen Daten entscheidet; sind die
                Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht
                der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise
                können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder
                dem Recht der Mitgliedstaaten vorgesehen werden;
            </p>
            <p>
                <strong>Erläuterung:</strong> In unserem Fall sind wir für die Verarbeitung Ihrer personenbezogenen Daten
                verantwortlich und folglich der “Verantwortliche”. Wenn wir erhobene Daten zur Verarbeitung
                an andere Dienstleister weitergeben, sind diese “Auftragsverarbeiter”. Dafür muss ein
                “Auftragsverarbeitungsvertrag (AVV)” unterzeichnet werden.
            </p>
        </section>
        <section>
            <h2>
                Verarbeitung
            </h2>
            <h3>
                Begriffsbestimmung nach Artikel 4 der DSGVO
            </h3>
            <p>
                Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            </p>
            <p class="italic">
                <strong>„Verarbeitung“</strong> jeden mit oder ohne Hilfe automatisierter Verfahren
                ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
                personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation,
                das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen,
                das Abfragen, die Verwendung, die Offenlegung durch Übermittlung,
                Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die
                Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung;
            </p>
            <p>
                <strong>Anmerkung:</strong> Wenn wir in unserer Datenschutzerklärung von Verarbeitung sprechen,
                meinen wir damit jegliche Art von Datenverarbeitung. Dazu zählt, wie oben in der originalen
                DSGVO-Erklärung erwähnt, nicht nur das Erheben sondern auch das Speichern und
                Verarbeiten von Daten.
            </p>
        </section>
        <section>
            <h2>
                Schlusswort
            </h2>
            <p>
                Herzlichen Glückwunsch! Wenn Sie diese Zeilen lesen, haben Sie sich wirklich durch unsere
                gesamte Datenschutzerklärung „gekämpft“ oder zumindest bis hier hin gescrollt. Wie Sie am
                Umfang unserer Datenschutzerklärung sehen, nehmen wir den Schutz Ihrer persönlichen
                Daten, alles andere als auf die leichte Schulter. <br>
                Uns ist es wichtig, Sie nach bestem Wissen und Gewissen über die Verarbeitung
                personenbezogener Daten zu informieren. Dabei wollen wir Ihnen aber nicht nur mitteilen,
                welche Daten verarbeitet werden, sondern auch die Beweggründe für die Verwendung
                diverser Softwareprogramme näherbringen. In der Regel klingen Datenschutzerklärung sehr
                technisch und juristisch. Da die meisten von Ihnen aber keine Webentwickler oder Juristen
                sind, wollten wir auch sprachlich einen anderen Weg gehen und den Sachverhalt in
                einfacher und klarer Sprache erklären. Immer ist dies natürlich aufgrund der Thematik nicht
                möglich. Daher werden die wichtigsten Begriffe am Ende der Datenschutzerklärung näher
                erläutert. <br>
                Bei Fragen zum Thema Datenschutz auf unserer Website zögern Sie bitte nicht, uns oder
                die verantwortliche Stelle zu kontaktieren. Wir wünschen Ihnen noch eine schöne Zeit und
                hoffen, Sie auf unserer Website bald wieder begrüßen zu dürfen. <br> <br>
                Alle Texte sind urheberrechtlich geschützt. <br> <br>
                Quelle: Erstellt mit dem <a
                    href="https://www.adsimple.at/datenschutz-generator/"
                    target="_blank"
                >Datenschutz Generator</a> von AdSimple
            </p>
        </section>
        <section>
            <h2>
                PostHog Datenschutzerklärung
            </h2>
            <p>
                Wir verwenden die Dienste von PostHog, um das Nutzerverhalten und die
                Nutzerfreundlichkeit unserer Seite zu analysieren und zu optimieren. Diese Funktionen
                werden angeboten durch PostHog Inc, 965 Mission Street, San Francisco, CA 94103 USA.
                PostHog kann Ihr Verhalten auf unserer Webseite aufzeichnen und wiedergeben. Die
                Speicherung dieser Daten ist zeitlich begrenzt und wird ausschließlich zur Verbesserung
                unseres Dienstes anhand Ihrer Bedürfnisse verwendet. Weitere Informationen hierzu finden
                Sie in der Datenschutzerklärung von PostHog: <a
                    href="https://posthog.com/privacy"
                    target="_blank"
                >https://posthog.com/privacy</a>
            </p>
        </section>
        <section>
            <h2>Calendly Datenschutzerklärung</h2>
            <p>
                Wir erlauben den Nutzern, ihre Calendly Kalender einzubinden, um den Buchungsprozess sicher und
                effizient abzuwickeln. Weitere Informationen zu Calendly finden Sie hier:
                <a
                    href="https://calendly.com/privacy"
                    target="_blank"
                    rel="nofollow"
                >Calendly Datenschutz</a>.
            </p>
        </section>
        <section>
            <h2>
                Stripe Datenschutzerklärung
            </h2>
            <p>
                Wir verwenden die Dienste von Stripe, um Zahlungen unkompliziert abzuwickeln. Anbieter
                für Kunden innerhalb der EU ist die Stripe Payments Europe, Ltd.,1 Grand Canal Street
                Lower, Grand Canal Dock, Dublin, Irland.
            </p>
            <p>
                Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
                gestützt. Details finden Sie hier: <a
                    href="https://stripe.com/de/privacy"
                    target="_blank"
                    rel="nofollow"
                >https://stripe.com/de/privacy</a> und
                <a
                    href="https://stripe.com/de/guides/general-data-protection-regulation"
                    target="_blank"
                >https://stripe.com/de/guides/general-data-protection-regulation</a>.
            </p>
            <p>
                Details hierzu können Sie in der Datenschutzerklärung von Stripe unter folgendem Link
                nachlesen: <a
                    href="https://stripe.com/de/privacy"
                    target="_blank"
                >https://stripe.com/de/privacy</a>.
            </p>
        </section>
        <section>
            <h2>YouTube Datenschutzerklärung</h2>
            <p>
                Unsere Website verwendet YouTube-Videos. Wenn du eine Seite mit einem eingebetteten Video
                besuchst, wird deine IP-Adresse an YouTube übertragen und ein Cookie auf deinem Computer
                gespeichert. Wir haben unsere YouTube-Videos jedoch mit dem erweiterten Datenschutzmodus
                eingebunden, sodass YouTube keine Informationen über die Besucher speichert, es sei denn,
                sie sehen sich das Video an. Wenn du ein Video anklickst, wird deine IP-Adresse an YouTube
                übermittelt und YouTube erfährt, dass du das Video angesehen hast. Sind du bei YouTube
                eingeloggt, wird diese Information auch deinem Benutzerkonto zugeordnet.
            </p>
            <p>
                Wir haben keine Kenntnis von der Erhebung und Verwendung deiner Daten durch YouTube und
                können darauf auch keinen Einfluss nehmen. Nähere Informationen kannst du der
                Datenschutzerklärung von YouTube unter www.google.de/intl/de/policies/privacy/
                entnehmen. Du kannst die Erhebung und Verwendung deiner Daten durch YouTube verhindern,
                indem du dich vor dem Aufrufen des Videos bei YouTube ausloggst.
                Für den generellen Umgang mit und die Deaktivierung von Cookies verweisen wir auf unsere
                allgemeine Darstellung in dieser Datenschutzerklärung.
            </p>
        </section>
        <section>
            <h2>
                Cookies
            </h2>
            <p>
                Wir verwenden Cookies auf dieser Website. Diese Cookie Erklärung gilt für die von
                allekommen.at / allekommen.com verwendeten Cookies.
            </p>
        </section>
        <section>
            <h3>
                Cookie Erklärung
            </h3>
            <p>
                Cookies sind kleine Textdateien, die von einer Website auf ihrem Computer oder mobilen
                Gerät gespeichert werden. Sie dienen dazu, Informationen über Ihre Interaktion mit der
                Website zu speichern, wie zum Beispiel Ihre Einstellungen, Anmeldedaten oder
                Präferenzen. Cookies ermöglichen es der Website, Sie bei zukünftigen Besuchen
                wiederzuerkennen und personalisierte Inhalte anzubieten. Sie werden auch für das Tracking
                und die Analyse des Nutzerverhaltens verwendet. Es gibt verschiedene Arten von Cookies,
                einschließlich Sitzungscookies, die nach dem Schließen des Browsers gelöscht werden, und
                persistenten Cookies, die länger auf Ihrem Gerät verbleiben. Es ist wichtig zu beachten,
                dass Cookies auch für Werbezwecke eingesetzt werden können, um Ihnen gezielte
                Anzeigen basierend auf Ihren Interessen zu zeigen.
            </p>
        </section>
        <section>
            <h3>
                Erlaubnis zur Verwendung von Cookies
            </h3>
            <p>
                Über den Cookie-Banner, der Ihnen beim Besuch einer unserer Websites eingeblendet wird,
                können Sie einwilligen oder ablehnen, dass bestimmte Cookies zum Einsatz kommen. In
                den Cookie-Einstellungen können Sie Ihre Präferenz diesbezüglich jederzeit anpassen.
            </p>
        </section>
        <section>
            <h3>
                Verwendete Cookies
            </h3>
            <p>
                <strong>Notwendige Cookies</strong><br>
                Notwendige Cookies sind notwendig, damit eine Website ordnungsgemäß funktionieren
                kann. Sie ermöglichen grundlegende Funktionen wie die Navigation auf der Seite, den
                Zugriff auf geschützte Bereiche und das Speichern von Informationen, die für die Interaktion
                mit der Website wichtig sind. Diese Cookies erfordern keine Zustimmung, da sie für die
                Bereitstellung des von Ihnen angeforderten Dienstes unerlässlich sind. Sie werden in der
                Regel automatisch gesetzt und dienen dazu, Ihre Präferenzen zu speichern, damit die
                Website optimal arbeiten kann.
            </p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><strong>Domain</strong></th>
                            <th><strong>Cookies</strong></th>
                            <th><strong>Anbieter</strong></th>
                            <th><strong>Zweck</strong></th>
                            <th><strong>Rechtsgrundlage</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>allekommen.at / allekommen.com</td>
                            <td>auth.redirect</td>
                            <td>allekommen.at / allekommen.com</td>
                            <td>Automatische Umleitung</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                        <tr>
                            <td>allekommen.at / allekommen.com / partner.allekommen.at / allekommen.com</td>
                            <td>auth.strategy</td>
                            <td>allekommen.at / allekommen.com</td>
                            <td>Authentifizierung</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                        <tr>
                            <td>allekommen.at / allekommen.com / partner.allekommen.at / allekommen.com</td>
                            <td>i18n_redirected</td>
                            <td>Nuxt JS</td>
                            <td>Verhinderung von mehrfacher Weiterleitung</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                        <tr>
                            <td>allekommen.at / allekommen.com / partner.allekommen.at / allekommen.com</td>
                            <td>ph_phc _(ID)_ posthog</td>
                            <td>Posthog</td>
                            <td>Verhinderung von mehrfacher Weiterleitung</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                        <tr>
                            <td>allekommen.at / allekommen.com / partner.allekommen.at / allekommen.com</td>
                            <td>__cf_bm</td>
                            <td>Cloudflare</td>
                            <td>Bot protection</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                        <tr>
                            <td>allekommen.at / allekommen.com / partner.allekommen.at / allekommen.com</td>
                            <td>__hs_do_not_track</td>
                            <td>Hubspot</td>
                            <td>Cookie Opt-Out</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                        <tr>
                            <td>allekommen.at / allekommen.com / partner.allekommen.at / allekommen.com</td>
                            <td>__hs_cookie_cat_pref</td>
                            <td>Hubspot</td>
                            <td>Cookie Preferences</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </section>
        <section>
            <h3>
                Analytics-Cookies
            </h3>
            <p>
                Analytics-Cookies werden verwendet, um Informationen über die Nutzung einer Website zu
                sammeln und statistische Daten zu generieren. Sie helfen Website-Betreibern, das
                Besucherverhalten zu analysieren, Trends zu erkennen und die Leistung der Website zu
                verbessern. Diese Cookies sammeln anonymisierte Daten wie die Anzahl der Besucher, die
                Verweildauer auf der Website und die am häufigsten aufgerufenen Seiten. Die gewonnenen
                Erkenntnisse dienen dazu, die Benutzererfahrung zu optimieren und relevante Inhalte
                anzubieten. Analytics-Cookies erfordern in der Regel die Einwilligung des Nutzers, da sie
                nicht unbedingt für die grundlegende Funktion der Website erforderlich sind.
            </p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><strong>Domain</strong></th>
                            <th><strong>Cookies</strong></th>
                            <th><strong>Anbieter</strong></th>
                            <th><strong>Zweck</strong></th>
                            <th><strong>Rechtsgrundlage</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>allekommen.at / allekommen.com</td>
                            <td>MUID</td>
                            <td>Bing</td>
                            <td>Bei diesem Cookie handelt es sich um einen Analysedienst, der Daten aus dem Bing-Werbenetzwerk mit auf der Website durchgeführten Aktionen verknüpft.</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                        <tr>
                            <td>allekommen.at / allekommen.com</td>
                            <td>GI_FRE_COOKIE</td>
                            <td>Bing</td>
                            <td>Bei diesem Cookie handelt es sich um einen Analysedienst, der Daten aus dem Bing-Werbenetzwerk mit auf der Website durchgeführten Aktionen verknüpft.</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                        <tr>
                            <td>allekommen.at / allekommen.com</td>
                            <td>MSPTC</td>
                            <td>Bing</td>
                            <td>Bei diesem Cookie handelt es sich um einen Analysedienst, der Daten aus dem Bing-Werbenetzwerk mit auf der Website durchgeführten Aktionen verknüpft.</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                        <tr>
                            <td>allekommen.at / allekommen.com</td>
                            <td>SRCHHPGUSR</td>
                            <td>Bing</td>
                            <td>Bei diesem Cookie handelt es sich um einen Analysedienst, der Daten aus dem Bing-Werbenetzwerk mit auf der Website durchgeführten Aktionen verknüpft.</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                        <tr>
                            <td>allekommen.at / allekommen.com</td>
                            <td>SRCHD</td>
                            <td>Bing</td>
                            <td>Bei diesem Cookie handelt es sich um einen Analysedienst, der Daten aus dem Bing-Werbenetzwerk mit auf der Website durchgeführten Aktionen verknüpft.</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                        <tr>
                            <td>allekommen.at / allekommen.com</td>
                            <td>SRCHUID</td>
                            <td>Bing</td>
                            <td>Bei diesem Cookie handelt es sich um einen Analysedienst, der Daten aus dem Bing-Werbenetzwerk mit auf der Website durchgeführten Aktionen verknüpft.</td>
                            <td>Art 6 Abs 1 lit a DSGVO</td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </section>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
useHead({
    title: 'Datenschutz'
})
</script>

<style scoped>
.with-border {
    border: 1px solid gray;
    padding: var(--spacing);
    border-radius: var(--border-radius);
}
.italic {
    font-style: italic;
    padding-left: 1.5rem;
}
th, td{
    width: 20%;
    padding: 5px;
    border: 1px solid var(--dark-blue);
    vertical-align: top;
}
</style>
