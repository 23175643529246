<template>
    <dialog open>
        <article>
            <CloseIcon @close="searchStore.setShowAddressModal(false)" />
            <p class="address-title">
                Dein Standort
            </p>
            <NavigationGoBackWithClick v-if="status !== 'start'" @click="status = 'start'" />
            <LazySearchAddressModalHomeScreen
                v-if="status === 'start'"
                @enter-address="status = 'enterAddress'"
            />
            <LazySearchAddressAutocompleteInput
                v-if="status === 'enterAddress'"
                class="mt-1"
                @address-selected="addressSelected"
            />
        </article>
    </dialog>
</template>

<script setup>
const searchStore = useSearchStore()
const isAuthenticated = useIsAuthenticated()
const status = ref(isAuthenticated.value ? 'start' : 'enterAddress') // start, enterAddress
/** Handle the selection of an address. */
function addressSelected () {
    // this selects an address from OSM, no polygonId
    status.value = 'start'
    document.body.classList.remove('no-scroll')
    searchStore.setShowAddressModal(false)
}
</script>

<style scoped>
p.address-title {
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1.2rem;
}
article {
    min-height: 25rem;
    max-height: 80%;
    width: 100%;
}
@media (min-width: 768px) {
    article {
        min-height: 26rem;
    }
}
</style>
