<template>
    <div class="social-media-icons">
        <a href="https://www.linkedin.com/company/allekommen" target="_blank">
            <img src="/assets/icons/social-media/linkedin.svg" alt="LinkedIn" title="LinkedIn">
        </a>
        <a href="https://www.instagram.com/allekommen" target="_blank">
            <img src="/assets/icons/social-media/instagram.svg" alt="Instagram" title="Instagram">
        </a>
        <a href="https://www.facebook.com/allekommenAT" target="_blank">
            <img src="/assets/icons/social-media/facebook.svg" alt="Facebook" title="Facebook">
        </a>
        <a href="https://www.youtube.com/@allekommen" target="_blank">
            <img src="/assets/icons/social-media/youtube.svg" alt="YouTube" title="YouTube">
        </a>
        <a href="https://www.tiktok.com/@allekommen.at" target="_blank">
            <img src="/assets/icons/social-media/tiktok.svg" alt="TikTok" fill="white">
        </a>
        <a href="https://www.threads.net/@allekommen" target="_blank">
            <img src="/assets/icons/social-media/threads.svg" alt="threads" title="threads">
        </a>
    </div>
</template>

<style scoped>
.social-media-icons img {
    width: 1.75rem;
    height: 1.75rem;
}
.social-media-icons {
    display: flex;
    column-gap: calc(2 * var(--spacing));
}
@media (min-width: 768px) {
    .social-media-icons {
        column-gap: var(--spacing);
    }
}
@media (min-width: 900px) {
    .social-media-icons img {
        width: 1.25rem;
        height: 1.25rem;
    }
    .social-media-icons {
        justify-content: flex-start;
        column-gap: 0.75rem;
    }
}
</style>
