<template>
    <div class="stars-container">
        <img
            v-for="n in 5"
            :key="n"
            class="star-icon"
            :class="{ selected: n <= rating }"
            :src="starIconPath(n)"
            alt="Star Icon"
            loading="lazy"
            @click="rating = n"
        >
    </div>
</template>

<script setup>
const rating = defineModel({ type: Number })

/**
 * Generates the path to the star icon image based on the selected number of stars.
 * @param {number} n - The index of the star.
 * @returns {string} - The path to the star icon image.
 */
function starIconPath (n) {
    return '/icons/stars/' + (n <= rating.value ? 'star-primary-solid.svg' : 'star-primary.svg')
}

</script>

<style scoped>
.stars-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: calc(var(--spacing) * 1.2);
    flex-direction: row;
    margin: var(--spacing) 0;
}
.star-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease-in-out;
    cursor: pointer;
}
.star-icon:hover {
    transform: scale(1.2);
}
.selected {
    transform: scale(1.5);
}
</style>
