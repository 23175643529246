<template>
    <span class="action">%</span>
</template>

<style scoped>
.action {
    background-color: var(--red);
    color: var(--white);
    border-radius: var(--border-radius);
    padding: 0.1rem 0.4rem;
    margin-left: 0.1rem;
    font-weight: bold;
}
</style>
