<template>
    <div class="cities-grid">
        <div class="top-cities">
            <div
                v-for="city in topCities[locale].slice(0, 2)"
                :key="city.name"
                class="item scale-on-hover"
            >
                <NuxtLink :to="localePath({ name: 'locations-locationName', params: { locationName: city.permanentName } })">
                    <img
                        :src="`${imageBase}/${city.permanentName}.jpg`"
                        :alt="city.name"
                        loading="lazy"
                    >
                    <div class="label">
                        {{ city.name }}
                    </div>
                </NuxtLink>
            </div>
        </div>
        <div class="cities">
            <div
                v-for="city in topCities[locale].slice(2)"
                :key="city.name"
                class="item scale-on-hover"
            >
                <NuxtLink :to="localePath({ name: 'locations-locationName', params: { locationName: city.permanentName } })">
                    <img
                        :src="`${imageBase}/${city.permanentName}.jpg`"
                        :alt="city.name"
                        loading="lazy"
                    >
                    <div class="label">
                        {{ city.name }}
                    </div>
                </NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup>
const localePath = useLocalePath()

const topCities = {
    at: [
        { name: 'Wien', permanentName: 'wien' },
        { name: 'Salzburg', permanentName: 'salzburg-stadt' },
        { name: 'Graz', permanentName: 'graz-stadt' },
        { name: 'Innsbruck', permanentName: 'innsbruck' },
        { name: 'Linz', permanentName: 'linz' }
    ],
    de: [
        { name: 'Berlin', permanentName: 'berlin' },
        { name: 'München', permanentName: 'muenchen' },
        { name: 'Hamburg', permanentName: 'hamburg' },
        { name: 'Stuttgart', permanentName: 'stuttgart' },
        { name: 'Leipzig', permanentName: 'leipzig' },
        { name: 'Dresden', permanentName: 'dresden' },
        { name: 'Hannover', permanentName: 'hannover' },
        { name: 'Düsseldorf', permanentName: 'duesseldorf' }
    ]
}

const config = useRuntimeConfig().public
const locale = config.locale
const SPACES_URL = config.spacesUrl
const imageBase = `${SPACES_URL}/images/main-cities`
</script>

<style scoped>
.top-cities, .cities {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: var(--spacing);
}
.cities { margin-top: var(--spacing); }
.item {
    position: relative;
    height: 15rem;
    box-shadow: var(--small-shadow);
    cursor: pointer;
}
.item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
}
.label {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: var(--dark-blue);
    color: var(--yellow);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1.5rem;
    font-weight: bold;
}
@media (min-width: 768px) {
    .top-cities {
        grid-template-columns: repeat(2, 1fr);
    }
    .cities {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 1440px) {
    .item {
        height: 20rem;
    }
}
</style>
