<template>
    <footer>
        <div class="container footer-container">
            <div class="links-container">
                <div class="social-media">
                    <AllekommenLogo class="allekommen-logo" />
                    <p class="title">
                        Vernetze dich mit uns!
                    </p>
                    <SocialMedia />
                </div>
                <AccordionComponent
                    title="Über uns"
                    :open="true"
                    :use-layout="false"
                >
                    <template #links>
                        <div class="links">
                            <NuxtLink :to="localePath('about-us')">
                                Über uns
                            </NuxtLink>
                            <NuxtLink :to="localePath('press')">
                                Presse
                            </NuxtLink>
                            <NuxtLink :to="localePath('customer-support')">
                                User Support
                            </NuxtLink>
                            <NuxtLink :to="localePath('/#newsletter-anmelden')">
                                Newsletter
                            </NuxtLink>
                            <a
                                href="https://partner.allekommen.at"
                                target="_blank"
                            >
                                Zum Partner Portal
                            </a>
                            <a
                                role="button"
                                class="cta yellow small"
                                href="https://partner.allekommen.at/kostenfrei-listen"
                            >
                                Anbieter werden
                            </a>
                        </div>
                    </template>
                </AccordionComponent>
                <div class="accordion-links">
                    <!-- <AccordionComponent title="Entdecke">
                        <template #links>
                            <div class="links-wrapper">
                                <div>
                                    <a>Bestbewertet</a>
                                </div>
                                <div>
                                    <a>Neuigkeiten</a>
                                    <a>Angebote</a>
                                </div>
                            </div>
                        </template>
                    </AccordionComponent> -->
                    <!-- TODO: add link once coupons are supported -->
                    <AccordionComponent
                        v-if="locale === 'at'"
                        title="Verzeichnisse"
                        :open="true"
                    >
                        <template #links>
                            <NuxtLink :to="localePath('provider')">
                                Anbieter A-Z
                            </NuxtLink>
                            <p
                                role="link"
                                @click="navigateTo(localePath('search'))"
                            >
                                Mobile Dienstleistungen A-Z
                            </p>
                            <NuxtLink :to="localePath('coupons')">
                                Gutscheine
                            </NuxtLink>
                            <NuxtLink :to="localePath('homevisits')">
                                Hausbesuche Kategorien
                            </NuxtLink>
                            <NuxtLink :to="localePath('delivery')">
                                Lieferservices Kategorien
                            </NuxtLink>
                        </template>
                    </AccordionComponent>
                    <NavigationLocationLinks
                        title="Bundesländer"
                        :locations="states[locale]"
                    />
                    <NavigationLocationLinks
                        title="Städte"
                        :locations="cities[locale]"
                    />
                    <NavigationRecommendedPartners />
                <!-- <AccordionComponent title="Ratgeber">
                    <template #links>
                        <a href="#">Ratgeber</a>
                        <a href="#">Hausbesuche: Die Vorteile von Services direkt zu Hause</a>
                    </template>
                </AccordionComponent> -->
                </div>
                <div
                    v-if="locale === 'at'"
                    class="marktplatz"
                >
                    <img
                        src="/business-links/oe_marktplatz_siegel.png"
                        alt="Österreicher Handelsverband, Marktplatz Siegel"
                        loading="lazy"
                    >
                    <p>allekommen.at ist Partner des österreichischen Handel!</p>
                </div>
            </div>
            <p class="centered mt-2">
                Mit ❤️ gemacht in Wien!
            </p>
            <p class="centered copyright mt-2">
                &copy; {{ new Date().getFullYear() }} allekommen.at
            </p>
            <div class="legal">
                <NuxtLink :to="localePath('privacy')">
                    Datenschutzerklärung
                </NuxtLink>
                <NuxtLink :to="localePath('imprint')">
                    Impressum
                </NuxtLink>
                <NuxtLink :to="localePath('terms')">
                    Nutzungsbedingungen
                </NuxtLink>
                <NuxtLink :to="localePath('agb')">
                    AGB
                </NuxtLink>
            </div>
        </div>
    </footer>
</template>

<script setup>
const locale = useRuntimeConfig().public.locale
const localePath = useLocalePath()

const states = {
    at: [
        { name: 'Burgenland', permanentName: 'burgenland' },
        { name: 'Kärnten', permanentName: 'kaernten' },
        { name: 'Niederösterreich', permanentName: 'niederoesterreich' },
        { name: 'Oberösterreich', permanentName: 'oberoesterreich' },
        { name: 'Salzburg', permanentName: 'salzburg-stadt' },
        { name: 'Steiermark', permanentName: 'steiermark' },
        { name: 'Tirol', permanentName: 'tirol' },
        { name: 'Vorarlberg', permanentName: 'vorarlberg' },
        { name: 'Wien', permanentName: 'wien' }
    ],
    de: [
        { name: 'Bayern', permanentName: 'bayern' },
        { name: 'Brandenburg', permanentName: 'brandenburg' },
        { name: 'Bremen', permanentName: 'bremen' },
        { name: 'Hamburg', permanentName: 'hamburg' },
        { name: 'Hessen', permanentName: 'hessen' },
        { name: 'Mecklenburg-Vorpommern', permanentName: 'mecklenburg-vorpommern' },
        { name: 'Niedersachsen', permanentName: 'niedersachsen' },
        { name: 'Nordrhein-Westfalen', permanentName: 'nordrhein-westfalen' },
        { name: 'Rheinland-Pfalz', permanentName: 'rheinland-pfalz' },
        { name: 'Sachsen', permanentName: 'sachsen' },
        { name: 'Sachsen-Anhalt', permanentName: 'sachsen-anhalt' },
        { name: 'Schleswig-Holstein', permanentName: 'schleswig-holstein' },
        { name: 'Thüringen', permanentName: 'thueringen' },
        { name: 'Baden-Württemberg', permanentName: 'baden-wuerttemberg' },
        { name: 'Berlin', permanentName: 'berlin' },
        { name: 'Saarland', permanentName: 'saarland' }
    ]
}
</script>

<style scoped>
footer {
    padding: calc(2 * var(--spacing)) var(--spacing);
    margin-top: auto;
    background-color: var(--dark-blue);
    font-size: calc(0.9 * var(--font-size));
}
.footer-container {
    margin: 0 auto;
    padding: 0 1rem;
}
.links-container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}
footer p, footer a {
    color: var(--white);
    min-height: 24px;
}
.copyright {
    font-size: calc(0.8 * var(--font-size));
}
.title { font-weight: bold; margin-bottom: 0; }
.marktplatz {
    max-width: 15rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    grid-template-columns: 5rem auto;
}
.marktplatz p {
    font-size: 0.75rem;
    margin-bottom: 0;
    min-height: 0;
}
.marktplatz img {
    width: 4rem;
    height: 4rem;
}
.links {
    grid-row: 1 / 3;
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    align-items: flex-start;
    row-gap: var(--spacing);
}
p[role="link"] { cursor: pointer; font-size: calc(0.9 * var(--font-size)); margin-bottom: 0; }
.social-media {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
}
.business a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.legal {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 2rem;
}
.business a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.cta { color: var(--dark-blue); width: fit-content; }
.accordion-links {
    display: flex;
    flex-direction: column;
    row-gap: calc(2 * var(--spacing));
}
@media (min-width: 768px) {
    .links-container {
        display: grid;
        column-gap: 2.5rem;
        grid-template-columns: 1fr 2fr;
    }
    .marktplatz{ grid-row: 3; margin-left: 0; }
    .accordion-links {
        grid-row: 1 / 5;
        grid-column: 2;
        row-gap: calc(2 * var(--spacing));
    }
}
</style>
