<template>
    <div v-if="!data.confirmed" class="container ptb-1">
        <h1>Token wird geprüft...</h1>
        <progress />
    </div>
    <form v-else method="post" class="container ptb-1" @submit.prevent="changePassword">
        <h1>Neues Passwort festlegen</h1>
        <p>
            Bitte definiere ein neues Passwort, das dein altes ersetzen wird. Achte darauf,
            dass deine Eingaben übereinstimmen und das neue Passwort mindestens 6 Zeichen lang ist.
        </p>
        <label for="password">
            Neues Passwort
            <input
                id="password"
                v-model="data.password"
                :aria-invalid="String(passwordsDifferent)"
                type="password"
                name="password"
                placeholder="Passwort eingeben"
                required
            >
            <small v-if="passwordsTooShort" class="input-warning">
                Passwort muss mindestens 6 Zeichen haben.
            </small>
            <small v-else-if="passwordsDifferent" class="input-warning">
                Passwörter stimmen nicht überein.
            </small>
        </label>
        <label for="repeat_password">
            Passwort wiederholen
            <input
                id="repeat_password"
                v-model="data.repeatPassword"
                :aria-invalid="String(passwordsDifferent)"
                type="password"
                name="repeat_password"
                placeholder="Passwort wiederholen"
                required
            >
            <small v-if="passwordsDifferent && !passwordsTooShort" class="input-warning">
                Passwörter stimmen nicht überein.
            </small>
        </label>

        <button :disabled="!allowSubmit" :aria-busy="data.submitting" type="submit">
            Aktualisieren
        </button>
    </form>
</template>

<script setup>
const route = useRoute()
const router = useRouter()
const toast = useToast()
const { $backendApi } = useNuxtApp()

definePageMeta({
    auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: '/'
    }
})

const data = reactive({
    confirmed: false,
    submitting: false,
    password: '',
    repeatPassword: '',
    token: ''
})

useHead({
    title: 'Passwort zurücksetzen',
    meta: [{ name: 'robots', content: 'noindex' }]
})

const passwordsDifferent = computed(() => {
    return data.password !== data.repeatPassword || (data.password === '' && data.repeatPassword === '')
})
const passwordsTooShort = computed(() => {
    return data.password.length < 6
})
const allowSubmit = computed(() => {
    return !passwordsDifferent && !passwordsTooShort
})

onMounted(async () => {
    try {
        data.token = route.params.passwordResetToken
        await $backendApi(`auth/password-reset/validate/${data.token}?userType=user`)
        toast.success('Token wurde bestätigt.')
        data.confirmed = true
    } catch (error) {
        handleBackendError({ error, toast })
        await router.push('/')
    }
})

async function changePassword () {
    if (passwordsDifferent.value) {
        resetInputs()
        return toast.error('Passwörter stimmen nicht überein.', { timeout: 5000 })
    }
    if (passwordsTooShort.value) {
        return toast.error('Die Passwörter müssen mindestens 6 Zeichen lang sein.', { timeout: 5000 })
    }
    try {
        await $backendApi(`auth/password-reset/new/${data.token}`, {
            method: 'POST',
            body: {
                userType: 'user',
                password: data.password,
                repeatPassword: data.repeatPassword
            }
        })
        toast.success('Passwort aktualisiert.')
        await router.push('/login')
    } catch (error) {
        handleBackendError({ error, toast })
    }
}
function resetInputs () {
    data.password = ''
    data.repeatPassword = ''
}

</script>
<style scoped>
.input-warning {
    color: var(--form-element-invalid-border-color);
    font-size: 0.8rem;
}
</style>
