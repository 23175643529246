<template>
    <div @click="emit('click')">
        <NuxtLink class="card scale-on-hover" :to="link">
            <div class="image-frame">
                <img :src="cdnPath" :alt="props.category.name" loading="lazy" class="category-image">
                <span>mobil</span>
            </div>
            {{ category.name }}
        </NuxtLink>
    </div>
</template>

<script setup>
const config = useRuntimeConfig()
const SPACES_URL = config.public.spacesUrl

const emit = defineEmits(['click'])

const props = defineProps({
    category: { type: Object, required: true }
})
const localePath = useLocalePath()

/** The URL link to navigate when the category card is clicked */
const link = localePath({
    name: 'categoryName',
    params: { categoryName: props.category.permanentName }
})

/** The CDN path to the category image */
const cdnPath = `${SPACES_URL}/images/categories/${props.category.image}`
</script>

<style scoped>
.card {
    height: 130px;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    justify-content: flex-end;
    align-items: center;
    transition: 0.2s ease-in-out;
    font-size: 1rem;
}
.image-frame {
    width: 12rem;
    height: 8rem;
    border-radius: var(--border-radius);
    box-shadow: var(--small-shadow);
    transition: 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
}
a {
    text-decoration: none;
}
.image-frame span {
    text-align: center;
    display: block;
    width: 100%;
    position: absolute;
    top: 10%;
    right: -39%;
    transform: rotate(45deg);
    background-color: var(--primary);
    box-shadow: var(--small-shadow);
    color: var(--white);
}
.category-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
}
</style>
