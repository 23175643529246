<template>
    <div class="login-invite">
        <BoxWithSlot>
            <template #content>
                <h2>
                    Optimiere dein Erlebnis mit individuellen Favoriten!
                </h2>
                <p>Deine Favoritenliste wird derzeit nicht gespeichert. Melde dich an, um zahlreiche Vorteile zu erhalten und deine Favoritenliste zu speichern.</p>
                <NuxtLink :to="localePath('login')" role="button">
                    Jetzt anmelden
                </NuxtLink>
            </template>
        </BoxWithSlot>
    </div>
</template>

<script setup>
const localePath = useLocalePath()
</script>

<style scoped>
.login-invite {
    display: flex;
}
.login-invite h2 {
    color: var(--primary);
}

.login-invite a {
    align-self: flex-end;
    white-space: nowrap;
    margin-top: auto;
}
@media (min-width: 425px) {
    .login-invite {
        grid-column: span 2;
    }
}
@media (min-width: 576px) {
    .login-invite button {
        width: 55%;
    }
}
@media (min-width: 1400px) {
    .login-invite {
        grid-column: span 1;
    }
}
</style>
