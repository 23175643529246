<template>
    <div class="search-menu-container">
        <CloseIcon @close="emit('close')" />
        <div
            v-if="loading"
            class="loading-animation-container"
        >
            <img
                class="loading-animation"
                src="/assets/loading-animation.gif"
                alt="Lade Services..."
            >
        </div>
        <SearchSuggestionsResults
            v-else-if="showSuggestions"
            :suggestions="suggestions"
            class="scrollable"
            @close="emit('close')"
        />
        <div
            v-else
            class="categories-container"
        >
            <p><b>Beliebte Kategorien mobiler Dienstleistungen & Lieferservices</b></p>
            <SearchCategorySuggestions
                :categories="trendingCategories[locale]"
                key-prefix="c-recommended"
                @close="emit('close')"
            />
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['close'])

defineProps({
    suggestions: { type: Object, required: true },
    loading: { type: Boolean, required: true },
    showSuggestions: { type: Boolean, required: true }
})

const locale = useRuntimeConfig().public.locale
</script>

<style scoped>
.search-menu-container {
    position: absolute;
    top: 2.75rem;
    border-radius: var(--border-radius);
    height: 24.5rem;
    width: 100%;
    left: 0;
    background-color: var(--white);
    z-index: 999;
    padding: var(--spacing);
    transition: all 0.3s ease-in-out;
    display: grid;
    row-gap: var(--spacing);
}
.categories-container {
    overflow-y: auto;
}
.categories-container p {
    margin-right: 2rem;
}
.loading-animation {
    display: block;
    margin: auto;
}
@media (min-width: 768px) {
    .search-menu-container {
        height: 21rem;
    }
    .categories-container {
        height: 19rem;
    }
    .loading-animation-container {
        display: grid;
        place-items: center;
    }
}
@media (min-width: 992px) {
    .search-menu-container {
        height: 21rem;
    }
    .categories-container {
        height: 19rem;
    }
}
</style>
