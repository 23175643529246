<template>
    <a
        class="b2b-ad-card scale-on-hover"
        :href="`https://partner.allekommen.${locale === 'de' ? 'com' : 'at'}/anbieter-werden?utm_source=allekommen-search`"
    >
        <div class="image-cont">
            <span class="angebot">Angebot</span>
            <img
                :src="`${SPACES_URL}/images/b2b-ad.webp`"
                alt="b2b card"
                class="b2b-ad-image"
            >
        </div>
        <div class="b2b-ad-card-content">
            <p
                role="link"
                class="b2b-ad-name"
            >
                Werde unser Partner!
                <ServiceSustainabilityBadge />
            </p>
            Kundengewinnung in deinem Servicegebiet - so einfach, wie Pizza bestellen!
        </div>
        <div class="mt-auto b2b-ad-card-content">
            <RatingStarImages
                unique-identifier="b2b-ad"
                :rating="5"
            />
            <button class="yellow small details-link">
                Gratis Testphase <span class="longer-cta">starten</span>
            </button>
        </div>
    </a>
</template>

<script setup>
const config = useRuntimeConfig().public
const SPACES_URL = config.spacesUrl
const locale = config.locale
</script>

<style scoped>
a { text-decoration: none; }
p[role=link] { color: var(--color); }
p { margin-bottom: 0; color: var(--dark-blue); }
.image-cont {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    position: relative;
}
.b2b-ad-card {
    display: grid;
    border-radius: var(--border-radius);
    background-color: var(--white);
    box-shadow: var(--small-shadow);
    cursor: pointer;
    color: var(--dark-blue);
}
.b2b-ad-card-content {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
.b2b-ad-name {
    font-size: 1rem;
    word-break: break-word;
}
.mt-auto { margin-top: auto; }
.angebot {
    display: grid;
    place-items: center;
    background-color: var(--red);
    color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    width: 5rem;
    height: 1.7rem;
    text-align: center;
    font-weight: bold;
    border-radius: var(--border-radius) 0 var(--border-radius) 0;
}
.b2b-ad-image {
    width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}
@media (min-width: 360px) {
    .longer-cta { display: none; }
}
@media (min-width: 1440px) {
    .longer-cta { display: inline; }
}
</style>
