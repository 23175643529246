<template>
    <div v-for="n in 4" :key="n" class="star-row" :class="{selected: rating === 5 - n}" @click="rating = 5 - n">
        <img v-for="star in 4" :key="star" :src="starImagePath(5 - star, n)" class="star-icon" alt="Sternebewertung">
        <span class="more"> & mehr</span>
    </div>
</template>

<script setup>
const rating = defineModel({ type: Number })

function starImagePath (star, row) {
    return `/icons/stars/${star >= row ? 'star-primary-solid' : 'star-primary'}.svg`
}
</script>

<style scoped>
.star-row {
    display: flex;
    align-items: center;
    margin-top: 0.3rem;
    column-gap: 0.1rem;
    cursor: pointer;
}
.star-row:hover, .selected {
    font-weight: bold;
}
.star-icon {
    width: 1.2rem;
    height: 1.2rem;
}
.more {
    margin-left: 0.3rem;
}
</style>
