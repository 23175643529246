<template>
    <button
        class="address-toggle"
        :class="{ yellow: text === 'Standort angeben' }"
        @click="searchStore.setShowAddressModal(true)"
    >
        <img
            class="gps-icon"
            src="/assets/images/allekommen-icon.svg"
            alt="Location Icon"
            loading="lazy"
        >
        <span class="address-text">
            {{ text }}
        </span>
    </button>
</template>

<script setup>
const searchStore = useSearchStore()
const { address } = storeToRefs(searchStore)

const text = computed(() => {
    return address.value ? address.value.displayName : 'Standort angeben'
})

// TODO set address from session storage
/* onMounted(() => {
    const savedLat = addressFromSession?.lat
    const savedLon = addressFromSession?.lon
    if (addressFromSession) {
        searchStore.setLocationFilters(addressFromSession)
    }
    if (locationIdsFromSession) {
        searchStore.setLocationIds(locationIdsFromSession)
    }
}) */
</script>

<style scoped>
.address-toggle {
    border: none;
    max-width: 10.5rem;
    width: fit-content;
    border-radius: var(--border-radius);
    height: calc(1rem * var(--line-height) + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
    padding: 0.35rem 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.5rem;
    cursor: pointer;
}
.gps-icon {
    height: 1.5rem;
}
.address-text {
    width: fit-content;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 0.85rem;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
