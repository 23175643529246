<template>
    <img
        src="/assets/icons/sustainability.svg"
        alt="Nachhaltigkeitsbadge"
        loading="lazy"
    >
</template>

<script setup>
</script>

<style scoped>

</style>
