<template>
    <span class="new-link">NEU</span>
</template>

<style scoped>
.new-link {
    padding: 0.1rem 0.4rem;
    background-color: var(--yellow);
    color: var(--color);
    border-radius: var(--border-radius);
}
</style>
