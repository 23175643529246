<template>
    <p v-if="pending" aria-busy="true" class="centered mt-2">
        Lade...
    </p>
    <p v-else-if="error" class="centered mt-2">
        Ortsseite kann nicht angezeigt werden 😔
    </p>
    <section v-else>
        <NavigationBreadCrumbs :breadcrumbs="breadcrumbs" />
        <h1>{{ seoTitle }}{{ pageString }}</h1>
        <SearchResultsPage
            :page-title="seoTitle"
            :mandatory-search-parameters="mandatorySearchParameters"
            search-api-endpoint="/search/in-location"
        />
        <div v-if="otherLinks?.length">
            <h2>Weitere Orte in {{ parentLocation }}</h2>
            <div class="links">
                <NuxtLink
                    v-for="link in otherLinks"
                    :key="link.name"
                    :to="localePath({ name: 'locations-locationName', params: { locationName: link.permanentName } })"
                >
                    {{ link.name }}
                </NuxtLink>
            </div>
        </div>
    </section>
</template>

<script setup>
const route = useRoute()
const locationName = route.params.locationName

const { data, pending, error } = await useIFetch(`/locations/${locationName}`)
if (error.value) { throw error.value }
const { location, otherLinks } = data.value

const parentLocation = location.adminLevel >= 3 ? location.parentDistrictName : location.name

const mandatorySearchParameters = { mandatoryLocationId: location.id }

const seoTitle = `Mobile Dienstleistungen und Lieferservices in ${location?.name} kommen zu dir`
const { pageString } = useQueryPageString()

const localePath = useLocalePath()
const breadcrumbs = [] // TODO: add link { name: 'Orte' }
if (location.parentStateName) {
    breadcrumbs.push({
        name: location.parentStateName,
        item: localePath({ name: 'locations-locationName', params: { locationName: location.parentStatePermanentName } })
    })
}
if (location.parentDistrictName && location.parentDistrictPermanentName !== 'wien') {
    breadcrumbs.push({
        name: location.parentDistrictName,
        item: localePath({ name: 'locations-locationName', params: { locationName: location.parentDistrictPermanentName } })
    })
}
breadcrumbs.push({ name: location.name })

useSchemaOrg([
    defineBreadcrumb({
        itemListElement: breadcrumbs
    })
])
useHead({
    title: `${seoTitle}${pageString.value}`,
    meta: [
        { name: 'description', content: `✓ große Auswahl ✓ Beste Anbieter ✓ Kundenbewertungen ✓ Zuverlässig ✓ Bequem - kommen zu dir nach ${location?.name} ✓ nach Hause ✓ ins Büro ✓ zu Verwandten` }
    ]
})
</script>

<style scoped>
.links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: var(--spacing);
    row-gap: 0.5rem;
    max-width: 100%;
}
.links a { width: fit-content; }
@media (min-width: 768px) {
    .links {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (min-width: 1024px) {
    .links {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
</style>
