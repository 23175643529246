<template>
    <CategoryFilteredOverviewPage
        category-type="delivery"
        h1="Lieferservices in der Nähe aus zahlreichen Kategorien"
        top-snippet="Entdecke Dienstleistungen zahlreicher Lieferservice-Kategorien"
    />
</template>

<script setup>
const metaTitle = 'Lieferservices in der Nähe'
const metaDescription = 'Lieferservices in der Nähe aus zahlreichen Kategorien'
useHead({
    title: metaTitle,
    meta: [{ name: 'description', content: metaDescription }]
})
</script>

<style scoped>
</style>
