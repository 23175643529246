<template>
    <p
        v-if="pending"
        aria-busy="true"
        class="centered mt-2"
    >
        Lade...
    </p>
    <p
        v-else-if="error"
        class="centered mt-2"
    >
        Kategorie kann nicht angezeigt werden 😔
    </p>
    <section v-else>
        <NavigationBreadCrumbs :breadcrumbs="breadcrumbs" />
        <h1>{{ customH1 || h1 }}{{ pageString }}</h1>
        <p v-if="topSnippet">
            {{ topSnippet }}
        </p>
        <SearchResultsPage
            :page-title="seoTitle"
            :mandatory-search-parameters="mandatorySearchParameters"
            search-api-endpoint="/search/in-location"
            :attributes="attributes"
        />
        <section
            v-if="lName === 'wien-1415' && !isNationalCategory && locale === 'at'"
            class="mt-2"
        >
            <h2 class="mt-1">
                Finde {{ category.name }} auch in anderen Wiener Bezirken
            </h2>
            <LinksToCategoryInLocationsContainer
                :category-name="category.name"
                :category-permanent-name="cName"
                :locations="viennaDistricts"
            />
        </section>
        <LinksToOtherCategoriesInLocation
            class="mt-2"
            :location-name="location.name"
            :location-name-with-id="route.params.locationName"
        />
        <div
            v-if="otherLinks?.length"
            class="mt-1"
        >
            <h2>{{ category.name }} in weiteren Orten von {{ parentLocation }}</h2>
            <div class="links">
                <NuxtLink
                    v-for="link in otherLinks"
                    :key="link.name"
                    :to="getLinkToCategoryInLocation(category.permanentName, link.permanentName)"
                >
                    {{ link.name }}
                </NuxtLink>
            </div>
        </div>
    </section>
</template>

<script setup>
definePageMeta({
    middleware: defineNuxtRouteMiddleware(async (to) => {
        if (import.meta.client) {
            return
        }
        const locationName = to.params.locationName
        let locationRedirectName = locationName
        const locationRegex = /^[-0-9a-zA-Z]+-\d+$/ // matches all urls that have a -<number> at the end
        if (locationRegex.test(locationName)) {
            const { data, error } = await useIFetch(`/locations/redirect/inspire/${locationName}`)
            if (error.value) {
                throw error.value
            }
            locationRedirectName = data.value.locationName
        }
        const categoryName = specialLookup[to.params.categoryName] || to.params.categoryName
        if (specialLookup[to.params.categoryName] || locationRedirectName !== locationName) {
            return navigateTo(`/${categoryName}/in/${locationRedirectName}`, { redirectCode: 301 })
        }
    })
})
const route = useRoute()
const localePath = useLocalePath()
const { categoryName: cName, locationName: lName } = route.params
const isNationalCategory = nationalCategories.includes(cName)

// fetch data from backend
const { data, pending, error } = await useIFetch(`/categories/${cName}/in/${lName}`)
if (error.value) {
    throw error.value
}
const { category, location, otherLinks, attributes } = data.value

const parentLocation = location.adminLevel >= 3 ? location.parentDistrictName : location.name

// Build the breadcrumbs with location links
function getLinkToCategoryInLocation (categoryName, locationName) {
    return localePath({ name: 'categoryName-in-locationName', params: { categoryName, locationName } })
}
const breadcrumbs = [
    { name: category.name, item: localePath({ name: 'categoryName', params: { categoryName: cName } }) }
]
if (location.parentStateName) {
    breadcrumbs.push({
        name: location.parentStateName,
        item: getLinkToCategoryInLocation(cName, location.parentStatePermanentName)
    })
}
if (location.parentDistrictName && location.parentDistrictPermanentName !== 'wien') {
    breadcrumbs.push({
        name: location.parentDistrictName,
        item: getLinkToCategoryInLocation(cName, location.parentDistrictPermanentName)
    })
}
breadcrumbs.push({ name: location.name })

const { pageString } = useQueryPageString()
const h1 = `${category.name} Anbieter in ${location.name} kommen zu dir - Österreichs große Auswahl`
const customH1 = substituteCategoriesGeoTemplate(category.customH1GeoTemplate, location.name) || category.customH1
const topSnippet = substituteCategoriesGeoTemplate(category.topSnippetGeoTemplate, location.name) || category.topSnippet

const mandatorySearchParameters = {
    categoryId: category.id,
    mandatoryLocationId: location.id
}

const { $captureEvent } = useNuxtApp()
onMounted(() => $captureEvent('category-view', { category: category.name }))

const config = useRuntimeConfig().public
const locale = config.locale
const SPACES_URL = config.spacesUrl
const categoryImage = `${SPACES_URL}/images/categories/${category.image}`
const customSeoTitle = substituteCategoriesGeoTemplate(category.seoTitleGeoTemplate, location.name) || category.seoTitle
const seoTitle = customSeoTitle || `${category?.name} in ${location.name} kommen zu dir`
const metaDescription = substituteCategoriesGeoTemplate(category.metaDescriptionGeoTemplate, location.name) || category.metaDescription
useSchemaOrg([
    defineBreadcrumb({
        itemListElement: breadcrumbs
    })
])
useHead({
    title: `${seoTitle}${pageString.value}`,
    meta: [
        { name: 'robots', content: isNationalCategory ? 'noindex' : 'index' },
        { name: 'og:image', content: categoryImage },
        { name: 'description', content: metaDescription || `✓ große Auswahl ✓ Beste Anbieter ✓ Kundenbewertungen ✓ Zuverlässig ✓ Bequem - ${category?.name} kommen zu dir nach ${location?.name} ✓ nach Hause ✓ ins Büro ✓ zu Verwandten` }
    ]
})
</script>

<style scoped>
.links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: var(--spacing);
    row-gap: 0.5rem;
    max-width: 100%;
}
.links a { width: fit-content; }
@media (min-width: 768px) {
    .links {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (min-width: 1024px) {
    .links {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
</style>
