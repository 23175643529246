export function useClickout (link, trackingLink) {
    const { data } = useAuthState()
    const query = useRoute().query
    const finalLink = isInternalUser(data.value) || !trackingLink ? link : trackingLink
    if (finalLink === trackingLink) {
        try {
            trackClickout(query)
        } catch (e) {}
    }
    // redirect user
    query.utm_source === 'b2c_app'
        ? window.location.href = finalLink
        : window.open(finalLink, '_blank')
}
