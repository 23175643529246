<template>
    <div class="coupon-card">
        <span class="angebot">Angebot</span>
        <FavoritesIcon :favorites-icon="favoritesIcon" @click="toggleFavorites" />
        <CouponsCountDown class="count-down" :expires="coupon.expiresAt" />
        <p class="title">
            {{ coupon.title }}
        </p>
        <p v-if="coupon.couponCode" class="red-text" @click="copyToClipboard(coupon.couponCode)">
            Dein Gutscheincode:
            <span>{{ coupon.couponCode }}</span>
            <img src="assets/icons/copy-red.svg" alt="copy icon" loading="lazy">
        </p>
        <p :class="{ 'line-clamp': isLengthLimited }">
            {{ coupon.content }}
        </p>
        <div class="wrapper">
            <p>
                <small>Einlösbar bis:</small><br>
                <NuxtTime
                    :datetime="date"
                    year="numeric"
                    month="long"
                    day="numeric"
                    hour="numeric"
                    minute="numeric"
                    hour-cycle="h24"
                    locale="de-AT"
                />
            </p>
            <button @click="useClickout(coupon.link, coupon.trackingLink)">
                Einlösen
            </button>
        </div>
    </div>
</template>

<script setup>
import { EMPTY_HEART_ICON, FILLED_HEART_ICON } from '~/constants'

const props = defineProps({
    coupon: { type: Object, required: true },
    isLengthLimited: { type: Boolean, default: false }
})

const date = new Date(props.coupon.expiresAt)

const toast = useToast()
const isAuthenticated = useIsAuthenticated()
const favoritesStore = useFavoritesStore()
const { localFavoriteBusinessCoupons, favoriteBusinessCoupons } = storeToRefs(favoritesStore)

const isFavourite = computed(() => {
    return isAuthenticated.value
        ? favoriteBusinessCoupons.value.some(coupon => coupon.id === props.coupon.id)
        : localFavoriteBusinessCoupons.value.some(coupon => coupon.id === props.coupon.id)
})

const favoritesIcon = computed(() => {
    return isFavourite.value ? FILLED_HEART_ICON : EMPTY_HEART_ICON
})

async function toggleFavorites () {
    if (!isAuthenticated.value) {
        // save or remove service to local favorites
        isFavourite.value
            ? favoritesStore.removeBusinessCouponFromLocalFavourites(props.coupon.id)
            : favoritesStore.addBusinessCouponToLocalFavourites(props.coupon)
        return
    }
    try {
        // save or remove service to user favorites in DB
        isFavourite.value
            ? await favoritesStore.removeBusinessCouponFromFavorites(props.coupon.id)
            : await favoritesStore.addBusinessCouponToFavorites(props.coupon)
    } catch (error) {
        handleBackendError({ error, toast })
    }
}

async function copyToClipboard (couponCode) {
    try {
        await navigator.clipboard.writeText(couponCode)
        toast.success('Code in die Zwischenablage kopiert!')
        setTimeout(() => useClickout(props.coupon.link, props.coupon.trackingLink), 1500)
    } catch (err) {
        toast.error('Code konnte nicht in die Zwischenablage kopiert werden!')
    }
}
</script>

<style scoped>
.coupon-card {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: var(--border-radius);
    padding: var(--spacing);
    box-shadow: var(--small-shadow);
    border: 1px solid var(--red);
    position: relative;
}
.red-text {
    color: var(--red);
    border-radius: var(--border-radius);
    border: 1px solid var(--red);
    padding: 0.5rem;
    cursor: pointer;
    width: fit-content;
    column-gap: 0.3rem;
    display: flex;
}
.red-text img {
    width: 1rem;
}
.title {
    font-size: 1.1rem;
    font-weight: bold;
}
.count-down {
    margin-top: 2rem;
}
.angebot {
    display: grid;
    place-items: center;
    background-color: var(--red);
    color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    width: 5rem;
    height: 2rem;
    text-align: center;
    font-weight: bold;
    border-radius: 0 0 var(--border-radius) 0;
}
.wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: auto;
}
.wrapper button { width: fit-content; }
.wrapper p {
    margin: 0;
}
.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
@media (min-width: 375px) {
    .count-down {
        margin-top: 0;
        text-align: center;
    }
    .coupon-card { padding-top: 0.5rem; }
}
</style>
