<template>
    <section>
        <!-- DO NOT CHANGE THE URL OF THIS PAGE IT MUST BE kundensupport/konto-loeschen -->
        <!-- it is bound to the play and apple store -->
        <NavigationGoBack />
        <h1 class="mt-1">
            Konto löschen
        </h1>
        <p>
            Um dein allekommen.at Konto zu löschen, folge diesen Schritten:
        </p>
        <ol>
            <li>Öffne dein allekommen.at Konto in deinem Browser.</li>
            <li>Gehe zum Abschnitt 'Konto-Einstellungen'.</li>
            <li>Scrolle nach unten zu 'Konto löschen'.</li>
            <li>Klicke darauf und folge den Anweisungen, um dein Konto zu löschen.</li>
        </ol>
        <p>
            Beachte bitte, dass das Löschen deines Kontos keine Auswirkungen auf andere Konten hat, die du möglicherweise hast.
        </p>
        <p>
            Hier erhältst du Informationen zur
            <NuxtLink :to="localePath('privacy')">
                Datenaufbewahrung
            </NuxtLink>.
            Falls du weitere Fragen hast, stehen wir im
            <NuxtLink :to="localePath('customer-support')">
                Kundensupport
            </NuxtLink>
            gerne zur Verfügung.
        </p>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
useHead({
    title: 'Konto löschen',
    meta: [{ name: 'robots', content: 'noindex' }]
})
</script>

<style scoped>

</style>
