<template>
    <section>
        <h2>Mehr mobile Dienstleistungen und Lieferservices in {{ locationName }}</h2>
        <div class="links-container">
            <NuxtLink
                v-for="category in categories"
                :key="'tcl-' + category.id"
                :to="getCategoryLocationLink(category.permanentName)"
            >
                {{ category.name }}
            </NuxtLink>
        </div>
    </section>
</template>

<script setup>
const props = defineProps({
    /** A permanent name with ID for the location */
    locationNameWithId: { type: String, required: true },
    /** The display name for the location. */
    locationName: { type: String, required: true }
})

const localePath = useLocalePath()
const locale = useRuntimeConfig().public.locale

// national categories whose links should not be here for duplicate content reasons
const categories = trendingCategories[locale].filter(category => !nationalCategories.includes(category.permanentName))

function getCategoryLocationLink (categoryPermanentName) {
    return localePath({
        name: 'categoryName-in-locationName',
        params: {
            categoryName: categoryPermanentName,
            locationName: props.locationNameWithId
        }
    })
}
</script>

<style scoped>
.links-container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: var(--spacing);
    row-gap: 0.5rem;
    max-width: 100%;
}
.links-container a { width: fit-content; }
@media (min-width: 425px) {
    .links-container {
        grid-template-columns: 1fr 1fr;
    }
}
@media (min-width: 768px) {
    .links-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (min-width: 1024px) {
    .links-container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
</style>
