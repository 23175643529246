<template>
    <section class="registration-card">
        <p class="title centered">
            Vielen Dank fur Deine Registrierung!
        </p>
        <img src="/assets/images/new-message.png" alt="Mail message">
        <p>Eine E-Mail wurde an die angegebene Adresse zur Verifizierung gesendet.Bitte überprüfe dein Postfach und klicke auf den Verifizierungslink, um dein Konto zu aktivieren.</p>
        <p>
            Danach kannst du dich
            <NuxtLink :to="localePath('login')">
                hier anmelden
            </NuxtLink>  und unsere Plattform erkunden.
        </p>
        <NuxtLink role="button" class="button" :to="localePath('/')">
            Zur Startseite
        </NuxtLink>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
</script>

<style scoped>
.registration-card {
    border-radius: var(--border-radius);
    background-color: var(--white);
    width: 27rem;
    padding: 1.5rem;
    box-shadow: var(--shadow);
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
}
.title {
    font-weight: bold;
    font-size: 1.3rem;
}
img {
    display: block;
    margin: 0 auto;
}
.button {
    width: 80%;
    display: block;
    margin: 0 auto;
}
</style>
