<template>
    <AccordionComponent
        :title="title"
        :open="true"
    >
        <template #links>
            <NuxtLink
                v-for="location in locations"
                :key="location.permanentName"
                :to="localePath({ name: 'locations-locationName', params: { locationName: location.permanentName } })"
            >
                {{ location.name }}
            </NuxtLink>
        </template>
    </AccordionComponent>
</template>

<script setup>
const { title, locations } = defineProps({
    title: { type: String, required: true },
    locations: { type: Array, required: true }
})
const localePath = useLocalePath()
</script>

<style scoped>
a { color: var(--white); }
</style>
