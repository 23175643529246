<template>
    <div class="coupons-wrapper">
        <slot name="content" />
    </div>
</template>

<script setup>
</script>

<style scoped>
.coupons-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing);
}
@media (min-width: 768px) {
    .coupons-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: var(--spacing);
    }
}
@media (min-width: 1200px) {
    .coupons-wrapper {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
</style>
