<template>
    <div class="note-box">
        <div class="note-text">
            <slot name="text" />
        </div>
        <slot name="button" />
    </div>
</template>

<style scoped>
.note-text {
    margin: 0;
}
.note-box {
    width: 100%;
    background-color: var(--primary-100);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--spacing);
    border-radius: var(--border-radius);
    margin-left: auto;
    margin-right: auto;
    row-gap: var(--spacing);
    border: 1px solid var(--primary-300);
}
@media (min-width: 768px) {
    .note-box {
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: var(--spacing);
    }
}
</style>
