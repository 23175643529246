<template>
    <div :class="{ show: sidebarOpen }" class="sidebar">
        <CloseIcon color="white" class="close-sidebar" @close="searchStore.setSidebarOpen(false)" />
        <div class="sidebar-header">
            <NuxtLink
                v-if="!isAuthenticated"
                :to="localePath('login')"
                class="login-link"
                @click.prevent="searchStore.setSidebarOpen(false)"
            >
                Anmelden
                <img src="/icons/user-solid.svg" class="nav-icon-container" alt="Account Icon">
            </NuxtLink>
            <p v-else class="welcome-message">
                {{ welcomeMessage }}
            </p>
            <p class="discover-title">
                allekommen.at erkunden
            </p>
        </div>
        <NuxtLink :to="localePath('index')" @click.prevent="searchStore.setSidebarOpen(false)">
            <div class="home-link">
                allekommen.at-Startseite
                <img src="/assets/icons/home-solid.svg" alt="Locations Icon" loading="lazy">
            </div>
        </NuxtLink>
        <div class="sidebar-container">
            <NuxtLink
                :to="localePath({
                    name: 'categoryName', params: { categoryName: 'shopping-angebote' }
                })"
                @click.prevent="searchStore.setSidebarOpen(false)"
            >
                <span>Shopping-Angebote <ActionTag /></span>
                <img src="/assets/icons/arrows/chevron-right-solid.svg" alt="Weiter zur Kategorie Shooping-Angebote" loading="lazy">
            </NuxtLink>
            <div v-for="group in categoryGroups" :key="group.title" class="group">
                <p class="sidebar-title">
                    {{ group.title }}
                </p>
                <NuxtLink
                    v-for="category in group.categories"
                    :key="category.permanentName"
                    :to="localePath({ name: 'categoryName', params: { categoryName: category.permanentName } })"
                    @click.prevent="searchStore.setSidebarOpen(false)"
                >
                    <span>
                        {{ category.name }}
                        <LazyActionTag v-if="category.action" />
                        <LazyNewTag v-if="category.new" />
                    </span>
                    <img src="/assets/icons/arrows/chevron-right-solid.svg" :alt="`Weiter zur Kategorie ${category.name}`" loading="lazy">
                </NuxtLink>
            </div>
            <p role="link" @click="gotoSearch">
                Entdecke weitere Kategorien
                <img src="/assets/icons/arrows/chevron-right-solid.svg" alt="Zur Suche" loading="lazy">
            </p>
        </div>
    </div>
</template>

<script setup>
const localePath = useLocalePath()
const isAuthenticated = useIsAuthenticated()
const { data } = useAuthState()
const searchStore = useSearchStore()
const { sidebarOpen } = storeToRefs(searchStore)

function gotoSearch () {
    searchStore.setSidebarOpen(false)
    navigateTo(localePath('search'))
}

const welcomeMessage = computed(() => 'Hallo' + (data.value?.firstName ? ', ' + data.value.firstName : '') + '!')
const categoryGroups = [
    {
        title: 'Essen und Trinken',
        categories: [
            { name: 'Essen bestellen', permanentName: 'essen-bestellen', action: true },
            { name: 'Lebensmittel bestellen', permanentName: 'lebensmittel-bestellen', new: true },
            { name: 'Getränke Lieferservice', permanentName: 'getraenke-lieferservice' },
            { name: 'Catering', permanentName: 'catering' },
            { name: 'Mobile Barkeeper', permanentName: 'mobile-barkeeper-cocktails' }
        ]
    },
    {
        title: 'Betreuung & Haushalt',
        categories: [
            { name: 'Haushaltshilfe', permanentName: 'haushaltshilfe', new: true },
            { name: 'Haushaltsartikel Lieferservice', permanentName: 'haushaltsartikel-lieferservice', new: true },
            { name: 'Babysitter', permanentName: 'babysitter', action: false },
            { name: 'Kinderbetreuung', permanentName: 'mobile-kinderbetreuung', action: true },
            { name: 'Personenbetreuung', permanentName: 'personenbetreuung' },
            { name: 'Au Pair', permanentName: 'aupair' },
            { name: 'Nachhilfe', permanentName: 'nachhilfe', action: true },
            { name: 'Tierbetreuung', permanentName: 'tierbetreuung' },
            { name: 'Gartenpflege', permanentName: 'gartenpflege' },
            { name: 'Reinigungsfirma', permanentName: 'reinigungsfirma' },
            { name: 'Schädlingsbekämpfung', permanentName: 'schaedlingsbekaempfung' },
            { name: 'Mobile Textilreinigung', permanentName: 'mobile-textilreinigung' }
        ]
    },
    {
        title: 'Gesundheit und Wellness',
        categories: [
            { name: 'Arzt Hausbesuch', permanentName: 'arzt-hausbesuch' },
            { name: 'Mobiler Optiker', permanentName: 'mobile-optiker' },
            { name: 'Mobile Physiotherapie', permanentName: 'mobile-physiotherapie' },
            { name: 'Mobile Massage', permanentName: 'mobile-massage' },
            { name: 'Mobiler Friseur', permanentName: 'mobiler-friseur' },
            { name: 'Mobile Fußpflege', permanentName: 'mobile-fusspflege' },
            { name: 'Mobile Kosmetiker', permanentName: 'mobile-kosmetiker' },
            { name: 'Mobiles Nagelstudio', permanentName: 'mobiles-nagelstudio' },
            { name: 'Personal Trainer', permanentName: 'personal-trainer' }
        ]
    },
    {
        title: 'Weitere',
        categories: [
            { name: 'Vermessungstechniker', permanentName: 'vermessungstechniker', new: true },
            { name: 'Marketing Agentur', permanentName: 'marketing-agentur', new: true },
            { name: 'Mobiles Coaching', permanentName: 'mobiles-coaching' },
            { name: 'Hochzeitsfotograf', permanentName: 'hochzeitsfotograf' },
            { name: 'Mobiler Fotograf', permanentName: 'mobiler-fotograf', new: true },
            { name: 'Elektronik & Technik Lieferservice', permanentName: 'elektronik-technik-lieferservice', new: true },
            { name: 'Tickets & Gutscheine', permanentName: 'tickets-gutscheine-kaufen', new: true },
            { name: 'Handwerker', permanentName: 'handwerker' },
            { name: 'Blumen liefern lassen', permanentName: 'blumen-liefern-lassen', new: true },
            { name: 'Taxi', permanentName: 'taxi' }
        ]
    }
]
</script>

<style scoped>
.sidebar {
    transform: translateX(-100%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    z-index: 11;
    transition: all 0.5s ease;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.show { transform: translateX(0); }
.sidebar-container {
    display: flex;
    flex-direction: column;
    padding: var(--spacing) 0;
    height: 100%;
    overflow-y: auto;
}
.sidebar-title {
    margin-top: var(--spacing);
    margin-bottom: 0;
    padding-left: var(--spacing);
    font-size: 1.2rem;
    font-weight: bold;
}
.sidebar-container a, p[role="link"] {
    padding: 0.5rem var(--spacing);
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.sidebar-container a img, p[role="link"] img {
    height: 1rem;
    width: 0.5rem;
}
.sidebar-container a:hover, p[role="link"]:hover {
    background-color: var(--primary-200);
}
.group {
    margin-bottom: var(--spacing);
}
.sidebar-header {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    background-color: var(--primary);
    padding: 1rem;
}
.sidebar-header p.discover-title {
    color: var(--white);
    font-size: 1.5rem;
    margin: 0;
    letter-spacing: 1px;
    font-weight: bold;
}
.home-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: var(--white);
    box-shadow: var(--shadow);
    cursor: pointer;
}
.home-link img {
    width: 1.5rem;
    height: 1.5rem;
}
.login-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.2rem;
    color: var(--white);
    width: fit-content;
}
.welcome-message {
    color: var(--white);
    margin-bottom: 0;
}
@media (min-width: 320px) {
    .sidebar { max-width: 22rem; }
}
@media (min-width: 768px) {
    .sidebar { max-width: 20rem; }
    .sidebar-title { margin-top: 0; }
}
@media (min-width: 985px) {
    .sidebar {
        min-width: 22rem;
    }
}
</style>
