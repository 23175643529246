<template>
    <div class="form">
        <div id="contactForm" />
        <progress v-if="!isHubspotLoaded" />
    </div>
</template>

<script setup>
const isHubspotLoaded = ref(false)

onBeforeMount(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
        if (window.hbspt) {
            window.hbspt.forms.create({
                region: 'eu1',
                portalId: '26998599',
                formId: 'd43a5ee4-8014-4991-be45-fd3929858136',
                target: '#contactForm'
            })
        }
        isHubspotLoaded.value = true
    })
})
</script>

<style scoped>
.form {
    width: 100%;
    padding: 2.5rem;
    border-radius: var(--border-radius);
    box-shadow: var(--small-shadow);
}
@media (min-width: 992px) {
    .form {
        width: 65%;
    }
}
</style>
