<template>
    <div class="links-container">
        <NuxtLink
            v-for="(city, index) in locations"
            :key="'c-' + index"
            :to="getCategoryLocationLink(city.permanentName)"
        >
            {{ city.name }}
        </NuxtLink>
    </div>
</template>

<script setup>
const props = defineProps({
    /** A permanent name for the category */
    categoryPermanentName: { type: String, required: true },
    /** The display name for the category. */
    categoryName: { type: String, required: true },
    locations: { type: Array, required: true }
})

const localePath = useLocalePath()

/**
 * Generate a link for a category location.
 * @param {string} locationNameWithId - The location name with its ID.
 * @returns {string} - The generated link.
 */
function getCategoryLocationLink (locationNameWithId) {
    return localePath({
        name: 'categoryName-in-locationName',
        params: {
            categoryName: props.categoryPermanentName,
            locationName: locationNameWithId
        }
    })
}
</script>

<style scoped>
.links-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--spacing);
    row-gap: 0.5rem;
    max-width: 100%;
}
.links-container a { width: fit-content; }
@media (min-width: 768px) {
    .links-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (min-width: 1024px) {
    .links-container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
</style>
