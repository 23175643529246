import * as Sentry from '@sentry/vue'
import {
    CLICKOUT_EVENT_NAME, HYPHEN
} from '~/constants'

/**
 * Remove all UTM parameters from a URL.
 * @param inputUrl {string}
 * @returns {string}
 */
function removeUTMParameters (inputUrl) {
    const urlObject = new URL(inputUrl)
    const filteredParams = Array.from(urlObject.searchParams.entries())
        .filter(([key]) => {
            const lowerKey = key.toLowerCase()
            return !(lowerKey.startsWith('utm')
              || lowerKey.startsWith('gclid')
              || lowerKey.startsWith('gclsrc')
              || lowerKey.startsWith('el')
              || lowerKey.startsWith('htrafficsource')
              || lowerKey.startsWith('fbclid'))
        })
    urlObject.search = new URLSearchParams(filteredParams).toString()
    return urlObject.toString()
}

/**
 * A function that shares text, URL, and title using the Web Share API.
 * @param {string} text - The text to share.
 * @param {string} url - The URL to share.
 * @param {string} title - The title to share.
 * @return {Promise<void>} A promise that resolves when the sharing is complete.
 */
export async function handleShare (text, url, title) {
    const toast = useToast()
    const { $captureEvent } = useNuxtApp()
    const cleanUrl = removeUTMParameters(url)
    if (navigator.share) {
        try {
            await navigator.share({ title, text, url: cleanUrl })
        } catch (e) {}
    } else {
        await navigator.clipboard.writeText(cleanUrl)
        toast.success('Link in die Zwischenablage kopiert!')
    }
    $captureEvent('share')
}

/**
 * Tracks a clickout event with posthog, bing and taboola.
 * @param {Object} query - The query object containing query parameters.
 */
export function trackClickout (query) {
    const { $captureEvent } = useNuxtApp()
    $captureEvent(CLICKOUT_EVENT_NAME)
    // track clickout with bing
    window.uetq = window.uetq || []
    window.uetq.push('event', CLICKOUT_EVENT_NAME)
    // track taboola
    /* const taboolaClickId = query[TABOOLA_CLICK_ID]
    if (taboolaClickId) {
        $fetch(`${TABOOLA_BASE_URL}?click-id=${taboolaClickId}&name=${CLICKOUT_EVENT_NAME}`, { method: 'POST' })
    } */
}

/**
 * Parses a URL parameter by decoding the encoded parameter and replacing all special hyphens with spaces.
 * @param {string} encodedParam - The encoded parameter to be parsed.
 * @return {string} - The decoded parameter with hyphens replaced by spaces.
 */
export function parseUrlParameter (encodedParam) {
    return decodeURIComponent(encodedParam).replaceAll(HYPHEN, ' ')
}

export function handleBackendError ({ error, toast }) {
    Sentry.captureException(error)
    if (!error.response) {
        toast.error(error.message)
    } else if (error.response._data.msg) {
        toast.error(error.response._data.msg)
    } else {
        error.response._data.errors.forEach((error) => {
            toast.error(error)
        })
    }
}

export function substituteCategoriesGeoTemplate (template, locationName) {
    return template?.replace(/{Stadt}/g, locationName)
    // return template?.replaceAll('{Stadt}', locationName) // this is not supported by all browsers
}
