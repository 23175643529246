import { ALLEKOMMEN_ROOT } from '~/constants'

/** Gets the image path for the service, using the main image or a fallback image. */
export function getServiceImagePath (service, SPACES_URL) {
    return service.mainImage
        ? `${SPACES_URL}/images/${service.serviceType}_service/${service.mainImage}`
        : `${SPACES_URL}/images/categories/${service.category.image}`
}

/** Gets the link to the service with the appropriate query parameters. */
export function getServicePageLink (service) {
    const localePath = useLocalePath()
    return localePath({
        name: 'provider-name-mobile-serviceName',
        params: {
            name: `${service.business.permanentName}-${service.business.id}`,
            serviceName: `${service.permanentName}-${service.id}`
        }
    })
}

/** Gets the image path for the category.
 * @param {string} imageName - The name of the image.
 * @param {string} SPACES_URL - The URL of the Spaces CDN.
 */
export function getCategoryImagePath (imageName, SPACES_URL) {
    return `${SPACES_URL}/images/categories/${imageName}`
}

export function generateItemListElementForServices (services, SPACES_URL) {
    return services.map((service, index) => {
        const item = {
            '@type': service.totalReviews > 0 ? 'Product' : 'Service',
            url: `${ALLEKOMMEN_ROOT}${getServicePageLink(service)}`,
            name: service.name,
            image: getServiceImagePath(service, SPACES_URL)
        }
        if (service.totalReviews > 0) {
            item.aggregateRating = {
                '@type': 'AggregateRating',
                ratingValue: service.rating,
                bestRating: 5,
                ratingCount: service.totalReviews
            }
        }
        return {
            '@type': 'ListItem',
            position: index + 1,
            item
        }
    })
}
