<template>
    <div class="address-search-container">
        <input
            v-model="addressInput"
            placeholder="Gib hier deine Adresse ein"
            type="search"
            @input="searchAddress"
            @focus="showAddressSuggestions = true"
        >
        <button
            class="yellow submit-btn inline-gps-button"
            :disabled="disallowAddressSelection"
            :aria-busy="loadingGps"
            @click="setAddress"
        >
            <img
                v-if="!loadingGps && addressInput.length === 0"
                loading="lazy"
                class="inline-gps-icon"
                src="assets/icons/location-crosshairs-solid.svg"
                alt="GPS Standpunkt ermitteln"
                title="GPS Standpunkt ermitteln"
            >
            {{ ctaText }}
        </button>
        <transition>
            <div v-if="showAddressSuggestions && addressInput.length > 2" class="address-suggestions">
                <div v-if="!noResults" class="suggestions-scrollable-content">
                    <p
                        v-for="(item, index) in searchResults"
                        :key="index"
                        class="address-suggestion"
                        @click="fetchGpsForAddress(item)"
                    >
                        <img src="assets/icons/location/location-solid.svg" loading="lazy" alt="Standort" title="Standort">
                        {{ item.displayName }}
                    </p>
                </div>
                <div v-else-if="noResults" class="no-address-result">
                    <p>Leider konnten wir keine Adresse für deine Eingabe finden. Versuche es mit der GPS Funktion.</p>
                    <button class="inline-gps-button" :aria-busy="loadingGps" @click="getGpsFromBrowser">
                        <img
                            loading="lazy"
                            class="inline-gps-icon"
                            src="assets/icons/location-crosshairs-white.svg"
                            alt="GPS Standpunkt ermitteln"
                            title="GPS Standpunkt ermitteln"
                        >
                        Standort ermitteln
                    </button>
                </div>
                <img v-if="!noResults" src="assets/google_on_white.png" alt="Google Logo" loading="lazy" class="google-logo">
            </div>
        </transition>
    </div>
</template>

<script setup>
import { SEARCH_SUGGESTIONS_DEBOUNCE } from '~/constants'

const emit = defineEmits(['address-selected'])

const toast = useToast()
const searchStore = useSearchStore()
const { address } = storeToRefs(searchStore)

const addressInput = ref(address.value?.displayName || '')
const searchResults = ref([]) // An array to store search results for addresses.
const loading = ref(false)
const searchDebounce = ref(null)
const noResults = ref(false)
const useBrowserGps = ref(false)
const selectedAddress = ref(address.value || null)
const showAddressSuggestions = ref(false)

const ctaText = computed(() => {
    return addressInput.value.length === 0 ? 'Standort ermitteln' : 'Standort setzen'
})
const disallowAddressSelection = computed(() => {
    return !selectedAddress.value && searchResults.value.length === 0 && addressInput.value.length !== 0
})

const { $captureEvent } = useNuxtApp()
function searchAddress () {
    clearTimeout(searchDebounce.value)
    if (addressInput.value.length < 3) { return }
    searchDebounce.value = setTimeout(async () => {
        loading.value = true
        searchResults.value = await $fetch('/api/address-search/places-autocomplete', {
            query: { input: addressInput.value }
        })
        noResults.value = searchResults.value.length === 0
        loading.value = false
        $captureEvent('address-search', { term: addressInput.value })
    }, SEARCH_SUGGESTIONS_DEBOUNCE)
}

async function fetchGpsForAddress (address) {
    const results = await $fetch('/api/address-search/geocoding', {
        query: { placeId: address.placeId }
    })
    if (!results) { return toast.error('Die Adresse konnte nicht gefunden werden') }
    selectAddress(results[0])
}

function selectAddress (address) {
    selectedAddress.value = address
    addressInput.value = address.displayName
    showAddressSuggestions.value = false
    noResults.value = false
    useBrowserGps.value = false
}

const { $backendApi } = useNuxtApp()
async function setAddress () {
    if (!selectedAddress.value && searchResults.value.length !== 0) {
        // user started search but did not select an address: take the first from suggestions
        await fetchGpsForAddress(searchResults.value[0])
    } else if (!selectedAddress.value && addressInput.value.length === 0) {
        // nothing in search box, use browser gps
        await getGpsFromBrowser()
        if (!selectedAddress.value) { return } // in case getGpsFromBrowser raised and exception
    }
    searchStore.setLocationFilters({
        address: selectedAddress.value
    })
    // trigger an async call to get the polygon ids for these coordinates -> store in sessionStorage
    await $backendApi(`/locations/gps?lat=${selectedAddress.value.lat}&lon=${selectedAddress.value.lon}`)
    toast.success(`${selectedAddress.value.displayName} festgelegt!`, { timeout: 5000 })
    // this method returns without waiting for getPolygonsForGps to complete
    emit('address-selected')
}

const loadingGps = ref(false)
function getCurrentPosition (options) {
    return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
    )
}
async function getGpsFromBrowser () {
    loadingGps.value = true
    try {
        const position = await getCurrentPosition({ enableHighAccuracy: true, timeout: 10000 })
        const location = {
            lat: position.coords.latitude,
            lon: position.coords.longitude
        }
        const results = await $fetch('/api/address-search/geocoding', {
            query: { latlng: `${location.lat},${location.lon}` }
        })
        if (!results) { return toast.error('Die Adresse konnte nicht gefunden werden') }
        selectAddress(results[0])
    } catch (error) {
        handleGeolocationErrorCallback(error)
    }
    loadingGps.value = false
}

function handleGeolocationErrorCallback (error) {
    switch (error.code) {
    case error.PERMISSION_DENIED:
        toast.error('GPS Zugriff verweigert. Bitte gehe in die Einstellungen des ' +
                            'Browsers und erlaube die Verwendung des Standorts.')
        break
    case error.POSITION_UNAVAILABLE:
        toast.error('Der Standort ist nicht verfügbar.')
        break
    case error.TIMEOUT:
        toast.error('Die Anfrage hat zu lange gedauert, bitte versuche es erneut.')
        break
    default:
        toast.error('Ein unbekannter Fehler ist aufgetreten.')
        break
    }
    loadingGps.value = false
}
</script>

<style scoped>
.address-search-container {
    position: relative;
}
.address-suggestions {
    position: absolute;
    top: 2.5rem;
    left: 0;
    width: 100%;
    background-color: var(--white);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5rem;
    z-index: 5;
    border: 1px solid var(--dark-blue);
}
.suggestions-scrollable-content {
    overflow-y: auto;
    max-height: 15rem;
}
.address-suggestion {
    border-radius: var(--border-radius);
    margin-bottom: 0;
    display: flex;
    align-items: center;
    column-gap: var(--spacing);
    cursor: pointer;
    transition: .2s;
    padding: var(--spacing);
}
.address-suggestion:hover {
    background-color: var(--primary-100);
}
.google-logo {
    display: block;
    margin-left: auto;
    margin-right: 0.5rem;
    height: 1.2rem;
    margin-top: 0.5rem;
}
.no-address-result {
    padding: var(--spacing);
}
.v-enter-active, .v-leave-active {
  transition: opacity 0.3s ease;
}
.v-enter-from, .v-leave-to {
  opacity: 0;
}
.inline-gps-button {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.3rem;
}
.inline-gps-icon {
    height: 1.2rem;
    width: 1.2rem;
}
@media (min-width: 768px) {
    input[type="search"] {
        max-width: 25rem;
        margin-bottom: 0;
    }
    .address-suggestions {
        width: 25rem;
    }
    .address-search-container {
        display: flex;
        flex-direction: row;
        column-gap: var(--spacing);
        align-items: center;
    }
    .submit-btn {
        width: fit-content;
        height: calc(1rem * var(--line-height) + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
        padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
    }
}
@media (min-width: 1024px) {
    input[type="search"] {
        max-width: 22rem;
        margin-bottom: 0;
    }
    .address-suggestions {
        width: 22rem;
    }
}
</style>
