import posthog from 'posthog-js'

export default defineNuxtPlugin(() => {
    const posthogClient = posthog.init(
        'phc_ajwQlgQYzqcvAWOPch8tzKmyaIp7Gjgkw2CF9zQ658w', {
            api_host: 'https://eu.posthog.com',
            capture_pageview: false, // we add manual pageview capturing below
            disable_session_recording: true,
            persistence: 'localStorage',
            debug: false,
            loaded: (posthog) => {
                if (import.meta.env.MODE === 'development') { posthog.debug() }
            }
        }
    )

    // Make sure that pageviews are captured with each route change
    const router = useRouter()
    router.afterEach((to, from) => {
        nextTick(() => {
            posthog.capture('$pageview', {
                current_url: to.fullPath
            })
            posthog.capture('$pageleave', {
                current_url: from.fullPath
            })
        })
    })

    function captureEvent (eventName, properties) {
        posthog.capture(eventName, properties)
    }

    return {
        provide: {
            posthog: () => posthogClient,
            captureEvent
        }
    }
})
