<template>
    <section class="page">
        <h1>{{ welcomeMessage }}</h1>
        <p>
            Konfiguriere dein Profil und speichere deine Adressen und Favoriten.
        </p>
        <div class="mylocaverse-cards-container">
            <NuxtLink :to="localePath('profile-locations')">
                <div class="mylocaverse-navigation-card">
                    <img src="assets/icons/home-solid.svg" alt="Locations Icon" loading="lazy">
                    Meine Standorte
                </div>
            </NuxtLink>

            <NuxtLink :to="localePath('profile-favorites')">
                <div class="mylocaverse-navigation-card">
                    <img src="/icons/hearts/heart-solid-red.svg" alt="Favourites Icon" loading="lazy">
                    Favoriten
                </div>
            </NuxtLink>
        </div>
        <ul class="account-management">
            <li>
                <NuxtLink :to="localePath('profile-settings')">
                    Einstellungen
                </NuxtLink>
            </li>
            <li>
                <button :aria-busy="loading" class="outline" @click="logout">
                    Abmelden
                </button>
            </li>
        </ul>
    </section>
</template>

<script setup>
definePageMeta({
    middleware: 'auth'
})

const { loading, logout } = useLogout()
const localePath = useLocalePath()

const { data } = useAuthState()
const welcomeMessage = 'Willkommen bei allekommen' + (data.value.firstName ? ', ' + data.value.firstName : '') + '!'

useHead({
    title: 'Profil',
    meta: [{ hid: 'noindex', name: 'robots', content: 'noindex' }]
})
</script>

<style scoped>
.mylocaverse-cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    margin: 2rem 0;
}
.mylocaverse-cards-container a {
    text-decoration: none;
}
.mylocaverse-navigation-card {
    display: grid;
    place-items: center;
    padding: 1rem;
    background-color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    transition: box-shadow .3s ease-in-out;
}
.mylocaverse-navigation-card:hover {
    box-shadow: none;
}
.mylocaverse-navigation-card img {
    height: 1.5rem;
    width: 1.5rem;
}
h1 { font-size: 1.2rem; }
.logout { cursor: pointer; }
.account-management {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    margin-top: calc(var(--spacing) * 2);
}
.account-management li {
    list-style-type: none;
    margin-top: calc(var(--spacing) * 1.5);
}
</style>
