import validate from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt/dist/pages/runtime/validate.js";
import trailing_45slash_45redirect_45global from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/middleware/trailing-slash-redirect.global.js";
import manifest_45route_45rule from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  trailing_45slash_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}