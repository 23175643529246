<template>
    <div>
        <h1>Du wirst gleich weitergeleitet...</h1>
        <progress />
    </div>
</template>

<script setup>
definePageMeta({
    auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: '/profil'
    }
})
const query = useRoute().query
if (!query.key) {
    throw createError({
        statusCode: 400,
        statusMessage: 'Need to pass a valid key parameter.'
    })
}
const { data: response, error } = await useIFetch('/auth/oauth2/tokens', {
    method: 'POST', query: { key: query.key }
})
if (error.value) { throw error.value }

const { data, rawToken, rawRefreshToken, refreshToken } = useAuthState()
rawToken.value = response.value.accessToken
refreshToken.value = response.value.refreshToken
rawRefreshToken.value = response.value.refreshToken
const { getSession } = useAuth()
await getSession()

const favoritesStore = useFavoritesStore()
onMounted(() => {
    favoritesStore.handleLogin(data.value)
    navigateTo('/profil')
})
</script>

<style scoped>
div {
    min-height: 70vh;
}
</style>
