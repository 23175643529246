<template>
    <div>
        <div v-if="address" class="mt-1">
            <p class="small-title address-string">
                <img
                    class="gps-icon"
                    src="assets/icons/location/location-solid.svg"
                    alt="Location Icon"
                    loading="lazy"
                >
                Aktueller Standort
                <span class="reset-text" @click="resetLocation">Zurücksetzen</span>
            </p>
            <span class="selected-address">
                {{ address.displayName }}
            </span>
        </div>

        <button class="mt-2" @click="emit('enter-address')">
            Neuen Standort wählen
        </button>

        <div v-if="isAuthenticated" class="mt-1">
            <p class="small-title">
                Gespeicherte Standorte
            </p>
            <div
                v-for="location in filteredUserLocations"
                :key="location.id"
                class="user-location"
                @click="selectUserLocation(location)"
            >
                📍 {{ location.name }}, {{ location.address.displayName }}
            </div>
            <NuxtLink class="block-link mt-1" :to="localePath('profile-locations')">
                Weitere Standorte speichern
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['enter-address'])

const isAuthenticated = useIsAuthenticated()
const { data } = useAuthState()
const searchStore = useSearchStore()
const { address } = storeToRefs(searchStore)
const toast = useToast()
const localePath = useLocalePath()

/**
 * Filters the user's saved locations to exclude the currently displayed address.
 * @returns {Array} An array of saved locations excluding the currently displayed address.
 */
const filteredUserLocations = computed(() => {
    return (data.value?.savedLocations || []).filter(location => location.name !== address.value?.displayName)
})

/**
 * this selects a user saved location, which has a list of location ids and an address
 * @param {Object} location - The selected user saved location.
 */
function selectUserLocation (location) {
    const selectedAddress = { ...location.address, displayName: location.name }
    searchStore.setLocationFilters({
        address: selectedAddress
    })
    document.body.classList.remove('no-scroll')
    searchStore.setShowAddressModal(false)
}

function resetLocation () {
    searchStore.setLocationFilters({ address: null })
    searchStore.setShowAddressModal(false)
    toast.success('Standort wurde zurückgesetzt')
}
</script>

<style scoped>
.user-location {
    margin-top: var(--spacing);
    cursor: pointer;
}
.block-link { display: block; }
.address-string {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    column-gap: 0.3rem;
}
.reset-text {
    display: block;
    margin-left: auto;
    text-decoration: underline;
    font-weight: normal;
    cursor: pointer;
}
.selected-address { display: block; margin-top: 0.5rem; }
</style>
