<template>
    <div class="slider">
        <div class="slides">
            <input
                id="radio1"
                v-model="heroImage"
                type="radio"
                value="1"
            >
            <input
                id="radio2"
                v-model="heroImage"
                type="radio"
                value="2"
            >
            <input
                id="radio3"
                v-model="heroImage"
                type="radio"
                value="3"
            >
            <input
                id="radio4"
                v-model="heroImage"
                type="radio"
                value="4"
            >

            <div class="slide first">
                <NuxtLink
                    :to="localePath({
                        name: 'categoryName',
                        params: { categoryName: 'essen-bestellen' } }
                    )"
                >
                    <img
                        :src="`${imageBase}/essen-bestellen.jpg`"
                        alt="Essen bestellen"
                    >
                </NuxtLink>
            </div>
            <div class="slide">
                <NuxtLink
                    :to="localePath({
                        name: 'categoryName',
                        params: { categoryName: 'haushaltshilfe' } }
                    )"
                >
                    <img
                        :src="`${imageBase}/haushaltshilfe.jpg`"
                        alt="Haushaltshilfe"
                    >
                </NuxtLink>
            </div>
            <div class="slide">
                <NuxtLink
                    :to="localePath({
                        name: 'categoryName',
                        params: { categoryName: 'lebensmittel-bestellen' } }
                    )"
                >
                    <img
                        :src="`${imageBase}/lebensmittel-bestellen.jpg`"
                        alt="Lebensmittel bestellen"
                    >
                </NuxtLink>
            </div>
            <div class="slide">
                <NuxtLink
                    :to="localePath({
                        name: 'categoryName',
                        params: { categoryName: 'nachhilfe' } }
                    )"
                >
                    <img
                        :src="`${imageBase}/nachhilfe.jpg`"
                        alt="Nachhilfe"
                    >
                </NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup>
const SPACES_URL = useRuntimeConfig().public.spacesUrl
const imageBase = `${SPACES_URL}/images/homepage-slider`

const localePath = useLocalePath()
const heroImage = ref(1)
onMounted(() => {
    setInterval(() => {
        heroImage.value = heroImage.value % 4 + 1
    }, 4000)
})
</script>

<style scoped>
.slider {
    width: 18rem;
    height: 18rem;
    overflow: hidden;
}
.slides {
    width: 90rem;
    height: 18rem;
    display: flex;
}
.slides input {
    display: none;
}
.slide {
    width: 20%;
    transition: 1s;
}
.slide img {
    width: 18rem;
    height: 18rem;
    max-width: none;
}
#radio1:checked ~ .first {
    margin-left: 0;
}
#radio2:checked ~ .first {
    margin-left: -20%;
}
#radio3:checked ~ .first {
    margin-left: -40%;
}
#radio4:checked ~ .first {
    margin-left: -60%;
}
</style>
