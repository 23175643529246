<template>
    <section>
        <section class="hero">
            <div class="hero-container container">
                <div class="hero-content">
                    <h1>
                        Marktplatz für Hausbesuche<br>
                        und Lieferservices
                    </h1>
                    <SearchAddressAutocompleteInput @address-selected="router.push(localePath('search'))" />
                    <div class="address-links mt-1">
                        <NuxtLink
                            v-if="!isAuthenticated"
                            :to="localePath('login')"
                        >
                            Für gespeicherte Adressen einloggen
                        </NuxtLink>
                        <p
                            v-if="!address"
                            role="link"
                            @click="navigateTo(localePath('search'))"
                        >
                            Weiter ohne Adresse
                        </p>
                    </div>
                </div>
                <HomepageSlider />
            </div>
        </section>
        <!-- TODO: change once all categories are added -->
        <section
            v-if="locale === 'at'"
            class="container"
        >
            <div class="space-between">
                <h2 class="mb-0">
                    Finde mobile Dienstleister in der Nähe, die Hausbesuche anbieten
                </h2>
                <NuxtLink
                    role="button"
                    class="outline"
                    :to="localePath('homevisits')"
                >
                    Alle Hausbesuche
                </NuxtLink>
            </div>
            <CategoryFlexContainer
                class="mt-1"
                :categories="trendingCategories[locale]"
            />
        </section>
        <section class="container">
            <h2>{{ $myT('homepageCategoryHeading') }}</h2>
            <div class="services-container mt-2">
                <LazyServiceGridWrapper
                    v-if="pending"
                    :show-address-note="false"
                >
                    <template #content>
                        <ServiceSearchCardSkeletonLoader
                            v-for="n in 12"
                            :key="n"
                        />
                    </template>
                </LazyServiceGridWrapper>
                <div
                    v-else
                    class="services"
                >
                    <LazyServiceGridWrapper :show-address-note="false">
                        <template #content>
                            <AdsB2BCard />
                            <ServiceSearchCard
                                v-for="(service, index) in recommendations.services"
                                :key="'service-' + index"
                                :service="service"
                                :no-lazy-load="index < 2"
                            />
                        </template>
                    </LazyServiceGridWrapper>
                    <button
                        role="button"
                        class="button-link mt-1"
                        @click="navigateTo(localePath('search'))"
                    >
                        Mehr ansehen
                    </button>
                </div>
            </div>
        </section>
        <!-- TODO: change once all categories are added -->
        <section
            v-if="locale === 'at'"
            class="container"
        >
            <div class="space-between">
                <h2 class="mb-0">
                    Lieferservices in der Nähe aus zahlreichen Kategorien
                </h2>
                <NuxtLink
                    role="button"
                    class="outline"
                    :to="localePath('delivery')"
                >
                    Alle Lieferservices
                </NuxtLink>
            </div>
            <CategoryFlexContainer
                class="mt-1"
                :categories="deliverServiceCategories"
            />
        </section>
        <!-- TODO: change once coupons are supported -->
        <section
            v-if="locale === 'at'"
            class="container"
        >
            <div class="space-between">
                <h2 class="mb-0">
                    Aktuelle Gutscheine
                </h2>
                <NuxtLink
                    role="button"
                    class="outline"
                    :to="localePath('coupons')"
                >
                    Alle Gutscheine
                </NuxtLink>
            </div>
            <CouponsGridWrapper class="mt-1">
                <template #content>
                    <BusinessCouponCode
                        v-for="coupon in recommendations.coupons"
                        :key="'coupon' + coupon.id"
                        :coupon="coupon"
                    />
                </template>
            </CouponsGridWrapper>
        </section>
        <section class="container">
            <h2>{{ $myT('homepageLocationsHeading') }}</h2>
            <LinksToMainCities />
        </section>
        <section class="about container">
            <h2>{{ $myT('aboutHeading') }}</h2>
            <div class="about-container">
                <div class="about-description">
                    <h2>Finde Anbieter, die zu dir kommen!</h2>
                    <p>Du brauchst einen Dienstleister, der nicht zu weit weg ist und zu dir nach Hause kommen kann? Dann nutze unser Suchfeld und komme schnell zum Ziel oder vielmehr lass das Ziel zu dir kommen. So sparst du dir wertvolle Zeit, unterstützt lokale Dienstleister und musst nicht mal deinen aktuellen Standort verlassen.</p>
                </div>
                <div class="about-img">
                    <img
                        src="/assets/images/homepage/suchen.svg"
                        alt="suchen illustration"
                        loading="lazy"
                    >
                </div>
            </div>
            <div class="about-container">
                <div class="about-img">
                    <img
                        src="/assets/images/homepage/entdecken.svg"
                        alt="entdecken illustration"
                        loading="lazy"
                    >
                </div>
                <div class="about-description">
                    <h2>Entdecke Vielfalt</h2>
                    <p>Klicke dich durch unsere vielen verschiedenen Kategorien und lass dich inspirieren bzw begeistern. Egal ob im Bereich Handwerk, Mobile Nachhilfe, Lebensmittel-Lieferservice oder noch viele weitere. allekommen.at macht es möglich, den perfekten Dienstleister nach deinen Anforderungen und Wünschen zu finden und das ohne viel Aufwand.</p>
                </div>
            </div>
            <div class="about-container">
                <div class="about-description">
                    <h2>Teile deine Erfahrungen</h2>
                    <p>Du konntest durch allekommen.at einen mobilen Dienstleister für dein Anliegen finden? Berichte und Bewerte deine Erfahrung gleich auf unserer Seite und mache es so anderen Usern noch einfacher, sich für mobile Dienstleister und Lieferservice zu entscheiden.</p>
                </div>
                <div class="about-img">
                    <img
                        src="/assets/images/homepage/teilen.svg"
                        alt="teilen illustration"
                        loading="lazy"
                    >
                </div>
            </div>
            <NuxtLink
                v-show="!isAuthenticated"
                role="button"
                class="button-link"
                :to="localePath('register')"
            >
                Jetzt registrieren
            </NuxtLink>
        </section>
        <div
            id="newsletter"
            class="newsletter container"
        >
            <BoxWithSlot>
                <template #content>
                    <span id="newsletter-anmelden" />
                    <NewsletterForm />
                </template>
            </BoxWithSlot>
        </div>
        <div class="container mt-2">
            <div class="about-container vorteile">
                <div class="about-description">
                    <h2>
                        Mit einem User-Account mehr Funktionen nutzen:
                    </h2>
                    <ul>
                        <li><strong>Adressen speichern:</strong>  Speichere deine häufig genutzten Adressen, sei es deine Wohnadresse, Büroadresse, die Adresse von Verwandten oder deinem Ferienhaus. So hast du alle Adressen bequem im Blick und kannst sie schnell auswählen.</li>
                        <li><strong>Favoriten:</strong> Markiere deine Lieblings-Services mit einem roten Herz, um sie in deiner Merkliste zu speichern. Das erleichtert dir das Wiederfinden und Buchen von Services, die du regelmäßig nutzt oder einfach im Blick behalten möchtest.</li>
                        <li><strong>Bewertungen abgeben:</strong>  Teile deine Erfahrungen mit anderen Nutzern, indem du mobile Dienstleistungen und Lieferservices bewertest. Mit nur zwei Klicks kannst du eine Bewertung abgeben und anderen Nutzern helfen, qualifizierte Entscheidungen zu treffen.</li>
                    </ul>
                    <a
                        role="button"
                        class="button-link"
                        href="https://www.allekommen.at/registrieren"
                        target="_blank"
                    >Jetzt als User kostenfrei registrieren</a>
                </div>
                <div class="about-img">
                    <img
                        src="/assets/images/homepage/adressen-speichern.svg"
                        alt="Adressen speichern und schnell suchen"
                        loading="lazy"
                    >
                </div>
            </div>
            <div class="about-container vorteile mt-2">
                <div class="about-description">
                    <h2>
                        Vorteile für Partnerunternehmen: Erfolgreich über allekommen.at verkaufen.
                    </h2>
                    <p>
                        Sei dabei und präsentiere deine mobilen Dienstleistungen und Lieferservices auf allekommen.at:
                    </p>
                    <ul>
                        <li>Wir liefern <strong>wertvollen und qualifizierten Traffic auf deine Webseite:</strong> Mit allekommen.at erreichst du kaufbereite Nutzer, die mobile Dienstleistungen und lokale Lieferservices suchen.</li>
                        <li><strong>140+ gelistete Branchen:</strong> Mit über 140 gelisteten Branchen trägt allekommen.at zur Steigerung der Bekanntheit deiner Angebote bei. Potenzielle Kunden entdecken deine Dienstleistungen in ihrer Nähe und können diese direkt über die Plattform finden.</li>
                    </ul>
                    <a
                        role="button"
                        class="button-link"
                        href="https://partner.allekommen.at/"
                        target="_blank"
                    >Jetzt gratis Testphase starten</a>
                </div>
                <div class="about-img">
                    <img
                        src="/assets/images/homepage/merkliste.svg"
                        alt="Services zu Favoriten hinzufügen"
                        loading="lazy"
                    >
                </div>
            </div>
        </div>
        <FaqsList
            class="mt-2 container"
            faq-scope="homepage"
        />
    </section>
</template>

<script setup>
const router = useRouter()
const isAuthenticated = useIsAuthenticated()
const localePath = useLocalePath()

const searchStore = useSearchStore()
const { address, lat, lon } = storeToRefs(searchStore)
const { data: recommendations, pending } = await useIFetch('/search/recommendations', {
    query: { lat, lon }
})
if (import.meta.client && pending.value && recommendations.value === null) {
    console.error('useIFetch pending and data is null')
}
const servicesToWatchRef = computed(() => recommendations.value.services)
useImpressionTracking(servicesToWatchRef)

const config = useRuntimeConfig().public
const locale = config.locale
const SPACES_URL = config.spacesUrl
useSchemaOrg([
    defineProduct({
        name: 'Hausbesuche und Lieferservices in der Nähe',
        image: [`${SPACES_URL}/images/essen-bestellen-allekommen-at.jpg`],
        aggregateRating: {
            '@type': 'AggregateRating',
            'ratingValue': 4.6,
            'bestRating': 5,
            'ratingCount': 1617
        }
    })
])
useHead({
    title: 'Hausbesuche und Lieferservices in der Nähe',
    meta: [
        { name: 'description', content: 'Mobile Dienstleister für Hausbesuche und Lieferservices in deiner Nähe, die perfekt zu deinen Bedürfnissen passen' }
    ]
})
</script>

<style scoped>
section {
    margin-bottom: calc(3 * var(--spacing));
}
h1 {
    color: var(--white);
    font-weight: 600;
}
.hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hero {
    background-color: var(--dark-blue);
    width: 100%;
    padding-top: 1rem;
    margin-bottom: calc(2 * var(--spacing));
}
.hero h2 {
    color: var(--white);
    letter-spacing: 1px;
    font-weight: 500;
}
.address-links {
    display: flex;
    column-gap: var(--spacing);
    flex-wrap: wrap;
    row-gap: 1rem;
}
.address-links a, .address-links p[role="link"] {
    color: var(--white);
    text-decoration: underline;
    cursor: pointer;
}
.services {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: var(--spacing);
}
.about {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
    justify-content: center;
}
.about-container {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing) * 1.5);
    justify-content: center;
    align-items: center;
}
.about-description {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}
.about-description .button-link {
    width: fit-content;
}
.about-img {
    display: flex;
    justify-content: center;
    width: 75%;
}
.vorteile .about-img {
    width: 50%;
}
.about-container:nth-of-type(2) .about-img {
    order: 1;
}
.services-container {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing);
}
.newsletter {
    background-color: var(--white);
    position: relative;
}
#newsletter-anmelden {
  position: absolute;
  top: -7.5rem;
  left: 0;
}
@media (min-width: 425px) {
    h2 {
        font-size: 1.6rem;
    }
    .button-link {
        width: fit-content;
    }
    .about-img {
        width: 65%;
    }
}

@media (min-width: 768px) {
    .hero-container {
        flex-direction: row;
        justify-content: space-between;
    }
    .favourite-categories a {
        width: auto;
    }
    .about-container {
        flex-direction: row;
        flex-wrap: wrap;
        gap: calc(var(--spacing) * 3);
        justify-content: space-between;
    }
    .about-description, .about-img {
        width: calc(50% - var(--spacing) * 3) ;
    }
    .about-container.vorteile {
        gap: calc(var(--spacing) * 2);
    }
    .vorteile .about-description   {
        width: calc(70% - var(--spacing) * 3) ;
    }
    .vorteile .about-img   {
        width: calc(30% - var(--spacing) * 3) ;
    }
    .about-container:nth-of-type(2) .about-img {
        order: 0;
    }
    .about-img img {
        width: 80%;
    }
    .about-description {
        align-self: center;
    }
}

@media only screen and (min-width: 1024px) {
    h1 { font-size: 1.9rem; }
    .hero-container {
        display: flex;
        flex-direction: row;
        column-gap: var(--spacing);
        justify-content: space-around;
        align-items: center;
    }
    .hero-content { min-width: 35rem; }
    .about-img img {
        width: 60%;
    }
}

@media only screen and (min-width: 1200px) {
    .about-img img {
        width: 50%;
    }
}
@media (min-width: 1440px) {
    .hero-img img {
        width: 22rem;
    }
}
</style>
