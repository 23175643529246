<template>
    <section>
        <section class="head">
            <div class="head-title">
                <h1>Wie können wir dir helfen?</h1>
                <p>
                    Unser Kundensupport-Team ist immer für Dich da, um Dir bei Fragen oder Problemen zu helfen.
                    Wir glauben, dass jeder Kunde einzigartig ist und wir tun unser Bestes, um sicherzustellen,
                    dass Du die bestmögliche Erfahrung mit uns hast.
                </p>
            </div>
        </section>

        <section class="support-contact">
            <h2>
                Kontaktmöglichkeiten
            </h2>
            <div class="contact-cards">
                <div class="contact-card white">
                    <h2>Schreib uns</h2>
                    <p>
                        Um Dir schnell und effektiv helfen zu können, bitten wir Dich, das folgende Formular
                        auszufüllen.
                    </p>
                    <NuxtLink role="button" :to="localePath('customer-support-form')">
                        Zum Formular
                    </NuxtLink>
                </div>
            </div>
        </section>

        <BusinessLinkBanner />
        <a class="centered mt-2 block-link" href="https://partner.allekommen.at/kostenfreie-erstberatung" rel="nofollow">
            Hier geht's zum Business Support
        </a>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
useHead({
    title: 'Kundensupport'
})
</script>

<style scoped>
p { margin-bottom: var(--spacing); }
.white {
    background-color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    padding: var(--spacing);
}
section {
    padding: var(--spacing);
    margin-bottom: calc(1.5 * var(--spacing));
}
.head p { margin-bottom: 0; }
.reasons-list p {
    display: flex;
    align-items: flex-start;
    column-gap: 0.5rem;
    margin-bottom: var(--spacing);
}
.support-contact { padding: 0; }
.contact-cards {
    display: flex;
    row-gap: calc(2 * var(--spacing));
    flex-direction: column;
}
@media (min-width: 1024px) {
    .head {
        border-radius: var(--border-radius);
        padding: calc(2 * var(--spacing));
        background-image: url('assets/images/support-image.jpg');
        background-position: 70% 50%;
        background-size: cover;
    }
    .head .head-title {
        width: 45%;
    }
    .contact-cards {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        column-gap: calc(2 * var(--spacing));
    }
    .contact-cards .contact-card {
        width: 45%;
    }
}
</style>
