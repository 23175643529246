<template>
    <nav aria-label="Breadcrumb" class="breadcrumb">
        <ul>
            <li class="back">
                <NavigationGoBack />
            </li>
            <li>
                <NuxtLink :to="localePath('index')" exact>
                    <img
                        class="home-icon"
                        src="assets/icons/home-solid.svg"
                        alt="Zur Startseite"
                        title="Zur Startseite"
                        loading="lazy"
                    >
                </NuxtLink>
            </li>
            <li v-for="(item, index) in breadcrumbs" :key="index">
                <img
                    class="right-icon"
                    src="assets/icons/arrows/chevron-right-solid.svg"
                    alt="Back Icon"
                    loading="lazy"
                >
                <NuxtLink v-if="item.item" :to="item.item">
                    {{ item.name }}
                </NuxtLink>
                <span v-else aria-current="page">{{ item.name }}</span>
            </li>
        </ul>
    </nav>
</template>

<script setup>
/**
 * A breadcrumb navigation component that displays a list of links or plain text items.
 *
 * @prop {Array} breadcrumbs - An array of objects representing the breadcrumb items.
 * Each object should have a `name` property which is the text to be displayed and
 * an optional `item` property which is the path to navigate to when the item is clicked.
 *
 * @example
 * // Displaying a simple breadcrumb with two items
 * <template>
 *   <Breadcrumb :breadcrumbs="[{ name: 'Home', item: '/' }, { name: 'About' }]" />
 * </template>
 */
const localePath = useLocalePath()
defineProps({
    breadcrumbs: { type: Array, required: true }
})
</script>

<style scoped>
nav {
    background-color: unset;
    padding: 0.5rem 0;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 1rem;
    margin-top: -1rem;
}
nav li { padding: 0; }
.breadcrumb ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}
span {
    padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
}
.back { padding-right: 0.5rem; }
.right-icon { height: 0.6rem; }
.home-icon {
    height: 1rem;
    margin-bottom: 0.2rem;
}
nav :where(a, [role=link]):first-child {
    padding-left: 0!important;
}
</style>
