<template>
    <section class="main-header">
        <div class="nav-container container">
            <nav>
                <div class="logo-with-address">
                    <AllekommenLogo class="logo" />
                    <LazySearchAddressMenuToggle v-if="!$device.isMobile && !onHomepage" class="top-address-toggle" />
                </div>
                <SearchFieldInput class="search-input" />
                <ul class="account-management">
                    <li class="favourites-container">
                        <NuxtLink class="favorites-link" :to="localePath('profile-favorites')">
                            <img src="/icons/hearts/heart-solid-white.svg" alt="Heart Icon" loading="lazy" class="nav-icon-container">
                            <span class="text">Favoriten</span>
                            <span v-if="favoritesCount !== '0'" class="count">{{ favoritesCount }}</span>
                        </NuxtLink>
                    </li>
                    <li>
                        <NuxtLink v-if="!isAuthenticated" :to="localePath('login')">
                            <img src="/icons/user-solid.svg" alt="Account Icon" loading="lazy" class="nav-icon-container">
                            <span>Anmelden</span>
                        </NuxtLink>
                        <NuxtLink v-else :to="localePath('profile')">
                            <img src="/icons/user-solid.svg" alt="Account Icon" loading="lazy" class="nav-icon-container">
                            <span>Profil</span>
                        </NuxtLink>
                    </li>
                </ul>
            </nav>
            <NavigationTopCategories />
            <LazySearchAddressMenuToggle v-if="$device.isMobile" class="bottom-address-toggle" />
        </div>
    </section>
</template>

<script setup>
const localePath = useLocalePath()

const { status } = useAuthState()
const isAuthenticated = computed(() => status.value === 'authenticated')

const favoritesStore = useFavoritesStore()
const { totalLocalFavorites, totalFavorites } = storeToRefs(favoritesStore)

const onHomepage = computed(() => useRoute().path === '/')

const favoritesCount = computed(() => {
    const count = isAuthenticated.value ? totalFavorites.value : totalLocalFavorites.value
    return count > 20 ? '20+' : count.toString()
})
</script>

<style scoped>
.top-address-toggle { display: none; }
.logo-with-address { display: flex; column-gap: var(--spacing); }
.main-header {
    background-color: var(--dark-blue);
    position: sticky;
    top: -3rem;
    z-index: 11;
}
.nav-container {
    padding: 0.5rem var(--spacing);
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}
nav {
    width: 100%;
    min-height: 3.1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0.5rem;
    align-items: center;
}
nav > ul {
    flex-shrink: 0;
    margin: 0;
    gap: 1rem;
}
nav ul:last-of-type {
    margin-right: 0;
}
nav a {
    color: var(--primary-inverse);
}
nav li {
    padding: 0;
}
.logo {
    padding: 0;
    display: flex;
    flex-shrink: 0;
    align-items: center;
}
.account-management {
    justify-content: flex-end;
    grid-row: 1;
    grid-column: 2;
    color: white;
}
.account-management li {
    margin: 0;
}
.account-management a {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}
.favourites-container .text {
    display: none;
}
.search-input {
    grid-column: span 2;
}
.favorites-link { position: relative; }
.count {
    position: absolute;
    width: 1.7rem;
    height: 1.7rem;
    top: -0.5rem;
    right: -0.8rem;
    font-size: 0.7rem;
    font-weight: bold;
    color: var(--dark-blue);
    background-color: var(--yellow);
    padding: 1px 3px;
    border-radius: 50%;
    text-align: center;
    line-height: 1.6rem;
}
@media (min-width: 768px) {
    .favourites-container .text {display: block;}
    .top-address-toggle { display: flex; }
    .bottom-address-toggle { display: none; }
    .count { position: initial; margin-left: 0.3rem; }
}
@media (min-width: 1024px) {
    .main-header { top: 0; }
}
@media (min-width: 992px) {
    nav {
        display: flex;
        gap: calc(var(--spacing) * 2);
    }
}
@media (min-width: 1200px) {
    nav {
        gap: calc(var(--spacing) * 3);
    }
}
</style>
