<template>
    <div class="horizontal-ad">
        <a
            v-if="isUnfilled"
            rel="sponsored"
            :href="bannerData.href"
        >
            <img
                :src="bannerData.src"
                border="0"
            >
        </a>
        <Adsbygoogle
            ref="horizontalAd"
            class="google-ads"
            :ad-slot="adSlot"
            :ad-full-width-responsive="true"
        />
    </div>
</template>

<script setup>
const { adSlot } = defineProps({
    adSlot: { type: String, required: true }
})
const horizontalAd = ref(null)
const isUnfilled = computed(() => horizontalAd.value?.isUnfilled)

const { isMobile } = useDevice()
const bannerData = computed(() => {
    return isMobile
        ? {
            href: 'https://www.awin1.com/cread.php?s=3252352&v=10432&q=448834&r=1256987',
            src: 'https://www.awin1.com/cshow.php?s=3252352&v=10432&q=448834&r=1256987'
        }
        : {
            href: 'https://www.awin1.com/cread.php?s=3252361&v=10432&q=448834&r=1256987',
            src: 'https://www.awin1.com/cshow.php?s=3252361&v=10432&q=448834&r=1256987'
        }
})
onMounted(() => {
    console.log('horizontal isUnfilled', isUnfilled.value)
})
</script>

<style scoped>
.horizontal-ad {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    /* background-color: #e5e5e5;
    border-radius: var(--border-radius); */
}
.google-ads {
    width: 100%;
    min-height: 90px;
}
</style>
