import at from '~/locales/at.json'
import de from '~/locales/de.json'

export default defineNuxtPlugin(() => {
    const translations = { at, de }
    const locale = useRuntimeConfig().public.locale
    inject('myT', key => translations[locale][key] || key)
    return {
        provide: {
            myT: key => translations[locale][key] || key
        }
    }
})
