<template>
    <div
        role="link"
        class="back"
        @click="$router.go(-1)"
    >
        <img
            src="assets/icons/arrows/chevron-left-solid.svg"
            alt="Zurück navigieren"
            title="Zurück navigieren"
            loading="lazy"
        >
        Zurück
    </div>
</template>

<style scoped>
.back {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    cursor: pointer;
    column-gap: 0.3rem;
    width: fit-content;
}
img { height: 0.6rem; }
</style>
