import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    setup (nuxtApp) {
        Sentry.init({
            app: nuxtApp.vueApp,
            dsn: 'https://9c0ee1981cc87567857d10fdced0dc7f@o4506899101188096.ingest.us.sentry.io/4506899601031168',
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false
                })
            ],
            ignoreErrors: ['AbortError: Share canceled', 'NotAllowedError', 'getTransitionRawChildren', 'TagError'],
            // Performance Monitoring
            tracesSampleRate: 0.1, //  Capture 60% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
            // Session Replay
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        })
    }
})
