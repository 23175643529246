export const GOOGLE_MAPS_PUBLIC_API_KEY = 'AIzaSyDBoE_dxtFkg5wSmWt7SEc8eYCm0YFYJ3c'

export const ALLEKOMMEN_ROOT = 'https://www.allekommen.at'

// U+2010 unicode representation, not the regular hyphen, as it is used in spaces separation for URls
export const HYPHEN = '‐'

export const WEEKDAYS_MAPPING = {
    0: 'Montag',
    1: 'Dienstag',
    2: 'Mittwoch',
    3: 'Donnerstag',
    4: 'Freitag',
    5: 'Samstag',
    6: 'Sonntag'
}
export const SEARCH_SUGGESTIONS_DEBOUNCE = 800
export const CUSTOM_FETCH_ERROR_CODE = 412

export const CLICKOUT_EVENT_NAME = 'clickout'
export const TABOOLA_CLICK_ID = 'taboolaclickid'
export const TABOOLA_BASE_URL = 'https://trc.taboola.com/actions-handler/log/3/s2s-action'

export const FILLED_HEART_ICON = '/icons/hearts/heart-solid-red.svg'
export const EMPTY_HEART_ICON = '/icons/hearts/heart.svg'
