<template>
    <div>
        <form>
            <label for="street-name">
                <input
                    id="street-name"
                    v-model="addressInput"
                    placeholder="Gib hier deine Adresse ein"
                    type="text"
                >
            </label>
            <button
                :aria-busy="loading"
                :disabled="addressInput.length < 2 || !allowSubmit"
                type="button"
                @click="searchAddress"
            >
                Adresse suchen
            </button>
        </form>

        <div v-if="searchResults.length > 0" class="address-list-container">
            <div v-for="(item, index) in searchResults" :key="index">
                <label>
                    <input type="radio" name="address" @click="onItemSelected(item)">
                    {{ item.displayName }}
                </label>
            </div>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['address-selected'])

defineProps({
    allowSubmit: { type: Boolean, required: true }
})

const addressInput = ref('')
const searchResults = ref([]) // An array to store search results for addresses.
const loading = ref(false)
const toast = useToast()

/**
 * Perform a search for an address using the provided input fields.
 * This method sends a request to a geocoding API and populates the searchResults with matching addresses.
 */
async function searchAddress () {
    loading.value = true
    const locations = await $fetch('/api/address-search/geocoding', {
        query: { address: addressInput.value }
    })
    if (!locations) { return toast.error('Die Adresse konnte nicht gefunden werden') }
    searchResults.value = locations // populate list with search results
    loading.value = false
}
/**
 * Handle the selection of an item from the search results.
 * Emits an "address-selected" event with the selected address.
 * @param {Object} item - The selected address item.
 */
function onItemSelected (item) {
    emit('address-selected', item)
}
</script>

<style scoped>
.two-columns button { margin-bottom: 0 }
.address-list-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
    padding: 0.5em 1em;
    height: auto;
}
</style>
