<template>
    <div>
        <h3 class="flex-row">
            Service-Zeiten
            <span v-if="isServiceOpen" class="green">Jetzt offen</span>
            <span v-else class="red">Jetzt geschlossen</span>
        </h3>
        <div class="hours-container">
            <div v-for="(entry, index) in hours" :key="'hours-' + index" class="hour-box">
                <span class="day">{{ dayOfWeekStringShort(entry.dayOfWeek) }}</span>
                <span v-if="entry.openingTime && entry.closingTime" class="time">
                    {{ entry.openingTime }} - {{ entry.closingTime }}
                </span>
                <span v-if="entry.openingTime2 && entry.closingTime2" class="time">
                    {{ entry.openingTime2 }} - {{ entry.closingTime2 }}
                </span>
                <span v-if="entry.uponRequest" class="time">Auf Anfrage</span>
                <span v-if="entry.closed" class="time">Geschlossen</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { WEEKDAYS_MAPPING } from '~/constants'

const props = defineProps({
    /** An array of opening hours for each day of the week. */
    hours: { type: Array, required: true }
})

const allHours = props.hours

const isServiceOpen = computed(() => {
    const now = new Date()
    const currentDayOfWeek = now.getDay() // 0 for Sunday, 1 for Monday, etc.
    const todayOpeningHours = props.hours.find(entry => entry.dayOfWeek === currentDayOfWeek)
    if (!todayOpeningHours || todayOpeningHours.closed) {
        return false // Service is closed on the current day
    }
    if (todayOpeningHours.uponRequest) { return true }

    // Convert current time to minutes since midnight
    const currentTime = now.getHours() * 60 + now.getMinutes()
    const openingTime = timeToMinutes(todayOpeningHours.openingTime)
    const closingTime = timeToMinutes(todayOpeningHours.closingTime)
    // Check if current time is within the first pair of opening hours
    const openInFirstPart = isTimeWithinRange(currentTime, openingTime, closingTime)

    if (!openInFirstPart && todayOpeningHours.openingTime2 && todayOpeningHours.closingTime2) {
        const openingTime2 = timeToMinutes(todayOpeningHours.openingTime2)
        const closingTime2 = timeToMinutes(todayOpeningHours.closingTime2)
        // Check if current time is within second pair of opening hours
        return isTimeWithinRange(currentTime, openingTime2, closingTime2)
    }
    return openInFirstPart
})

onMounted(() => {
    const daysOpen = new Set(props.hours.map(entry => entry.dayOfWeek))
    if (daysOpen.size !== 7) {
        for (let d = 0; d < 7; d++) {
            if (!daysOpen.has(d)) {
                allHours.push({ dayOfWeek: d, closed: true })
            }
        }
    }
    // make sure the days are sorted to be Mo, Tue, Wed, Thu, Fri, Sat, Sun
    allHours.sort((a, b) => {
        return a.dayOfWeek - b.dayOfWeek
    })
})

/**
 * Converts the day index to a short string representation (e.g., "Mo" for Monday).
 * @param {number} dayIndex - The index of the day (0 for Sunday, 1 for Monday, etc.).
 * @returns {string} - The short string representation of the day.
 */
function dayOfWeekStringShort (dayIndex) {
    return WEEKDAYS_MAPPING[dayIndex].slice(0, 2)
}
function timeToMinutes (timeString) {
    const [hours, minutes] = timeString.split(':').map(Number)
    return hours * 60 + minutes // Convert time to minutes since midnight
}

function isTimeWithinRange (currentTime, openingTime, closingTime) {
    // If closing time is after midnight (e.g., 23:00 to 02:00)
    if (closingTime < openingTime) {
        return currentTime >= openingTime || currentTime <= closingTime
    }
    return currentTime >= openingTime && currentTime <= closingTime
}
</script>

<style scoped>
p { margin-bottom: 0; }
h3 {
    margin-bottom: 0.5rem;
    font-size: 1rem;
}
.flex-row {
    display: flex;
    column-gap: var(--spacing);
    align-items: center;
    justify-content: flex-start;
}
.hours-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.day {
    font-size: 1.3rem;
}
.green { color: var(--success);}
.red { color: var(--danger); }
.hour-box {
    width: 5rem;
    min-height: 3rem;
    border-radius: var(--border-radius);
    display: grid;
    place-items: center;
    border: 1px solid var(--neutral-200);
    padding: 0 0.1rem 0 0.1rem;
}
.hour-box .time {
    font-size: 0.7rem;
    text-align: center;
}
</style>
