<template>
    <section class="wrapper">
        <LoginImage />
        <section class="ptb-1 form">
            <NavigationGoBack />
            <h1 class="mt-1">
                Passwort zurücksetzen
            </h1>
            <p>
                Bitte gib Deine E-Mail an, mit der Du Dich registriert hast. Wir senden Dir einen
                Link zur Wiederherstellung Deines Passworts.
            </p>

            <form
                v-if="!data.submitted"
                method="post"
                @submit.prevent="sendPasswordResetLink"
            >
                <input
                    id="email"
                    v-model="data.email"
                    type="email"
                    name="email"
                    placeholder="E-Mail Adresse eingeben"
                    required
                    maxlength="255"
                >
                <button :aria-busy="data.submitting">
                    Abschicken
                </button>
            </form>

            <img v-else src="/assets/icons/circle-check-solid.svg" alt="Success Icon" loading="lazy">
        </section>
    </section>
</template>

<script setup>
const { $backendApi } = useNuxtApp()
const toast = useToast()

definePageMeta({
    auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: '/'
    }
})

const data = reactive({
    email: '',
    submitting: false,
    submitted: false
})

useHead({
    title: 'Passwort zurücksetzen',
    meta: [{ name: 'robots', content: 'noindex' }]
})

async function sendPasswordResetLink () {
    data.submitting = true
    try {
        await $backendApi('auth/password-reset/resend-link', {
            method: 'POST',
            body: { email: data.email, userType: 'user' }
        })
        toast.success('Anfrage erfolgreich.')
        data.submitted = true
    } catch (error) {
        handleBackendError({ error, toast })
    }
    data.submitting = false
}
</script>

<style scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
img {
    width: 5em;
    height: 5em;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.form {
    border-radius: var(--border-radius);
    background-color: var(--white);
    width: 21rem;
    padding: 1.5rem;
    margin-bottom: 0;
    box-shadow: var(--shadow);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media (min-width: 768px) {
    .wrapper {
        flex-direction: row;
        padding: 0;
    }
    .form {
        width: 22rem;
        min-height: 31rem;
    }
}
</style>
