<template>
    <section>
        <h1>Die besten Gutscheine in der Nähe: Finde Gutscheine aller Anbieter hier - täglich neu!</h1>
        <div class="space-between">
            <p class="mb-0">
                Auf der Suche nach attraktiven Gutscheinen in deiner Nähe? Entdecke eine Vielzahl von Angeboten für Lieferservices und Dienstleistungen, die direkt zu dir kommen.
            </p>
            <ShareButton @share="toggleShare" />
        </div>

        <div class="search-container mt-2">
            <SearchBar v-model="searchTerm" placeholder="Suche über alle Gutscheine" />
            <CouponsSortBox v-model="sort" />
        </div>
        <p v-if="error" class="centered mt-2">
            Gutscheine können nicht angezeigt werden 😔
        </p>
        <CouponsGridWrapper v-else class="mt-2">
            <template #content>
                <BusinessCouponCode
                    v-for="coupon in data.coupons"
                    :key="'coupon' + coupon.id"
                    :coupon="coupon"
                />
            </template>
        </CouponsGridWrapper>
        <SearchPaginationLinks
            v-if="data.total > 20"
            class="mt-2"
            :total="data.total"
            :page="query.page || 1"
            :per-page="20"
        />
        <AdsHorizontalAd ad-slot="8606490315" class="mt-2" />
    </section>
</template>

<script setup>
const route = useRoute()

const searchTerm = ref('')
const sort = ref('asc')

const query = computed(() => {
    return {
        page: Number(route.query._page) || 1,
        searchTerm: searchTerm.value,
        sortBy: 'expires_at',
        direction: sort.value
    }
})

const metaTitle = 'Die besten Gutscheine in der Nähe'
const metaDescription = 'Finde attraktive Gutscheine in der Nähe. Große Auswahl an Angeboten & Deals für Anbieter, die direkt zu dir kommen.'
function toggleShare () {
    handleShare(
        metaDescription,
        window.location,
        metaTitle
    )
}

const { data, error } = await useIFetch('/coupons/', { query })

// TODO: add og image
useHead({
    title: metaTitle,
    meta: [{ name: 'description', content: metaDescription }]
})
</script>

<style scoped>
.space-between { flex-wrap: wrap; row-gap: var(--spacing); }
.search-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
@media (min-width: 768px) {
    .search-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
</style>
