<template>
    <section>
        <h2>Bewertungen</h2>
        <RatingStarsWithCount
            class="review-summary"
            :average-rating="service.rating"
            :total-reviews="service.totalReviews"
        />
        <div v-show="isAuthenticated" class="mt-2">
            <p>Klicke auf die Sterne, um zu bewerten.</p>
            <SearchFilterSelectableStars v-model="review.stars" />
            <textarea
                v-model="review.message"
                class="mt-1"
                placeholder="Gib hier Dein Feedback ein"
            />
            <button :aria-busy="submitting" :disabled="!allowSubmit" @click="createReview">
                Absenden
            </button>
        </div>
        <p v-show="!isAuthenticated" class="mt-1">
            Bitte logge dich ein, um eine Bewertung abzugeben.
            <NuxtLink :to="localePath('login')">
                Zum Login
            </NuxtLink>
        </p>
        <ServiceUserReview
            v-for="(r, index) in service.reviews"
            :key="'user-review-' + index"
            class="mt-1"
            :review="r"
        />
    </section>
</template>

<script setup>
const props = defineProps({
    service: { type: Object, required: true }
})
const emit = defineEmits(['new-review'])

const localePath = useLocalePath()
const toast = useToast()
const isAuthenticated = useIsAuthenticated()
const { $backendApi } = useNuxtApp()

const submitting = ref(false)
const review = ref({ message: '', stars: null })

/** Determines whether the review submission is allowed based on text and star rating. */
const allowSubmit = computed(() => {
    return review.value.message.trim() !== '' && review.value.stars !== null
})

async function createReview () {
    if (!allowSubmit.value) {
        return toast.info('Bitte alle Felder ausfüllen.')
    }
    submitting.value = true
    review.value.message = review.value.message.trim()
    try {
        const data = await $backendApi('/reviews/', {
            method: 'POST',
            body: { ...review.value, serviceId: props.service.id }
        })
        review.value.message = ''
        review.value.stars = null
        emit('new-review', data)
        toast.success('Danke für deine Bewertung!')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    submitting.value = false
}
</script>

<style scoped>
.review-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    flex-wrap: wrap;
}
.review-summary .rating {
    font-size: 1.5rem;
}
.review-summary img {
    height: 2rem;
    width: 2rem;
}
textarea {
    width: 100%;
    padding: var(--spacing);
    margin-top: 0.5rem;
    resize: vertical;
    min-height: 8rem;
}
.star { width: 2.5rem; }
.review-summary-card h2, .review-summary-card p { margin: 0; }
</style>
