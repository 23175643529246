<template>
    <div class="services-wrapper" :class="{ 'services-wrapper-small': props.servicesWrapperSmall }">
        <LazyNoteBox v-if="address === null && props.showAddressNote" class="no-address-note">
            <template #text>
                Gib deinen Standort an, dann zeigen wir dir, welche Anbieter
                <img class="inline-icon" src="/assets/images/allekommen-icon.svg" alt="Allekommen Icon">
                <strong class="primary-text">direkt zu dir kommen</strong>!
            </template>
            <template #button>
                <button class="yellow" @click="searchStore.setShowAddressModal(true)">
                    Standort angeben
                </button>
            </template>
        </LazyNoteBox>
        <slot name="loginInviteCard" />
        <slot name="content" />
    </div>
</template>

<script setup>
const props = defineProps({
    showAddressNote: { type: Boolean, default: true },
    servicesWrapperSmall: { type: Boolean, default: false }
})

const searchStore = useSearchStore()
const address = searchStore.address
</script>

<style scoped>
.inline-icon {
    height: 1.5rem;
}
.primary-text {
    color: var(--primary);
    margin-left: 0.3rem;
}
.services-wrapper {
    width: 100%;
    display: grid;
    column-gap: var(--spacing);
    row-gap: var(--spacing);
}
@media (min-width: 360px) {
    .no-address-note {
        grid-column: 1 / -1;
    }
    .services-wrapper {
        grid-template-columns: repeat(2, 1fr);
        margin: 0;
    }
}
@media (min-width: 768px) {
    .services-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 992px) {
    .services-wrapper {
        grid-template-columns: repeat(4, 1fr);
    }
    .services-wrapper-small {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 1200px) {
    .services-wrapper-small {
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>
