<template>
    <LoginPage email-button-text="Mit E-Mail registrieren" form-title="Profil erstellen">
        <template #form>
            <FormRegistrationWithEmail />
        </template>
        <template #bottomLink>
            Du bist schon dabei?
            <NuxtLink :to="localePath('login')">
                Anmelden
            </NuxtLink>
        </template>
    </LoginPage>
</template>

<script setup>
definePageMeta({
    auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: '/'
    }
})

const localePath = useLocalePath()

useHead({
    title: 'Registrieren'
})
</script>

<style scoped>
.container { display: grid; place-items: center }
.head {
    padding: 2rem 0;
}
.grid-center {
    display: grid;
    place-items: center;
}
.registration-form {
    display: flex;
    flex-direction: column-reverse;
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    overflow: hidden;
}
.form {
    width: 20rem;
    padding: 1.5rem;
    background-color: var(--white);
}
h1 {
    margin-bottom: 0;
    text-align: center;
}
span {
    display: block;
    cursor: pointer;
}
.newsletter {
    font-size: 0.8rem;
    margin-bottom: var(--spacing);
}
@media (min-width: 425px) {
    .form { width: 22rem; }
}
@media (min-width: 768px) {
    .registration-form {
        flex-direction: row;
    }
    .form {
        width: 22rem;
    }
}
@media (min-width: 1024px) {
    .form { width: 30rem; }
    .registration-form {
        height: 29rem;
    }
}
</style>
