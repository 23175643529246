<template>
    <p v-if="!expired">
        Gilt noch: <span>{{ timeLeft }}</span>
    </p>
    <p v-else>
        <span>Abgelaufen</span>
    </p>
</template>

<script setup>
const { expires } = defineProps({ expires: { type: String, default: '' } })

const expired = ref(false)

const displayDays = ref(0)
const displayHours = ref(0)
const displayMinutes = ref(0)
const displaySeconds = ref(0)

const seconds = computed(() => 1000)
const minutes = computed(() => seconds.value * 60)
const hours = computed(() => minutes.value * 60)
const days = computed(() => hours.value * 24)

const timeLeft = computed(() => {
    const timeString = `${displayHours.value}:${displayMinutes.value}:${displaySeconds.value}`
    return displayDays.value > 0
        ? `${displayDays.value} Tage, ${timeString}`
        : timeString
})

onMounted(() => {
    showRemaining()
})

function showRemaining () {
    const timer = setInterval(() => {
        const now = new Date()
        const end = new Date(expires)
        const distance = end.getTime() - now.getTime()

        if (distance <= 0) {
            clearInterval(timer)
            expired.value = true
            return
        }

        displayDays.value = Math.floor(distance / days.value)
        const remainingHours = Math.floor((distance % days.value) / hours.value)
        const remainingMinutes = Math.floor((distance % hours.value) / minutes.value)
        const remainingSeconds = Math.floor((distance % minutes.value) / seconds.value)

        displayMinutes.value = remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes
        displaySeconds.value = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds
        displayHours.value = remainingHours < 10 ? '0' + remainingHours : remainingHours
    }, 1000)
}
</script>

<style scoped>
p span {
    color: var(--red);
    font-weight: bold;
}
</style>
