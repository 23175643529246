<template>
    <div class="quick-view" :class="{show: show}">
        <CloseIcon @close="emit('close')" />
        <ServiceComingToYouIndicator :comes-to-you="Boolean(service.comesToYou)" />
        <strong>
            {{ service.name }}
            <ServiceSustainabilityBadge v-if="service.business.sustainabilityDescription" />
        </strong>
        <RatingStarsWithCount
            v-if="service.totalReviews > 0"
            class="mt-05"
            :total-reviews="service.totalReviews"
            :average-rating="service.rating"
        />
        <p v-if="service.minPrice">
            <strong>ab € {{ service.minPrice.toFixed(2) }}</strong>
        </p>
        <p class="mt-1">
            Kategorie:
            <NuxtLink
                :to="localePath({
                    name: 'categoryName',
                    params: { categoryName: service.category.permanentName }
                })"
            >
                {{ service.category.name }}
            </NuxtLink>
        </p>
        <ServiceContactInfo
            class="contact-container"
            :service="service"
            :card-image="image"
        />
        <p class="mt-1">
            <b>Beschreibung</b>
        </p>
        <div v-html="service.info" />
        <strong>Anbieter:</strong> <a :href="providerPageLink">
            {{ business.name }}
        </a>
        <a :href="servicePageLink" role="button" class="button-link outline mt-1">
            Zur Detailseite
        </a>
    </div>
</template>

<script setup>
const props = defineProps({
    service: { type: Object, required: true },
    image: { type: String, required: true },
    servicePageLink: { type: String, required: true },
    show: { type: Boolean, default: false }
})
const emit = defineEmits(['close'])
const localePath = useLocalePath()
const business = props.service.business
const providerPageLink = localePath({
    name: 'provider-name',
    params: { name: `${business.permanentName}-${business.id}` }
})
</script>

<style scoped>
p { margin-bottom: 0.5rem; }
.quick-view {
    transform: translateY(150%);
    transition: transform 0.4s ease;
    padding: calc(var(--spacing) * 2);
    background-color: var(--white);
    width: 100%;
    height: 100%;
    max-height: calc(100% - 2rem);
    position: fixed;
    top: 2rem;
    left: 0;
    z-index: 11;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    overflow-y: auto;
}
.show { transform: translateY(0); }
.button-link { display: block; }
@media (min-width: 1024px) {
    .quick-view {
        transform: translateY(0) translateX(-100%);
        width: 60%;
        top: 0;
        border-top-left-radius: 0;
        max-height: 100%;
    }
    .show { transform: translateX(0%); }
}
@media (min-width: 1440px) {
    .quick-view {
        width: 40rem;
    }
}
</style>
