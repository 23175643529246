<template>
    <form v-if="!submitted" class="info-card" @submit.prevent="sendRequest">
        <div class="grid">
            <label for="email">
                E-Mail, an die geantwortet werden soll *
                <input v-model="requestData.responseEmail" type="email" name="email" placeholder="Deine E-Mail" required>
            </label>
            <label for="phone">
                Telefonnummer für einen Rückruf <span v-if="!requestData.callWanted">(optional)</span>
                <input
                    v-model="requestData.responsePhone"
                    type="tel"
                    :aria-invalid="requestData.callWanted && !requestData.responsePhone ? 'true' : ''"
                    name="phone"
                    placeholder="Deine Telefonnummer"
                >
            </label>
        </div>
        <label for="message">
            Deine Nachricht *
            <textarea v-model="requestData.message" name="message" minlength="30" placeholder="Beschreibe dein Anliegen oder Anfrage" />
        </label>
        <label for="call">
            <input id="call" v-model="requestData.callWanted" type="checkbox" name="call">
            Ich wünsche einen Rückruf
        </label>
        <label for="contact-data">
            <input id="contact-data" v-model="correctData" type="checkbox" name="contact-data" required>
            Ich habe meine korrekten Kontaktdaten angegeben *
        </label>
        <button :disabled="disableSubmit" :aria-busy="loading">
            Absenden
        </button>
    </form>
    <div v-else class="info-card success-message">
        <div class="flex-center">
            <img src="/assets/icons/circle-check-solid.svg" alt="Erfolgreich gesendet" class="check">
            <img src="/assets/images/new-message.png" alt="Nachricht erfolgreich gesendet">
        </div>
        <p class="centered mt-1">
            <b>Dein Anfrage wurde verschickt! Der Anbieter wird sich bei dir melden!</b>
        </p>
    </div>
</template>

<script setup>
const { businessId } = defineProps({
    businessId: { type: Number, required: true }
})

const correctData = ref(false)
const { data } = useAuthState()
const requestData = ref({
    responseEmail: data.value?.email || '',
    message: '',
    responsePhone: '',
    callWanted: false,
    submissionUrl: useRoute().path
})

const submitted = ref(false)
const disableSubmit = computed(() => !correctData.value || (requestData.value.callWanted && !requestData.value.responsePhone))

const loading = ref(false)
const toast = useToast()
const { $backendApi } = useNuxtApp()
async function sendRequest () {
    loading.value = true
    try {
        await $backendApi(`/notifications/${businessId}/contact-provider`, {
            method: 'POST', body: requestData.value
        })
        submitted.value = true
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>

<style scoped>
label { cursor: pointer; }
textarea, input { margin-bottom: 0; }
.check {
    width: 5rem;
    height: 5rem;
}
</style>
