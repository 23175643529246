<template>
    <section class="page">
        <NavigationGoBack />
        <h1 class="mt-1">
            Konto löschen
        </h1>
        <p>
            Wenn du dein Konto löschen möchtest, melde dich bitte bei uns über diese Form.
            Hinterlasse uns eine kurze Nachricht, dass wir das Löschen für dich übernehmen.
        </p>
        <FormHubspotContact />
    </section>
</template>

<script setup>
useHead({
    title: 'Konto löschen',
    meta: [{ name: 'robots', content: 'noindex' }]
})
</script>
