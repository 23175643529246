<template>
    <div class="skeleton-item">
        <div class="skeleton-image" />
        <div class="skeleton-info">
            <div class="skeleton-title" />
            <div class="skeleton-metadata" />
            <div class="skeleton-metadata" />
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>
div {
    border-radius: var(--border-radius);
}
.skeleton-item {
    display: grid;
    grid-template-rows: 10rem auto;
    background-color: var(--white);
    box-shadow: var(--small-shadow);
    border-radius: var(--border-radius);
    row-gap: var(--spacing);
    animation: loadingAnimation 1s infinite alternate;
}
.skeleton-image {
    width: 100%;
    background-color: #e0e0e0;
}
.skeleton-info {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    padding: 0.5rem;
}
.skeleton-title {
    height: 1.5rem;
    width: 80%;
    background-color: #e0e0e0;
}
.skeleton-metadata {
    height: 1.5rem;
    width: 60%;
    background-color: #e0e0e0;
}
@keyframes loadingAnimation {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
</style>
