import { default as _91attributeName_933rckLFHHrwMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/[categoryName]/[attributeName].vue?macro=true";
import { default as _91locationName_93It8Tm0tlcKMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/[categoryName]/in/[locationName].vue?macro=true";
import { default as indexGA1YJpPmK3Meta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/[categoryName]/index.vue?macro=true";
import { default as about_45uss2Slo1fpIuMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/about-us.vue?macro=true";
import { default as agbyNHtbv6mI9Meta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/agb.vue?macro=true";
import { default as _91name_93kJLRw816JAMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/business/[name].vue?macro=true";
import { default as couponsXidsvoFpL9Meta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/coupons.vue?macro=true";
import { default as delete_45accountIcpEjBl6fCMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/customer-support/delete-account.vue?macro=true";
import { default as form4Hpw1m1UTAMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/customer-support/form.vue?macro=true";
import { default as indexPbovqLyak3Meta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/customer-support/index.vue?macro=true";
import { default as deliveryK9Bto3RxmIMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/delivery.vue?macro=true";
import { default as homevisitsSsZvPuBkG7Meta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/homevisits.vue?macro=true";
import { default as imprintDPgihqBWecMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/imprint.vue?macro=true";
import { default as indexMg45IgFKzJMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/index.vue?macro=true";
import { default as _91locationName_93bBjsASW8zFMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/locations/[locationName].vue?macro=true";
import { default as loginraOC2Avs9cMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/login.vue?macro=true";
import { default as _91locationName_93Tf6AYsfil7Meta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/mobile/[categoryName]/[locationName].vue?macro=true";
import { default as indexNpANVLsoRXMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/mobile/[categoryName]/index.vue?macro=true";
import { default as _91serviceName_93qYgPi0GraaMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/mobile/service/[serviceName].vue?macro=true";
import { default as callbackILAqqSY2TbMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/oauth/callback.vue?macro=true";
import { default as errorh2wGRfuC1yMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/oauth/error.vue?macro=true";
import { default as pressbWOtTpORE7Meta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/press.vue?macro=true";
import { default as privacyvqvcdQfHdNMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/privacy.vue?macro=true";
import { default as favoritessGSUPJXM7HMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/favorites.vue?macro=true";
import { default as indexpe2KbGsUNCMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/index.vue?macro=true";
import { default as locationsArxb83xEbQMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/locations.vue?macro=true";
import { default as change_45infoDapC3fWrfXMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/settings/change-info.vue?macro=true";
import { default as change_45passwordxL13s7vSDQMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/settings/change-password.vue?macro=true";
import { default as delete_45accountFCdXsKx66MMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/settings/delete-account.vue?macro=true";
import { default as indexXYPGv1pJHNMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/settings/index.vue?macro=true";
import { default as optin_45settings48UHVSlCkkMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/settings/optin-settings.vue?macro=true";
import { default as indexVW4HLFIzfWMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/provider/[name]/index.vue?macro=true";
import { default as _91serviceName_93hbFfMM8cIGMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/provider/[name]/mobile/[serviceName].vue?macro=true";
import { default as indexjSha5czFF5Meta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/provider/index.vue?macro=true";
import { default as _91emailConfirmToken_93p1zb8KZdThMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/register/[emailConfirmToken].vue?macro=true";
import { default as indexJuMBa5cqGRMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/register/index.vue?macro=true";
import { default as _91passwordResetToken_93TXbMxIqBKpMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/reset-password/[passwordResetToken].vue?macro=true";
import { default as indexzpyJb134QzMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/reset-password/index.vue?macro=true";
import { default as indexgaaNYkcDVNMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/search/index.vue?macro=true";
import { default as _91sitemapName_93DY9E0VKmqJMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/sitemaps-redirect/[sitemapName].vue?macro=true";
import { default as termscyhovlScOvMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/terms.vue?macro=true";
import { default as component_45stub9i7XqMXexFMeta } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub9i7XqMXexF } from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "categoryName-attributeName___de",
    path: "/:categoryName()/:attributeName()",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/[categoryName]/[attributeName].vue").then(m => m.default || m)
  },
  {
    name: "categoryName-in-locationName___de",
    path: "/:categoryName()/in/:locationName()",
    meta: _91locationName_93It8Tm0tlcKMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/[categoryName]/in/[locationName].vue").then(m => m.default || m)
  },
  {
    name: "categoryName___de",
    path: "/:categoryName()",
    meta: indexGA1YJpPmK3Meta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/[categoryName]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us___de",
    path: "/ueber-uns",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "agb___de",
    path: "/agb",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/agb.vue").then(m => m.default || m)
  },
  {
    name: "business-name___de",
    path: "/business/:name()",
    meta: _91name_93kJLRw816JAMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/business/[name].vue").then(m => m.default || m)
  },
  {
    name: "coupons___de",
    path: "/gutscheine",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: "customer-support-delete-account___de",
    path: "/kundensupport/konto-loeschen",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/customer-support/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "customer-support-form___de",
    path: "/kundensupport/formular",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/customer-support/form.vue").then(m => m.default || m)
  },
  {
    name: "customer-support___de",
    path: "/kundensupport",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/customer-support/index.vue").then(m => m.default || m)
  },
  {
    name: "delivery___de",
    path: "/lieferservices",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "homevisits___de",
    path: "/hausbesuche",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/homevisits.vue").then(m => m.default || m)
  },
  {
    name: "imprint___de",
    path: "/impressum",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "locations-locationName___de",
    path: "/orte/:locationName()",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/locations/[locationName].vue").then(m => m.default || m)
  },
  {
    name: "login___de",
    path: "/login",
    meta: loginraOC2Avs9cMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "mobile-categoryName-locationName___de",
    path: "/mobil/:categoryName()/:locationName()",
    meta: _91locationName_93Tf6AYsfil7Meta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/mobile/[categoryName]/[locationName].vue").then(m => m.default || m)
  },
  {
    name: "mobile-categoryName___de",
    path: "/mobil/:categoryName()",
    meta: indexNpANVLsoRXMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/mobile/[categoryName]/index.vue").then(m => m.default || m)
  },
  {
    name: "mobile-service-serviceName___de",
    path: "/mobil/service/:serviceName()",
    meta: _91serviceName_93qYgPi0GraaMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/mobile/service/[serviceName].vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback___de",
    path: "/oauth/callback",
    meta: callbackILAqqSY2TbMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "oauth-error___de",
    path: "/oauth/error",
    meta: errorh2wGRfuC1yMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/oauth/error.vue").then(m => m.default || m)
  },
  {
    name: "press___de",
    path: "/presse",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/press.vue").then(m => m.default || m)
  },
  {
    name: "privacy___de",
    path: "/datenschutz",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-favorites___de",
    path: "/profil/favoriten",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/favorites.vue").then(m => m.default || m)
  },
  {
    name: "profile___de",
    path: "/profil",
    meta: indexpe2KbGsUNCMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-locations___de",
    path: "/profil/meine-standorte",
    meta: locationsArxb83xEbQMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/locations.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings-change-info___de",
    path: "/profil/einstellungen/info-aendern",
    meta: change_45infoDapC3fWrfXMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/settings/change-info.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings-change-password___de",
    path: "/profil/einstellungen/passwort-aendern",
    meta: change_45passwordxL13s7vSDQMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/settings/change-password.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings-delete-account___de",
    path: "/profil/einstellungen/konto-loeschen",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/settings/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings___de",
    path: "/profil/einstellungen",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings-optin-settings___de",
    path: "/profil/einstellungen/optin-einstellungen",
    meta: optin_45settings48UHVSlCkkMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/profile/settings/optin-settings.vue").then(m => m.default || m)
  },
  {
    name: "provider-name___de",
    path: "/anbieter/:name()",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/provider/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: "provider-name-mobile-serviceName___de",
    path: "/anbieter/:name()/mobil/:serviceName()",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/provider/[name]/mobile/[serviceName].vue").then(m => m.default || m)
  },
  {
    name: "provider___de",
    path: "/anbieter",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/provider/index.vue").then(m => m.default || m)
  },
  {
    name: "register-emailConfirmToken___de",
    path: "/registrieren/:emailConfirmToken()",
    meta: _91emailConfirmToken_93p1zb8KZdThMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/register/[emailConfirmToken].vue").then(m => m.default || m)
  },
  {
    name: "register___de",
    path: "/registrieren",
    meta: indexJuMBa5cqGRMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-passwordResetToken___de",
    path: "/passwort-zuruecksetzen/:passwordResetToken()",
    meta: _91passwordResetToken_93TXbMxIqBKpMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/reset-password/[passwordResetToken].vue").then(m => m.default || m)
  },
  {
    name: "reset-password___de",
    path: "/passwort-zuruecksetzen",
    meta: indexzpyJb134QzMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suchen",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "sitemaps-redirect-sitemapName___de",
    path: "/sitemaps-redirect/:sitemapName()",
    meta: _91sitemapName_93DY9E0VKmqJMeta || {},
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/sitemaps-redirect/[sitemapName].vue").then(m => m.default || m)
  },
  {
    name: "terms___de",
    path: "/nutzungsbedingungen",
    component: () => import("/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: component_45stub9i7XqMXexFMeta?.name,
    path: "/robots.txt",
    component: component_45stub9i7XqMXexF
  },
  {
    name: component_45stub9i7XqMXexFMeta?.name,
    path: "/jobs",
    component: component_45stub9i7XqMXexF
  },
  {
    name: component_45stub9i7XqMXexFMeta?.name,
    path: "/anbieter/afreshed-gmbh-85906",
    component: component_45stub9i7XqMXexF
  },
  {
    name: component_45stub9i7XqMXexFMeta?.name,
    path: "/anbieter/locaverse-gmbh-191522",
    component: component_45stub9i7XqMXexF
  },
  {
    name: component_45stub9i7XqMXexFMeta?.name,
    path: "/anbieter/betreut-85389",
    component: component_45stub9i7XqMXexF
  },
  {
    name: component_45stub9i7XqMXexFMeta?.name,
    path: "/anbieter/extrafrei-gmbh-extrasauber-buchungsportal-fur-reinigungen-31098",
    component: component_45stub9i7XqMXexF
  },
  {
    name: component_45stub9i7XqMXexFMeta?.name,
    path: "/anbieter/myhammer-gmbh-191711",
    component: component_45stub9i7XqMXexF
  },
  {
    name: component_45stub9i7XqMXexFMeta?.name,
    path: "/anbieter/lieferando-osterreich-191709",
    component: component_45stub9i7XqMXexF
  },
  {
    name: component_45stub9i7XqMXexFMeta?.name,
    path: "/anbieter/haushaltshilfe24-at-rasch-gunstige-haushaltshilfe-85411",
    component: component_45stub9i7XqMXexF
  },
  {
    name: component_45stub9i7XqMXexFMeta?.name,
    path: "/anbieter/o-ticket-osterreichs-fuehrender-ticketvertrieb-191714",
    component: component_45stub9i7XqMXexF
  },
  {
    name: component_45stub9i7XqMXexFMeta?.name,
    path: "/anbieter/startselect-welt-der-digitalen-geschenkkarten-191715",
    component: component_45stub9i7XqMXexF
  },
  {
    name: component_45stub9i7XqMXexFMeta?.name,
    path: "/anbieter/mydays-unvergessliche-erlebnisse-schenken-191719",
    component: component_45stub9i7XqMXexF
  }
]