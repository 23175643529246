<template>
    <form v-if="!data.submitted" method="post" @submit.prevent="register">
        <label for="email">
            E-Mail Adresse *
            <input
                id="email"
                v-model="data.userInfo.email"
                type="email"
                name="email"
                placeholder="E-Mail Adresse eingeben"
                required
                maxlength="255"
            >
        </label>
        <label for="password">
            Passwort *
            <input
                id="password"
                v-model="data.userInfo.password"
                :aria-invalid="passwordsDifferent"
                type="password"
                name="password"
                placeholder="Passwort eingeben"
                required
                maxlength="255"
            >
        </label>
        <label for="repeat_password">
            Passwort wiederholen *
            <input
                id="repeat_password"
                v-model="data.userInfo.repeatPassword"
                :aria-invalid="passwordsDifferent"
                type="password"
                name="repeat_password"
                placeholder="Passwort wiederholen"
                required
                maxlength="255"
            >
        </label>
        <label for="newsletter" class="newsletter">
            <input
                id="newsletter"
                v-model="data.userInfo.newsletter"
                type="checkbox"
                name="newsletter"
            >
            Newsletter mit exklusiven Angeboten, Tipps und Neuigkeiten anmelden
        </label>
        <button :aria-busy="data.submitting" type="submit">
            Registrieren
        </button>
    </form>
    <LazySuccessfulRegistrationConfirmation v-else />
</template>

<script setup>
const data = reactive({
    submitting: false,
    submitted: false,
    userInfo: {
        email: '',
        password: '',
        repeatPassword: '',
        newsletter: false,
        userType: 'user'
    }
})

const passwordsDifferent = computed(() => {
    if (data.userInfo.password === '' && data.userInfo.repeatPassword === '') { return '' }
    return (data.userInfo.password !== data.userInfo.repeatPassword ||
            data.userInfo.password.length < 6 || data.userInfo.repeatPassword.length < 6)
        ? 'true'
        : 'false'
})

onMounted(() => {
    data.userInfo.email = route.query.email
})

const favoritesStore = useFavoritesStore()
const { localFavoriteServices, localFavoriteBusinessCoupons } = storeToRefs(favoritesStore)

const route = useRoute()
const toast = useToast()
const { $backendApi } = useNuxtApp()

async function register () {
    if (data.userInfo.password !== data.userInfo.repeatPassword) {
        return toast.error('Die Passwörter stimmen nicht überein.')
    }
    if (data.userInfo.password.length < 6 || data.userInfo.repeatPassword.length < 6) {
        return toast.error('Die Passwörter müssen mindestens 6 zeichen lang sein.')
    }
    data.submitting = true
    const localFavoriteServicesIds = localFavoriteServices.value.map(s => s.id)
    const localFavoriteBusinessCouponsIds = localFavoriteBusinessCoupons.value.map(s => s.id)
    try {
        await $backendApi('/auth/register', {
            method: 'POST',
            body: {
                ...data.userInfo,
                favoriteServicesIds: localFavoriteServicesIds,
                favoriteBusinessCouponsIds: localFavoriteBusinessCouponsIds
            }
        })
        toast.success('Registrierung erfolgreich.')
        data.submitted = true
    } catch (error) {
        handleBackendError({ error, toast })
    }
    data.submitting = false
}
</script>

<style scoped>
.newsletter {
    font-size: 0.8rem;
    margin-bottom: var(--spacing);
}
</style>
