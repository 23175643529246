<template>
    <h1 class="centered mt-2">
        Diese Seite ist umgezogen... :)
    </h1>
</template>

<script setup>
import { HYPHEN } from '~/constants'

definePageMeta({
    middleware: defineNuxtRouteMiddleware(async (to) => {
        if (process.client) { return }
        const name = to.params.name
        let businessNameId = name
        if (name.includes(HYPHEN)) {
            // old URL, fetch backend to get new permanent name
            const nameParts = to.params.name.split(HYPHEN)
            const id = nameParts[nameParts.length - 1]
            const { data, error } = await useIFetch(`/businesses/redirect/b/${id}`)
            if (error.value) {
                throw createError({
                    statusCode: 404,
                    statusMessage: 'Anbieter nicht gefunden.'
                })
            }
            businessNameId = `${data.value.businessPermanentName}-${data.value.businessId}`
        }
        return navigateTo(`/anbieter/${businessNameId}`, { redirectCode: 301 })
    })
})
</script>
