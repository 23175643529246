<template>
    <div class="pagination">
        <NuxtLink
            v-if="page > 1"
            class="control-button pagination-button cb-left"
            :to="linkToPage(page - 1)"
            @click.stop.prevent="scrollUp"
        >
            <img src="assets/icons/arrows/chevron-left-solid.svg" alt="Zurück Pfeil">
            <span class="label">Zurück</span>
        </NuxtLink>
        <div v-if="totalPages < 6" class="pagination-links-container">
            <!-- Render all pages if there are < 6 pages in total-->
            <NuxtLink
                v-for="p in totalPages"
                :key="p"
                class="pagination-button pagination-element"
                :class="{active: p === page}"
                :to="linkToPage(p)"
                @click.stop.prevent="scrollUp"
            >
                {{ p }}
            </NuxtLink>
        </div>
        <div v-else-if="page < 5" class="pagination-links-container">
            <!--totalPages >= 6, Render all divs from 1 to page (4 included) here, ..., last page disabled-->
            <NuxtLink
                v-for="p in 4"
                :key="p"
                class="pagination-button pagination-element"
                :class="{active: p === page}"
                :to="linkToPage(p)"
                @click.stop.prevent="scrollUp"
            >
                {{ p }}
            </NuxtLink>
            <span class="spacing-element">...</span>
            <span class="pagination-button pagination-element disabled">{{ totalPages }}</span>
        </div>
        <div v-else-if="totalPages - page > 2" class="pagination-links-container">
            <!--Render first page, ..., 3 pages, ..., last page disabled-->
            <NuxtLink
                class="pagination-button pagination-element"
                :to="linkToPage(1)"
                @click.stop.prevent="scrollUp"
            >
                1
            </NuxtLink>
            <span class="spacing-element">...</span>
            <NuxtLink
                class="pagination-button pagination-element"
                :to="linkToPage(page - 1)"
                @click.stop.prevent="scrollUp"
            >
                {{ page - 1 }}
            </NuxtLink>
            <span class="active pagination-element">
                {{ page }}
            </span>
            <NuxtLink
                class="pagination-button pagination-element"
                :to="linkToPage(page + 1)"
                @click.stop.prevent="scrollUp"
            >
                {{ page + 1 }}
            </NuxtLink>
            <span class="spacing-element">...</span>
            <span class="pagination-button pagination-element disabled">
                {{ totalPages }}
            </span>
        </div>
        <div v-else class="pagination-links-container">
            <!--Render first page, ..., last three pages-->
            <NuxtLink
                class="pagination-button pagination-element"
                :to="linkToPage(1)"
                @click.stop.prevent="scrollUp"
            >
                1
            </NuxtLink>
            <span class="spacing-element">...</span>
            <NuxtLink
                v-for="p in 3"
                :key="p"
                class="pagination-button pagination-element"
                :class="{active: totalPages - 3 + p === page}"
                :to="linkToPage(totalPages - 3 + p)"
                @click.stop.prevent="scrollUp"
            >
                {{ totalPages - 3 + p }}
            </NuxtLink>
        </div>
        <NuxtLink
            v-if="page < totalPages"
            class="control-button pagination-button cb-right"
            :to="linkToPage(page + 1)"
            @click.stop.prevent="scrollUp"
        >
            <span class="label">Weiter</span>
            <img src="assets/icons/arrows/chevron-right-solid.svg" alt="Weiter Pfeil">
        </NuxtLink>
    </div>
</template>

<script setup>
const props = defineProps({
    /** The total number of items to be paginated. */
    total: {
        type: Number,
        required: true,
        validator: value => value > 0
    },
    /** The current page number. */
    page: {
        type: Number,
        required: true,
        validator: value => value > 0
    },
    /** The number of items displayed per page. */
    perPage: {
        type: Number,
        default: 20,
        validator: value => value > 0
    }
})

const route = useRoute()
const router = useRouter()

/**
 * Calculates the total number of pages based on the total items and items per page.
 * @returns {Number} - Total number of pages.
 */
const totalPages = computed(() => {
    return Math.ceil(props.total / props.perPage)
})
/**
 * Checks if there is a next page.
 * @returns {Boolean} - True if there is a next page; otherwise, false.
 */
const hasNextPage = computed(() => {
    return props.page < totalPages.value
})

function linkToPage (pageNumber) {
    const query = {
        ...route.query,
        _page: pageNumber !== 1 ? pageNumber : undefined
    }
    const resolved = router.resolve({ path: route.path, query })
    return resolved.fullPath
}

function scrollUp () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

useHead({
    link: () => {
        const links = []
        if (props.page > 1) {
            links.push({
                rel: 'prev', content: linkToPage(props.page - 1)
            })
        }
        if (hasNextPage.value) {
            links.push({
                rel: 'next', content: linkToPage(props.page + 1)
            })
        }
        return links
    }
})
</script>

<style scoped>
.pagination {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--small-shadow);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.pagination-links-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.control-button {
    width: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 0.3rem;
    height: 3rem;
}
.cb-left {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}
.cb-right {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}
.control-button .label { display: none; }
.control-button img {
    height: 0.5rem;
    width: 0.5rem;
}
.pagination-button {
    padding: 0.5rem;
    transition: 0.2s ease-in-out;
    height: 3rem;
}
.pagination-button:not(.disabled) {
    cursor: pointer;
}
.pagination-button:not(.disabled, .active):hover {
    background-color: var(--primary-100);
}
.pagination-element {
    width: 2.5rem;
    display: grid;
    place-items: center;
    height: 3rem;
}
.spacing-element {
    width: 1.5rem;
    display: grid;
    place-items: center;
    height: 3rem;
}
.active { background-color: var(--primary-200); }
.disabled {
    color: var(--light-gray);
}
@media (min-width: 425px) {
    .control-button {
        width: 5rem;
    }
    .control-button .label { display: inline-block; }
}
</style>
