<template>
    <div class="sort-container">
        <label for="date">Sortieren</label>
        <select id="date" v-model="sort">
            <option value="asc">
                Datum: Aufsteigend
            </option>
            <option value="desc">
                Datum: Absteigend
            </option>
        </select>
    </div>
</template>

<script setup>
const sort = defineModel({ type: String, default: 'asc' })
</script>

<style scoped>
.sort-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
}
.sort-container label {
    font-weight: bold;
    margin-bottom: 0;
}
.sort-container select {
    margin: 0;
}
</style>
