<template>
    <div>
        <div
            class="side-filters"
            :class="{ hide: !showFilters }"
        >
            <div class="filter-header mb-1">
                <p><b>Filteroptionen ({{ totalSetFilters }})</b></p>
                <span
                    class="reset-text"
                    @click="resetFilters"
                >
                    Alle zurücksetzen
                </span>
                <CloseIcon @close="toggleFilters" />
            </div>
            <label
                for="coming"
                @click="handleComingFilter"
            >
                <input
                    id="coming"
                    v-model="filters.onlyComing"
                    type="checkbox"
                    name="coming"
                >

                Die zu mir kommen
                <img
                    class="inline-icon"
                    src="/assets/images/allekommen-icon.svg"
                    alt="Kommt zu mir"
                    loading="lazy"
                >
            </label>
            <label for="hasCoupons">
                <input
                    id="hasCoupons"
                    v-model="filters.hasCoupons"
                    type="checkbox"
                    name="hasCoupons"
                >
                Im Angebot <ActionTag />
            </label>
            <label for="sustainable">
                <input
                    id="sustainable"
                    v-model="filters.sustainable"
                    type="checkbox"
                    name="sustainable"
                >
                Nachhaltig <ServiceSustainabilityBadge />
            </label>
            <!-- <label for="fees">
            <input
                id="fees"
                v-model="filters.noServiceFees"
                type="checkbox"
                name="fees"
            >
            Ohne zusätzlicher Gebühren
        </label> -->
            <label for="withInstagram">
                <input
                    id="withInstagram"
                    v-model="filters.withInstagram"
                    type="checkbox"
                    name="withInstagram"
                >
                Mit Instagram-Profil <img
                    class="inline-icon"
                    src="/assets/icons/social-media-color/instagram-color.png"
                    alt="Instagram"
                    title="Instagram"
                >
            </label>
            <label for="withImages">
                <input
                    id="withImages"
                    v-model="filters.withImages"
                    type="checkbox"
                    name="withImages"
                >
                Mit Bildern
            </label>
            <SearchAttributesFilter
                v-model="filters.attributes"
                :attributes="attributes"
            />
            <div class="mt-1">
                <b>Kundenbewertungen</b>
                <span
                    v-if="filters.minRating !== 0"
                    class="reset-text"
                    @click="filters.minRating = 0"
                >
                    Zurücksetzen
                </span>
                <SearchFilterRating v-model="filters.minRating" />
            </div>
            <p class="mt-1">
                <b>Anbieter</b>
            </p>
            <label for="individual">
                <input
                    id="individual"
                    v-model="filters.individual"
                    type="checkbox"
                    name="individual"
                >
                Privat
            </label>
            <label for="business">
                <input
                    id="business"
                    v-model="filters.business"
                    type="checkbox"
                    name="business"
                >
                Gewerblich
            </label>
            <p class="mt-1">
                <b>Servicegebiet</b>
            </p>
            <label for="national">
                <input
                    id="national"
                    v-model="filters.national"
                    type="checkbox"
                    name="national"
                >
                National
            </label>
            <label for="regional">
                <input
                    id="regional"
                    v-model="filters.regional"
                    type="checkbox"
                    name="regional"
                >
                Regional
            </label>
            <p class="mt-1">
                <b>Verfügbarkeit</b>
            </p>
            <label for="inactive">
                <input
                    id="inactive"
                    v-model="filters.includeInactive"
                    type="checkbox"
                    name="inactive"
                >
                Nicht verfügbare Services anzeigen
            </label>
            <button
                class="done mt-1"
                @click="toggleFilters"
            >
                Fertig
            </button>
        </div>
        <AdsVerticalAd
            v-if="!$device.isMobile"
            ad-slot="5956485677"
            class="mt-1"
        />
    </div>
</template>

<script setup>
const filterCount = defineModel({ type: Number })

const { attributes } = defineProps({
    attributes: { type: Array, required: false, default: () => [] }
})

function toggleFilters () {
    showFilters.value = !showFilters.value
    document.body.classList.toggle('no-scroll')
}
const showFilters = ref(false)

defineExpose({ toggleFilters })

const searchStore = useSearchStore()
const { address, lat } = storeToRefs(searchStore)

const route = useRoute()
const router = useRouter()
const initialFilters = {
    onlyComing: false,
    individual: false, // providerType
    business: false, // providerType
    minRating: 0,
    noServiceFees: false,
    withImages: false,
    hasCoupons: false,
    national: false,
    regional: false,
    sustainable: false,
    includeInactive: false,
    withInstagram: false,
    attributes: []
}

const filters = ref({ ...initialFilters })

onMounted(() => {
    updateFiltersFromQuery()
    filterCount.value = totalSetFilters.value
})

function handleComingFilter (event) {
    if (!address.value) {
        event.preventDefault()
        searchStore.setShowAddressModal(true, true)
    }
}

// Method to update filters based on the query parameters
function updateFiltersFromQuery () {
    const queryParams = route.query
    filters.value.onlyComing = (queryParams.onlyComing === 'true' && !!address.value)
    filters.value.individual = queryParams.providerType === 'individual'
    filters.value.business = queryParams.providerType === 'business'
    filters.value.minRating = parseInt(queryParams.minRating) || 0
    filters.value.noServiceFees = queryParams.serviceFees === 'false'
    filters.value.hasCoupons = queryParams.hasCoupons === 'true'
    filters.value.withImages = queryParams.withImages === 'true'
    filters.value.national = queryParams.national === 'true'
    filters.value.regional = queryParams.regional === 'true'
    filters.value.withInstagram = queryParams.withInstagram === 'true'
    filters.value.sustainable = queryParams.sustainable === 'true'
    filters.value.includeInactive = queryParams.includeInactive === 'true'
    filters.value.attributes = queryParams.attributes ? queryParams.attributes.split(',') : []
}

/**
 * Calculates the total number of set filters.
 * @returns {number} The total number of set filters.
 */
const totalSetFilters = computed(() => {
    let overallLength = Object.values(filters.value).filter(val => val).length
    if (
        (filters.value.individual && filters.value.business)
        || (filters.value.national && filters.value.regional)
        || filters.value.attributes.length === 0
    ) {
        overallLength--
    }
    return overallLength
})

/** Resets all filters to their default values */
function resetFilters () {
    Object.assign(filters.value, {
        ...initialFilters // Use spread operator for most properties
    })
}
let initialLoad = true
// Watch for changes in the filters and update the route.query accordingly
watch(filters, () => {
    // Construct the updated query parameters based on the filters
    const updatedQuery = {
        ...route.query,
        _page: initialLoad ? route.query._page : 1,
        onlyComing: filters.value.onlyComing ? 'true' : undefined,
        providerType: filters.value.individual
            ? 'individual'
            : filters.value.business
                ? 'business'
                : undefined,
        minRating: filters.value.minRating > 0 ? filters.value.minRating.toString() : undefined,
        serviceFees: filters.value.noServiceFees ? 'false' : undefined,
        hasCoupons: filters.value.hasCoupons ? 'true' : undefined,
        sustainable: filters.value.sustainable ? 'true' : undefined,
        includeInactive: filters.value.includeInactive ? 'true' : undefined,
        withImages: filters.value.withImages ? 'true' : undefined,
        withInstagram: filters.value.withInstagram ? 'true' : undefined,
        attributes: filters.value.attributes.length ? filters.value.attributes.join(',') : undefined,
        national: filters.value.national === filters.value.regional ? undefined : filters.value.national.toString(),
        regional: filters.value.national === filters.value.regional ? undefined : filters.value.regional.toString()
    }
    // Replace the current route with the updated query
    router.replace({ path: route.path, query: updatedQuery })
    initialLoad = false
}, { deep: true })

watch(() => lat.value, () => {
    filters.value.onlyComing = !!address.value
})
</script>

<style scoped>
p { margin-bottom: 0.3rem; }
label { cursor: pointer; }
.side-filters {
    background-color: var(--white);
    padding: var(--spacing);
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    overflow-y: auto;
}
.hide { display: none; }
.reset-text {
    text-decoration: underline;
    font-size: smaller;
    margin-left: 0.5rem;
    cursor: pointer;
}
.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-right: 3rem;
}
.inline-icon {
    height: 1.5rem;
}
@media (min-width: 1024px) {
    .filter-header {
        margin-right: 0;
    }
    .side-filters {
        position: initial;
        width: 100%;
        border-radius: var(--border-radius);
        height: fit-content;
        box-shadow: var(--small-shadow);
        z-index: 1;
    }
    .done { display: none; }
    .hide { display: block; } /* Filters cannot be hidden on large screens */
}
</style>
