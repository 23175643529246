import revive_payload_client_4sVQNw7RlN from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import titles_dw2T9lEw4h from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_fcc0iSNKKy from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_izaWKZ8rEu from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AOuQ1DYzjk from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import backend_api_6kqJu2PRR2 from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/plugins/backend-api.js";
import client_session_storage_TAd6R5bHxp from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/plugins/client-session-storage.js";
import error_handler_VFH3VvK7yG from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/plugins/error-handler.js";
import my_t_KBBI9ziEUp from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/plugins/my-t.js";
import posthog_client_o9IEgU9LwG from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/plugins/posthog.client.js";
import sentry_client_GoGQuZo4Et from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/plugins/sentry.client.js";
import vue_toastificaton_client_I3tNgVjrzA from "/home/runner/work/root-frontend-nuxt-3/root-frontend-nuxt-3/plugins/vue-toastificaton.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  titles_dw2T9lEw4h,
  defaultsWaitI18n_fcc0iSNKKy,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_AOuQ1DYzjk,
  backend_api_6kqJu2PRR2,
  client_session_storage_TAd6R5bHxp,
  error_handler_VFH3VvK7yG,
  my_t_KBBI9ziEUp,
  posthog_client_o9IEgU9LwG,
  sentry_client_GoGQuZo4Et,
  vue_toastificaton_client_I3tNgVjrzA
]