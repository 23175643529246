<template>
    <div class="info-icon small">
        i
    </div>
</template>

<script setup>
</script>

<style scoped>
.info-icon {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary);
    cursor: pointer;
    border: 1px solid var(--primary);
    border-radius: var(--border-radius);
    display: grid;
    place-items: center;
    aspect-ratio: 1/1;
    transition: all 0.3s ease-in-out;
}
.info-icon:hover {
    background-color: var(--primary-100);
}
</style>
