<template>
    <section>
        <StaticContentLoader name="Impressum" />
    </section>
</template>

<script setup>
useHead({
    title: 'Impressum'
})
</script>

<style scoped>
</style>
