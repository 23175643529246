<template>
    <p class="register-note">
        Mit der Autorisierung stimme ich den
        <NuxtLink :to="localePath('privacy')">
            Datenschutzbestimmungen
        </NuxtLink>
        und den
        <NuxtLink :to="localePath('terms')">
            Nutzungsbedingungen
        </NuxtLink>
        zu.
    </p>
</template>

<script setup>
const localePath = useLocalePath()
</script>

<style scoped>
.register-note {
    margin-top: var(--spacing);
    font-size: 0.6rem;
    text-align: center;
}
p { margin: 0; }
</style>
