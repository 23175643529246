<template>
    <div class="filter-bar">
        <div
            :aria-busy="loading"
            class="result-count"
        >
            <span>Treffer <span v-if="!loading">({{ totalResults }})</span></span>
            <ShareButton
                class="small"
                @share="toggleShare"
            />
        </div>
        <div class="filter-bar-icons-container">
            <button
                class="outline filter-button small mobile-filter-button"
                @click="emit('show-filters')"
            >
                Filter
                <span v-if="filterCount > 0">({{ filterCount }})</span>
                <img
                    src="assets/icons/filter-primary.svg"
                    alt="Filter Icon"
                    loading="lazy"
                >
            </button>
            <SearchSortingSelectField />
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['show-filters'])

const props = defineProps({
    totalResults: { type: Number, required: true },
    filterCount: { type: Number, required: true },
    pageTitle: { type: String, required: true },
    loading: { type: Boolean, required: true }
})

function toggleShare() {
    handleShare(props.pageTitle, window.location, props.pageTitle)
}
</script>

<style scoped>
.filter-bar {
    display: flex;
    margin-bottom: var(--spacing);
    border-bottom: 1px solid var(--light-gray);
    padding-bottom: calc(0.5 * var(--spacing));
    flex-direction: column;
    row-gap: var(--spacing);
}
.filter-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 0.3rem;
}
.filter-button img { width: 1.3rem; height: 1.3rem; }
.filter-bar-icons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: var(--spacing);
}
.result-count {
    display: flex;
    flex-direction: row;
    column-gap: var(--spacing);
    align-items: center;
    justify-content: space-between;
}
@media (min-width: 480px) {
    .filter-bar {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .filter-bar-icons-container {
        display: flex;
        flex-direction: row;
    }
}
@media (min-width: 1024px) {
    .mobile-filter-button { display: none; }
    .result-count { justify-content: flex-start; }
}
</style>
