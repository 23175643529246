<template>
    <div class="contact">
        <ServiceActionButtons :service="service" />
        <img
            class="contact-image"
            :src="cardImage"
            :alt="service.name"
            loading="lazy"
            @click="redirectToBookingLink"
        >
        <p class="small-title">
            Dein Kontakt zum Anbieter
        </p>
        <div class="space-between">
            <BusinessSocialMediaInfo :business="service.business" />
            <div
                class="yellow"
                role="button"
                @click="redirectToBookingLink"
            >
                Jetzt bestellen
            </div>
        </div>
        <a
            v-if="service.business.hasOwner && service.business.enableContactForm"
            href="#contactForm"
            role="button"
            class="outline small mt-1"
        >
            <img
                src="assets/icons/message-solid.svg"
                class="contact-icon"
                alt="Nachricht schreiben"
            >
            Nachricht schreiben
        </a>
        <div
            v-if="service.paymentMethods?.length > 0"
            class="mt-1"
        >
            <p><strong>Akzeptierte Zahlungsmethoden</strong></p>
            <div class="methods-container">
                <div
                    v-for="(paymentMethod, index) in service.paymentMethods"
                    :key="'paymentMethod-' + index"
                    class="method"
                    :data-tooltip="paymentMethod.displayName"
                >
                    <img
                        :src="`${SPACES_URL}/images/payment-methods/${paymentMethod.logo}`"
                        :alt="paymentMethod.displayName"
                        loading="lazy"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const { service, cardImage } = defineProps({
    service: { type: Object, required: true },
    cardImage: { type: String, required: true }
})
const emit = defineEmits(['show-feedback'])

const SPACES_URL = useRuntimeConfig().public.spacesUrl

const { $clientSessionStorage } = useNuxtApp()
function redirectToBookingLink () {
    const loggedServiceIds = $clientSessionStorage.getItem('msfeedbacks') || []
    if (!loggedServiceIds.includes(service.id)) {
        emit('show-feedback')
        logFeedback()
    }
    useClickout(service.bookingLink, service.trackingLink)
}

function logFeedback () {
    const loggedServiceIds = $clientSessionStorage.getItem('msfeedbacks') || []
    loggedServiceIds.push(service.id)
    $clientSessionStorage.setItem({ item: 'msfeedbacks', value: loggedServiceIds })
}
</script>

<style scoped>
p { margin-bottom: 0.5rem; }
.methods-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    flex-wrap: wrap;
}
.method {
    background-color: var(--white);
    padding: 0.3rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--neutral-200);
}
.contact-icon {
    width: 1.3rem;
    height: 1.3rem;
}
.method img {
    height: 1.5rem;
    width: 100%;
}
.contact-image {
    margin-top: 1rem;
    max-height: 12rem;
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.space-between { flex-wrap: wrap; row-gap: var(--spacing); }
</style>
