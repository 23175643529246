import { SEARCH_SUGGESTIONS_DEBOUNCE } from '~/constants'

export function useDebouncedInput () {
    const tempSearchTerm = ref('')
    let searchDebounce = null
    let lastLength = 0
    let stopSearch = false

    function searchWithDebounce (searchTerm) {
        clearTimeout(searchDebounce)
        searchDebounce = setTimeout(() => {
            const delta = tempSearchTerm.value.length - lastLength
            // check if suggestions are still possible (if searchTerm gets longer and with a
            // shorter one there were no suggestions already, there is no point in fetching further
            if (delta > 0 && stopSearch) { return }
            lastLength = tempSearchTerm.value.length
            stopSearch = false
            searchTerm.value = tempSearchTerm.value
        }, SEARCH_SUGGESTIONS_DEBOUNCE)
    }
    return {
        tempSearchTerm,
        searchWithDebounce
    }
}
