<template>
    <h1 class="centered mt-2">
        Diese Seite ist umgezogen... :)
    </h1>
</template>

<script setup>
definePageMeta({
    middleware: defineNuxtRouteMiddleware(async (to) => {
        if (process.client) { return }
        const nameParam = to.params.serviceName
        const serviceNameParts = nameParam.split('-')
        const serviceId = serviceNameParts[serviceNameParts.length - 1]
        const { data, error } = await useIFetch(`/businesses/redirect/${serviceId}`)
        if (error.value) { throw error.value }
        return navigateTo(`/anbieter/${data.value.businessPermanentName}-${data.value.businessId}/mobil/${nameParam}`, { redirectCode: 301 })
    })
})
</script>
