<template>
    <section>
        <h1>Alle mobilen Dienstleistungen und Lieferservices{{ pageString }}</h1>
        <SearchResultsPage :ignore-search-term="false" page-title="Alle mobilen Dienstleistungen und Lieferservices auf allekommen.at" />
    </section>
</template>

<script setup>
const { pageString } = useQueryPageString()
useHead({
    title: `Alle mobilen Services${pageString.value}`,
    meta: [{ name: 'robots', content: 'noindex' }]
})
</script>

<style scoped>
h1 { margin-bottom: 0; }
</style>
