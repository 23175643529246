<template>
    <AccordionComponent
        title="Empfohlene Partner"
        :open="true"
    >
        <template #links>
            <NuxtLink
                v-for="provider in recommendedProviders[locale]"
                :key="provider.permanentName"
                :to="localePath({ name: 'provider-name', params: { name: provider.permanentName } })"
            >
                {{ provider.name }}
            </NuxtLink>
        </template>
    </AccordionComponent>
</template>

<script setup>
const localePath = useLocalePath()
const locale = useRuntimeConfig().public.locale

const recommendedProviders = {
    at: [
        { name: 'Lieferando', permanentName: 'lieferando-191709' },
        { name: 'babysitter.at', permanentName: 'babysitter-at-85421' },
        { name: 'Betreut.at', permanentName: 'betreut-at-85389' },
        { name: 'Alfies', permanentName: 'alfies-191743' },
        { name: 'ExtraSauber', permanentName: 'extrasauber-31098' },
        { name: 'VIENNTUS', permanentName: 'vienntus-191745' },
        { name: 'Idealo', permanentName: 'idealo-85387' },
        { name: 'MyHammer', permanentName: 'myhammer-191711' },
        { name: 'Haushaltshilfe24', permanentName: 'haushaltshilfe24-85411' },
        { name: 'Euroflorist', permanentName: 'euroflorist-85422' },
        { name: 'Bezahlbare Blumen', permanentName: 'bezahlbare-blumen-85855' },
        { name: 'Regionsflorist', permanentName: 'regionsflorist-191751' },
        { name: 'Ninja Kitchen', permanentName: 'ninja-kitchen-191739' },
        { name: 'Shark Clean', permanentName: 'shark-191738' },
        { name: 'Le Ciel Vintage', permanentName: 'le-ciel-vintage-191523' }
    ],
    de: [
        { name: 'Lieferando', permanentName: 'lieferando-2' },
        { name: 'hallobabysitter.de', permanentName: 'hallobabysitter-de-1' }
    ]
}
</script>

<style scoped>
a { color: var(--white); }
</style>
