<template>
    <section class="container ptb-1">
        <div v-if="!failed">
            <h1>E-Mail wird bestätigt...</h1>
            <progress />
        </div>
        <div v-else>
            <h3>
                Neuen Link anfragen
            </h3>
            Leider ist es zu einem Fehler gekommen. Bitte überprüfe den Link, er könnte falsch oder
            abgelaufen sein.
            <FormResendEmailConfirmation />
        </div>
    </section>
</template>

<script setup>
definePageMeta({
    auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: '/'
    }
})

const route = useRoute()
const router = useRouter()
const toast = useToast()
const { $backendApi } = useNuxtApp()
const localePath = useLocalePath()
const failed = ref(false)

onMounted(async () => {
    try {
        const token = route.params.emailConfirmToken
        const query = new URLSearchParams(route.query).toString()
        await $backendApi(`auth/confirm-email/validate/${token}?${query}`)
        toast.success('Bestätigung erfolgreich.')
        await router.push(localePath('login'))
    } catch (error) {
        failed.value = true
        const errorMsg = error.response ? error.response._data.msg : error
        toast.error(`Bestätigung fehlgeschlagen. ${errorMsg}`, { timeout: 5000 })
    }
})

useHead({
    title: 'E-Mail bestätigen',
    meta: [{ name: 'robots', content: 'noindex' }]
})
</script>

<style scoped>
.container {
    min-height: 60vh;
}
</style>
