<template>
    <div>
        <h3
            class="accordion-title"
            :style="{
                'color': `var(${whiteTheme ? '--white' : '--color'})`,
                'border-top-color': `var(${whiteTheme ? '--white' : '--color'})`
            }"
            @click="toggleAccordion"
        >
            {{ title }}
            <span
                ref="arrow"
                class="arrow"
                :style="{
                    'border-top-color': `var(${whiteTheme ? '--white' : '--color'})`,
                    'border-left-color': `var(${whiteTheme ? '--white' : '--color'})`
                }"
            />
        </h3>
        <div ref="panel" class="panel">
            <div class="links" :class="{'accordion-links': useLayout}">
                <slot name="links" />
            </div>
        </div>
    </div>
</template>

<script setup>
const { open, title } = defineProps({
    open: { type: Boolean, default: false },
    useLayout: { type: Boolean, default: true },
    title: { type: String, required: true },
    whiteTheme: { type: Boolean, default: true }
})

const panel = ref(null)
const arrow = ref(null)

onMounted(() => {
    if (open) { toggleAccordion() }
})

function toggleAccordion () {
    if (panel.value.style.maxHeight) {
        panel.value.style.maxHeight = null
        arrow.value.style.transform = 'rotate(225deg)'
        arrow.value.style.marginTop = '0px'
    } else {
        panel.value.style.maxHeight = panel.value.scrollHeight + 'px'
        arrow.value.style.transform = 'rotate(45deg)'
        arrow.value.style.marginTop = '5px'
    }
}
</script>

<style scoped>
.accordion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    text-align: left;
    outline: none;
    transition: 0.2s;
    margin-bottom: 0;
    border-top: 1px solid #001E3B;
    padding: 0.5rem;
}
.panel {
    background-color: transparent;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}
.arrow {
    display: block;
    width: 10px;
    height: 10px;
    border-top: 3px solid var(--white);
    border-left: 3px solid var(--white);
    transform: rotate(225deg);
    padding-left: 0.5rem;
}
.links { padding: 1rem 0.5rem 0 0.5rem; }
.accordion-links {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    row-gap: var(--spacing);
}
.accordion-links a {
    margin-top: 5px;
    text-decoration: none;
    cursor: pointer;
}
@media (min-width: 768px) {
    .accordion-links {
        display: grid;
        column-gap: var(--spacing);
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
}
@media (min-width: 1024px) {
    .accordion-links {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
</style>
