<template>
    <section class="page">
        <NavigationGoBack />
        <h1 class="mt-1">
            Mein Profil
        </h1>
        <form ref="infoForm" @submit.prevent="submitForm">
            <div class="photo-input">
                <div class="photo-container">
                    <img
                        :src="photoPath"
                        class="avatar"
                        alt="Profilbild"
                        loading="lazy"
                    >
                    <span
                        v-if="data.photo"
                        ref="deleteMessageSpan"
                        class="centered delete"
                        :aria-busy="deleting"
                        @click="deletePhoto"
                    >
                        Löschen
                    </span>
                </div>
                <label for="photo">
                    Füge ein Profilbild hinzu
                    <input
                        id="photo"
                        ref="photo"
                        type="file"
                        name="photo"
                        accept=".jpg,.jpeg,.png,.gif"
                    >
                </label>
            </div>

            <label for="first_name">
                Vorname
                <input
                    id="first_name"
                    v-model="form.firstName"
                    type="text"
                    name="first_name"
                    placeholder="Vorname eingeben"
                    maxlength="100"
                >
            </label>
            <label for="last_name">
                Nachname
                <input
                    id="last_name"
                    v-model="form.lastName"
                    type="text"
                    name="last_name"
                    placeholder="Nachname eingeben"
                    maxlength="100"
                >
            </label>
            <label for="date">
                Geburtsdatum
                <input
                    id="date"
                    v-model="form.birthDate"
                    max="2023-01-01"
                    type="date"
                    name="date"
                >
            </label>
            <button :aria-busy="submitting" type="submit">
                Speichern
            </button>
        </form>
    </section>
</template>

<script setup>
const config = useRuntimeConfig()
const SPACES_URL = config.public.spacesUrl

const localePath = useLocalePath()
const toast = useToast()
const router = useRouter()
const { $backendApi } = useNuxtApp()
const { data } = useAuthState()
const { getSession } = useAuth()

const form = ref({
    birthDate: data.value.birthDate,
    firstName: data.value.firstName,
    lastName: data.value.lastName
})
const photo = ref(null)
const submitting = ref(false)
const deleting = ref(false)
const infoForm = ref(null)

const photoPath = computed(() => {
    return data.value.photo ? `${SPACES_URL}/images/users/${data.value.photo}` : '/icons/user-solid.svg'
})

async function submitForm () {
    const formData = new FormData()
    formData.append('file', photo.value.files[0])
    formData.append('userInfo', JSON.stringify(form.value))
    submitting.value = true
    try {
        await $backendApi('/users/info', {
            method: 'PUT',
            body: formData
        })
        infoForm.value.reset()
        toast.success('Profildaten erfolgreich aktualisiert.', { timeout: 5000 })
        await getSession()
        await router.push(localePath('profile'))
    } catch (error) {
        handleBackendError({ error, toast })
    }
    submitting.value = false
}
async function deletePhoto () {
    deleting.value = true
    try {
        await $backendApi('/users/photo', { method: 'DELETE' })
        toast.success('Bild erfolgreich gelöscht.', { timeout: 5000 })
        await getSession()
    } catch (error) {
        handleBackendError({ error, toast })
    }
    deleting.value = false
}

definePageMeta({
    middleware: 'auth'
})
useHead({
    title: 'Profil',
    meta: [{ name: 'noindex', content: 'noindex' }]
})
</script>

<style scoped>
.equal-grid label, .photo-input img {
    margin: auto;
}
.avatar {
    vertical-align: middle;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 2px solid var(--primary);
}
.photo-input {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 0.5rem;
    margin: var(--form-element-spacing-vertical) 0;
}
.photo-input label input {
    margin: 0
}
.photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.delete {
    color: var(--red);
    cursor: pointer;
}
</style>
