<template>
    <div v-if="linksGiven" class="some-container">
        <a v-if="business.facebookLink" :href="business.facebookLink" target="_blank">
            <img src="/assets/icons/social-media-color/facebook-color.svg" alt="Facebook Icon">
        </a>
        <a v-if="business.linkedinLink" :href="business.linkedinLink" target="_blank">
            <img src="/assets/icons/social-media-color/linkedin-color.svg" alt="LinkedIn Icon">
        </a>
        <a v-if="business.instagramLink" :href="`https://www.instagram.com/${business.instagramLink}`" target="_blank">
            <img src="/assets/icons/social-media-color/instagram-color.png" alt="Instagram Icon">
        </a>
        <a v-if="business.youtubeLink" :href="business.youtubeLink" target="_blank">
            <img src="/assets/icons/social-media-color/youtube-color.svg" alt="YouTube Icon">
        </a>
        <a v-if="business.tiktokLink" :href="business.tiktokLink" target="_blank">
            <img src="/assets/icons/social-media-color/tiktok-color.svg" alt="Tiktok Icon">
        </a>
    </div>
</template>

<script setup>
const { business } = defineProps({
    business: { type: Object, required: true }
})

const linksGiven = computed(() => {
    return business.facebookLink || business.linkedinLink ||
                business.instagramLink || business.youtubeLink ||
                business.tiktokLink
})

</script>

<style scoped>
.some-container {
    display: flex;
    flex-direction: row;
    column-gap: var(--spacing);
    align-items: center;
    justify-content: center;
}
.some-container img {
    height: 2rem;
    width: auto;
}
</style>
