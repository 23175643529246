<template>
    <div
        v-for="name in distinctAttributeNames"
        :key="name"
    >
        <p class="mt-1">
            <b>{{ name }}</b>
        </p>
        <div
            v-for="item in getAttributeValuesByName(name)"
            :key="item.id"
        >
            <label v-if="item.customH1 === null">
                <input
                    v-model="selectedAttributes"
                    type="checkbox"
                    :value="item.id"
                >
                {{ item.value }}
            </label>
            <NuxtLink
                v-else
                :to="localePath({
                    name: 'categoryName-attributeName',
                    params: {
                        categoryName: route.params.categoryName,
                        attributeName: item.permanentName
                    }
                })"
            >
                {{ item.value }}
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
const { attributes } = defineProps({
    attributes: { type: Array, required: false, default: () => [] }
})

const localePath = useLocalePath()
const route = useRoute()

const distinctAttributeNames = []
attributes.forEach((attribute) => {
    if (!distinctAttributeNames.includes(attribute.name)) {
        distinctAttributeNames.push(attribute.name)
    }
})

function getAttributeValuesByName(name) {
    return attributes.filter(item => item.name === name)
}

const selectedAttributes = defineModel({ type: Array, default: () => [] })
</script>

<style scoped>
p { margin-bottom: 0.3rem; }
</style>
