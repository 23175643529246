<!-- eslint-disable vue/no-v-html -->
<template>
    <div>
        <p v-if="pending" aria-busy="true">
            Lade...
        </p>
        <p v-else-if="error">
            Content kann nicht angezeigt werden 😔
        </p>
        <div v-else v-html="content" />
    </div>
</template>

<script setup>
const props = defineProps({
    name: { type: String, required: true }
})

const content = ref('')
const lastUpdate = ref('')

const { data, pending, error } = await useIFetch(`/content/texts/${props.name}`, {
    pick: ['content', 'lastUpdate', 'seoTitle', 'seoDescription']
})
if (!error.value) {
    content.value = data.value.content
    lastUpdate.value = data.value.lastUpdate
}

if (data.value?.seoTitle && data.value?.seoDescription) {
    useHead({
        title: data.value?.seoTitle,
        meta: [{ name: 'description', content: data.value?.seoDescription }]
    })
}
</script>

<style scoped>
</style>
