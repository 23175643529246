<template>
    <div>
        <NavigationAppHeader />
        <LazySearchDarkOverlay
            v-if="sidebarOpen"
            :z-index="11"
        />
        <LazySearchAddressModalDialog v-if="showAddressModal" />
        <main :class="{ 'container': !fullWidth, 'ptb-1': !fullWidth }">
            <!-- TODO: change once all categories are added -->
            <NavigationCategorySidebar v-if="locale === 'at'" />
            <NuxtPage />
        </main>
        <NavigationDefaultFooter />
    </div>
</template>

<script setup>
const locale = useRuntimeConfig().public.locale
const { sidebarOpen, showAddressModal } = storeToRefs(useSearchStore())
const fullWidth = computed(() => ['/', '/login', '/registrieren'].includes(useRoute().path))

onMounted(() => {
    // add bing conversion tracking
    (function (w, d, t, r, u) {
        let f, n, i
        w[u] = w[u] || [], f = function () {
            const o = { ti: '97144133', enableAutoSpaTracking: true }
            o.q = w[u], w[u] = new UET(o), w[u].push('pageLoad')
        },
        n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () {
            const s = this.readyState
            s && s !== 'loaded' && s !== 'complete' || (f(), n.onload = n.onreadystatechange = null)
        },
        i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)
    })
    (window, document, 'script', '//bat.bing.com/bat.js', 'uetq')
})
</script>

<style scoped>
</style>
