<template>
    <section>
        <StaticContentLoader name="agb" />
    </section>
</template>

<script setup>
useHead({
    title: 'AGB'
})
</script>

<style scoped>
</style>
