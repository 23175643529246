<template>
    <div class="feedback-container">
        <CloseIcon @close="emit('close')" />
        <div v-if="status === null">
            <p class="title mt-1">
                Cool, du hast einen Anbieter gefunden!
            </p>
            <p>Sind die Informationen auf unserer Seite korrekt?</p>
            <div class="thumbs-container">
                <img
                    class="thumb"
                    src="assets/icons/thumbs/thumbs-up.svg"
                    alt="Alles korrekt"
                    title="Alles korrekt"
                    @click="thumbsUp"
                >
                <img
                    class="thumb"
                    src="assets/icons/thumbs/thumbs-down.svg"
                    alt="Fehler gefunden"
                    title="Fehler gefunden"
                    @click="status = 'bad'"
                >
            </div>
        </div>
        <div v-else-if="status === 'bad'">
            <p class="title mt-1">
                Was ist dir aufgefallen?
            </p>
            <p>
                Wir schätzen deine Ehrlichkeit. Bitte hilf uns zu verstehen, was schief gelaufen ist, damit wir es verbessern können.
            </p>
            <form @submit.prevent="sendFeedback">
                <textarea v-model="message" placeholder="Dein Feedback" minlength="5" maxlength="1000" />
                <span class="char-count">({{ messageLength }}/1000)</span>
                <button :disabled="messageTooShort" :aria-busy="submitting">
                    Abschicken
                </button>
            </form>
        </div>
        <div v-else>
            <p class="title mt-1">
                Vielen Dank für dein Feedback. Es ist unterwegs!
            </p>
            <img class="success" src="/feedback-submitted.webp" alt="Feedback ist unterwegs!" title="Feedback ist unterwegs!">
            <p class="mt-1">
                Deine Einsichten sind unschätzbar und helfen uns, deine Erfahrung zu verbessern. Wir schätzen deinen Beitrag, um unsere Plattform noch besser zu machen!
            </p>
            <LazyServiceActionButtons v-if="status === 'good'" :service="service" />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    service: { type: Object, required: true }
})
const emit = defineEmits(['close'])

const { $captureEvent, $backendApi } = useNuxtApp()
const toast = useToast()
const status = ref(null)
const message = ref('')
const submitting = ref(false)

const messageLength = computed(() => message.value.length)
const messageTooShort = computed(() => messageLength.value < 5)

async function sendFeedback () {
    submitting.value = true
    try {
        await $backendApi('/notifications/mobile-services', {
            method: 'POST',
            body: { message: message.value, serviceId: props.service.id }
        })
        toast.success('Vielen Dank für dein Feedback!')
        status.value = 'done'
    } catch (error) {
        handleBackendError({ error, toast })
    }
    submitting.value = false
}

function thumbsUp () {
    status.value = 'good'
    $captureEvent('mobile-service-feedback-good', { serviceId: props.service.id })
}
</script>

<style scoped>
.feedback-container {
    text-align: center;
    max-width: calc(100% - 2rem);
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: var(--white);
    border-radius: var(--border-radius);
    padding: 1rem;
    box-shadow: var(--shadow);
    z-index: 6;
    border: 1px solid var(--primary);
}
.title {
    font-size: 1.2rem;
    font-weight: bold;
}
.thumbs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
}
.thumb {
    transition: .3s ease-in-out;
    cursor: pointer;
}
.thumb:hover { transform: rotate(-10deg); }
.success { height: 7rem; }
textarea { margin-bottom: 0; }
.char-count {
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-bottom: var(--spacing);
}
@media (min-width: 768px) {
    .feedback-container {
        width: 25rem;
    }
}
</style>
