export function useLogout () {
    const { $backendApi } = useNuxtApp()
    const { signOut } = useAuth()
    const { refreshToken } = useAuthState()
    const loading = ref(false)

    async function logout () {
        loading.value = true
        await $backendApi('/auth/logout', { method: 'DELETE', body: { refresh_token: refreshToken.value } })
        await signOut({ callbackUrl: '/' })

        useFavoritesStore().$reset()
        useToast().success('Abgemeldet')
        loading.value = false
    }

    return {
        logout, loading
    }
}
