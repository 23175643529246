export const cities = {
    at: [
        { name: 'Wien', permanentName: 'wien' },
        { name: 'Graz', permanentName: 'graz-stadt' },
        { name: 'Salzburg', permanentName: 'salzburg-stadt' },
        { name: 'Innsbruck', permanentName: 'innsbruck' },
        { name: 'Linz', permanentName: 'linz' },
        { name: 'Klagenfurt', permanentName: 'klagenfurt' },
        { name: 'Bregenz', permanentName: 'bregenz' },
        { name: 'Eisenstadt', permanentName: 'eisenstadt' },
        { name: 'St. Pölten', permanentName: 'st-poelten' }
    ],
    de: [
        { name: 'Berlin', permanentName: 'berlin' },
        { name: 'Hamburg', permanentName: 'hamburg' },
        { name: 'München', permanentName: 'muenchen' },
        { name: 'Köln', permanentName: 'koeln' },
        { name: 'Frankfurt am Main', permanentName: 'frankfurt-am-main' },
        { name: 'Stuttgart', permanentName: 'stuttgart' },
        { name: 'Düsseldorf', permanentName: 'duesseldorf' },
        { name: 'Leipzig', permanentName: 'leipzig' },
        { name: 'Dortmund', permanentName: 'dortmund' },
        { name: 'Essen', permanentName: 'essen' },
        { name: 'Bremen', permanentName: 'bremen' },
        { name: 'Dresden', permanentName: 'dresden' },
        { name: 'Hannover', permanentName: 'hannover' },
        { name: 'Nürnberg', permanentName: 'nuernberg' },
        { name: 'Duisburg', permanentName: 'duisburg' },
        { name: 'Bochum', permanentName: 'bochum' },
        { name: 'Wuppertal', permanentName: 'wuppertal' },
        { name: 'Bielefeld', permanentName: 'bielefeld' },
        { name: 'Mannheim', permanentName: 'mannheim' },
        { name: 'Karlsruhe', permanentName: 'karlsruhe' }
    ]
}

export const viennaDistricts = [
    { name: '1010 Innere Stadt', permanentName: '1010-innere-stadt' },
    { name: '1020 Leopoldstadt', permanentName: '1020-leopoldstadt' },
    { name: '1030 Landstraße', permanentName: '1030-landstrasse' },
    { name: '1040 Wieden', permanentName: '1040-wieden' },
    { name: '1050 Margareten', permanentName: '1050-margareten' },
    { name: '1060 Mariahilf', permanentName: '1060-mariahilf' },
    { name: '1070 Neubau', permanentName: '1070-neubau' },
    { name: '1080 Josefstadt', permanentName: '1080-josefstadt' },
    { name: '1090 Alsergrund', permanentName: '1090-alsergrund' },
    { name: '1100 Favoriten', permanentName: '1100-favoriten' },
    { name: '1110 Simmering', permanentName: '1110-simmering' },
    { name: '1120 Meidling', permanentName: '1120-meidling' },
    { name: '1130 Hietzing', permanentName: '1130-hietzing' },
    { name: '1140 Penzing', permanentName: '1140-penzing' },
    { name: '1150 Rudolfsheim-Fünfhaus', permanentName: '1150-rudolfsheim-fuenfhaus' },
    { name: '1160 Ottakring', permanentName: '1160-ottakring' },
    { name: '1170 Hernals', permanentName: '1170-hernals' },
    { name: '1180 Währing', permanentName: '1180-waehring' },
    { name: '1190 Döbling', permanentName: '1190-doebling' },
    { name: '1200 Brigittenau', permanentName: '1200-brigittenau' },
    { name: '1210 Floridsdorf', permanentName: '1210-floridsdorf' },
    { name: '1220 Donaustadt', permanentName: '1220-donaustadt' },
    { name: '1230 Liesing', permanentName: '1230-liesing' }
]
