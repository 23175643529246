<template>
    <section>
        <h1>
            Nutzungsbedingungen
        </h1>
        <section>
            <h2>
                Einführung und Zweck der Nutzungsbedingungen
            </h2>
            <p>
                allekommen ist eine Plattform, die es Nutzern ermöglicht, einen einfachen Überblick zu
                Unternehmen zu bekommen, die Dienstleistungen vor Ort erbringen oder Lieferungen
                anbieten. Unser Ziel ist es, Nutzern zu helfen, informierte Entscheidungen zu treffen und die
                lokale Wirtschaft zu unterstützen. Wir sind stolz darauf, eine inklusive und respektvolle
                Gemeinschaft zu fördern und erwarten von allen Nutzern, dass sie sich an unsere Richtlinien
                halten.
            </p>
            <p>
                Diese Nutzungsbedingungen (im Folgenden "Bedingungen" genannt) gelten für die Nutzung
                der Dienste von allekommen e.U. (im Folgenden "Dienste" genannt), einschließlich der
                allekommen-Websites und der allekommen-Apps. Durch die Nutzung der Dienste stimmen
                Sie den Bedingungen zu und erklären sich damit einverstanden.
            </p>
        </section>
        <section>
            <h2>
                Definitionen und Interpretationen wichtiger Begriffe
            </h2>
            <p>
                In diesen Bedingungen haben die folgenden Begriffe die angegebenen Bedeutungen:
            </p>
            <ul>
                <li>
                    "allekommen", “allekommen e.U.”, “uns” oder “wir” bezieht sich auf
                    das Unternehmen, das die Dienste bereitstellt.
                </li>
                <li>
                    “Plattform” ist die von allekommen bereitgestellten Websites.
                </li>
                <li>
                    "Nutzer" bezieht sich auf jede Person, die die Dienste von allekommen nutzt.
                </li>
                <li>
                    “Unternehmen” bezieht sich auf die juristischen Personen, die auf allekommen gelistet
                </li>
                <li>
                    "Inhalte" bezieht sich auf alle Informationen, Daten, Texte, Bilder, Videos oder andere
                    Materialien, die von Nutzern oder von allekommen auf der Website bereitgestellt
                    werden.
                </li>
                <li>
                    "Konto" bezieht sich auf das Profil, das ein Nutzer oder Unternehmen erstellt, um auf
                    die Dienste von allekommen zuzugreifen.
                </li>
            </ul>
            <p>
                Sollten in diesen Bedingungen andere Begriffe oder Ausdrücke verwendet werden, die nicht
                ausdrücklich definiert sind, gelten die allgemein anerkannten Bedeutungen dieser Begriffe
                oder Ausdrücke.
            </p>
        </section>
        <section>
            <h2>Nutzung der Dienste von allekommen</h2>
            <p>
                Um eine angenehme und sichere Erfahrung für alle Nutzer und Unternehmen von allekommen
                zu gewährleisten, bitten wir, sich an unsere Regeln für den Inhalt zu halten. Dazu gehört,
                dass keine beleidigenden, gewaltverherrlichenden oder bedrohlichen Äußerungen verbreitet
                werden, keine Pornografie oder andere sexuell explizite Inhalte gepostet werden und keine
                illegalen Aktivitäten auf der Plattform betrieben werden. Verstöße gegen diese Regeln
                führen zu entsprechenden Maßnahmen bis hin zur Sperrung der betreffenden Personen
                oder Unternehmen.
            </p>
            <p>
                Die Grundfunktionen von allekommen sind für Nutzer und Unternehmen kostenfrei. Es werden
                jedoch entgeltliche Möglichkeiten zur Vermarktung oder für zusätzliche Leistungen
                angeboten. Diese sind klar erkennbar und können nur mit expliziter Zustimmung in Anspruch
                genommen werden.
            </p>
            <p>
                Nutzer und Unternehmen verpflichten sich, die Plattform nicht missbräuchlich zu verwenden.
                Das bedeutet, dass jedes unangemessene Verhalten und jede unangemessene
                Verwendung der Plattform zu unterlassen ist. Konkret bedeutet dies, dass keine illegalen
                Aktivitäten über die Plattform abgewickelt werden dürfen, keine Inhalt als die eigenen
                dargestellt werden dürfen wenn dies nicht der Fall ist und kein diffamierendes oder in
                anderer Weise rechtswidriges Material verbreitet werden darf. Zudem dürfen keine
                persönlichen Informationen wie Adressen oder Telefonnummern angegeben werden, wenn
                dies nicht explizit von allekommen gefordert wird. Es dürfen auch keine Programme oder
                technischen Hilfsmittel verwendet werden, um Daten von allekommen oder der Plattform zu
                extrahieren oder die den Betrieb in jeglicher Weise beeinträchtigen.
            </p>
            <p>
                Jeder Nutzer und jedes Unternehmen hat dafür zu sorgen, dass die angegebenen Daten der
                Wahrheit entsprechen. Die Nutzer und Unternehmen verpflichten sich, die Daten regelmäßig
                zu aktualisieren, sodass sie dem aktuellen Stand entsprechen.
            </p>
            <p>
                Wenn Nutzer oder Unternehmen gegen unsere Richtlinien verstoßen, können wir je nach
                Schwere des Verstoßes unterschiedliche Konsequenzen verhängen. Dazu können das
                Entfernen von Inhalten, das temporäre oder dauerhafte Sperren des Kontos sowie die
                Geltendmachung von Schadenersatzforderungen und weitere rechtliche Schritte gehören.
                Wir behalten uns das Recht vor, im Einzelfall angemessene Maßnahmen zu ergreifen.
                Wiederholte Verstöße gegen unsere Richtlinien führen zur dauerhaften Sperrung des
                Kontos.
            </p>
        </section>
        <section>
            <h2>
                Rechte an veröffentlichten Bildern und Informationen
            </h2>
            <p>
                Nutzer und Unternehmen akzeptieren, dass von ihnen auf der Plattform veröffentlichte Bilder
                und Informationen von allekommen auf der Plattform und für Marketingzwecke anderweitig
                verwendet werden dürfen.
            </p>
        </section>
        <section>
            <h2>
                Betrieb und Wartung
            </h2>
            <p>
                Um ein optimales Erlebnis schaffen zu können, liegt es im Ermessen von allekommen, über
                den Betrieb und die Wartung der Plattform zu entscheiden. Somit behalten wir uns vor,
                Wartungsarbeiten, Verbesserungen und Änderungen jeglicher Art an und auf der Plattform
                vorzunehmen. Somit kann nicht garantiert werden, dass die Plattform jederzeit zugänglich
                ist. Zusätzlich bedeutet dies, dass Veränderungen der Plattform von allekommen ohne
                jegliche Einwilligung vollzogen werden können.
                allekommen haftet nicht für entgangene Umsätze oder Ähnliches in Folge von Wartungen,
                Updates, Störungen, Ausfällen, Verfügbarkeitsproblemen oder anderweitigen Problemen mit
                der Plattform.
            </p>
            <p>
                allekommen haftet nicht für entgangene Umsätze oder Ähnliches in Folge von Wartungen,
                Updates, Störungen, Ausfällen, Verfügbarkeitsproblemen oder anderweitigen Problemen mit
                der Plattform.
            </p>
        </section>
        <section>
            <h2>
                Haftung und Gewährleistung
            </h2>
            <p>
                allekommen haftet nicht für die Erbringung einer auf der Plattform angebotenen Leistung oder
                für die auf der Plattform veröffentlichten Inhalte und beschränkt sich auf die Publikation von
                Dienstleistungen, Lieferungen und Online-Shops. Die Nutzer und Unternehmen befinden
                sich bei Vertragsabschlüssen oder anderen Abmachungen untereinander in keinem Vertragsverhältnis mit allekommen. Somit haftet allekommen nicht für Handlungen oder die
                Unterlassung von Handlungen von Nutzern oder Unternehmen.
            </p>
        </section>
        <section>
            <h2>
                Links zu anderen Webseiten
            </h2>
            <p>
                Auf der Plattform befinden sich Link zu Webseiten, die nicht zu allekommen gehören. Die dort
                bereitgestellten Inhalte können nicht von allekommen beeinflusst werden und sind somit
                unabhängig von allekommen. Wir haften nicht für Angebote, rechtswidrige oder ähnliche
                Inhalte, die sich auf einer Webseite befinden, die nicht zu allekommen gehört. Bei
                Bekanntwerden von Links zu Webseiten mit solchen Inhalten werden die jeweiligen
                Informationen und Links von uns unverzüglich von der Plattform gelöscht. Falls Sie auf
                rechtswidrige oder zweifelhafte Inhalte auf oder durch allekommen stoßen, bitten wir Sie, uns
                darüber zu informieren.
            </p>
        </section>
        <section>
            <h2>
                Änderungen der Nutzungsbedingungen
            </h2>
            <p>
                Da sich die Plattform als auch die Rechtslage stetig weiterentwickeln und verändern, behält
                sich allekommen das Recht vor, die Nutzungsbedingungen jederzeit anzupassen. Dies ist
                notwendig, um stets adäquate Nutzungsbedingungen bereitstellen zu können.
            </p>
        </section>
        <section>
            <h2>
                Datenschutz
            </h2>
            <p>
                allekommen ist bestrebt, den Schutz der Daten und Privatsphäre aller zu gewährleisten. Wir
                verarbeiten personenbezogene Daten nur in Übereinstimmung mit unserer
                Datenschutzerklärung und den geltenden Datenschutzgesetzen. Nutzer haben die
                Möglichkeit, ihre Privatsphäre-Einstellungen in ihrem Konto zu verwalten und festzulegen,
                welche Informationen öffentlich sichtbar sein sollen. Wir empfehlen Nutzern, sorgfältig zu
                überlegen, welche Informationen sie auf allekommen teilen und ihre
                Privatsphäre-Einstellungen regelmäßig zu überprüfen.
            </p>
        </section>
        <section>
            <h2>
                Gerichtsstand und anwendbares Recht
            </h2>
            <p>
                Für jegliche Rechtsstreitigkeiten ist ausschließlich das sachlich zuständige Gericht in A-Wien
                zuständig. Es findet ausschließlich österreichisches Recht Anwendung. Erfüllungsort ist der
                Unternehmenssitz von allekommen in A-1100 Wien.
            </p>
        </section>
        <section>
            <h2>
                Kontaktinformationen für Fragen oder Beschwerden
            </h2>
            <p>
                Wenn Sie auf der Plattform Inhalte entdecken, die gegen unsere Richtlinien verstoßen,
                bitten wir Sie, uns darüber zu informieren. Unser Team wird schnellstmöglich prüfen, ob der
                Inhalt gegen unsere Richtlinien verstößt und ihn gegebenenfalls entfernen. Bitte beachten
                Sie, dass bewusste Falschmeldungen möglicherweise zu Konsequenzen für den Melder
                führen können.
            </p>
            <p>
                Bitte kontaktieren Sie uns für jegliche Fragen oder Beschwerden unter <a href="mailto:michael@allekommen">michael@allekommen</a>.
            </p>
        </section>
    </section>
</template>

<script setup>
useHead({
    title: 'Nutzungsbedingungen'
})
</script>

<style scoped>
a { cursor: pointer;}
li { margin-bottom: var(--spacing); }
</style>
