<template>
    <Adsbygoogle
        style="display: block"
        ad-format="fluid"
        ad-layout-key="-7o+dk+2t+1-3g"
        ad-slot="2981455086"
    />
</template>

<script setup>
</script>

<style scoped>

</style>
