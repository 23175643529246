<template>
    <form v-if="!emailUnconfirmed" method="post" @submit.prevent="login">
        <label for="email">
            E-Mail Adresse *
            <input
                id="email"
                v-model="email"
                type="email"
                name="email"
                placeholder="E-Mail Adresse eingeben"
                required
            >
        </label>
        <div class="space-between">
            <span>Passwort *</span>
            <NuxtLink :to="localePath('reset-password')">
                Passwort vergessen?
            </NuxtLink>
        </div>
        <label for="password">
            <input
                id="password"
                v-model="password"
                type="password"
                name="password"
                placeholder="Passwort eingeben"
                required
            >
        </label>
        <button :aria-busy="submitting" type="submit">
            Einloggen
        </button>
    </form>
    <div v-else>
        <p>
            Falls du den Bestätigungslink nicht erhalten hast, kannst du ihn hier nochmals anfragen.
            Folge dann bitte dem Link, um Deine E-Mailadresse zu bestätigen.
        </p>
        <FormResendEmailConfirmation />
        <a class="centered" @click="emailUnconfirmed = false">
            Zurück
        </a>
    </div>
</template>

<script setup>
const localePath = useLocalePath()

const submitting = ref(false)
const email = ref('')
const password = ref('')
const emailUnconfirmed = ref(false)

onMounted(() => {
    emailUnconfirmed.value = useRoute().query.emailUnconfirmed
})

const favoritesStore = useFavoritesStore()
const { localFavoriteServices, localFavoriteBusinessCoupons } = storeToRefs(favoritesStore)
const toast = useToast()
const { data } = useAuthState()
const { signIn } = useAuth()
async function login () {
    submitting.value = true
    // Check if email and password are provided
    if (email.value === '' || password.value === '') {
        submitting.value = false
        return toast.error('Bitte Form vollständig ausfüllen.')
    }
    try {
        const localFavoriteServicesIds = localFavoriteServices.value.map(s => s.id)
        const localFavoriteBusinessCouponsIds = localFavoriteBusinessCoupons.value.map(s => s.id)
        await signIn(
            {
                email: email.value,
                password: password.value,
                favoriteServicesIds: localFavoriteServicesIds,
                favoriteBusinessCouponsIds: localFavoriteBusinessCouponsIds
            },
            { redirect: true, callbackUrl: localePath('profile') }
        )
        await favoritesStore.handleLogin(data.value, true)
        toast.success('Authentifizierung erfolgreich.')
    } catch (err) {
        submitting.value = false
        email.value = ''
        password.value = ''
        const msg = err.data.message
        if (msg === 'Bitte zuerst E-Mail bestätigen.') {
            emailUnconfirmed.value = true
        }
        toast.error(`Login fehlgeschlagen. ${msg}`)
    }
    submitting.value = false
}

</script>

<style scoped>
h1 {
    margin-bottom: 0;
    text-align: center;
}
span {
    display: block;
    cursor: pointer;
}
</style>
