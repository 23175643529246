<template>
    <div class="categories-flex-container">
        <CategoryTextCard
            v-for="category in categories"
            :key="keyPrefix + category.id"
            :category="category"
            @click="emit('close')"
        />
    </div>
</template>

<script setup>
const emit = defineEmits(['close'])
defineProps({
    categories: { type: Array, required: true },
    keyPrefix: { type: String, required: true }
})
</script>

<style scoped>
.categories-flex-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 100%;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
}
</style>
