<template>
    <div class="coupons-wrapper">
        <slot name="loginInviteCard" />
        <slot name="content" />
    </div>
</template>

<style scoped>
.coupons-wrapper {
    width: 100%;
    display: grid;
    column-gap: var(--spacing);
    row-gap: var(--spacing);
}
@media (min-width: 768px) {
    .coupons-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>
