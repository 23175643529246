<template>
    <section class="page">
        <NavigationGoBack />
        <h1 class="mt-1">
            E-Mail Einstellungen
        </h1>
        <p>
            <i>Hinweis: Du kannst Dich in jeder Marketing-E-Mail von der Kommunikation abmelden.</i>
        </p>
        <p v-if="pending" aria-busy="true">
            Lade Daten...
        </p>
        <p v-else-if="error">
            E-Mail Einstellungen konnten nicht abgefragt werden.
        </p>
        <div v-else class="optin-container">
            <div v-if="optinData.optout">
                Du hast Dich von allen Kommunikationen abgemeldet.
                <NuxtLink :to="localePath('contact')">
                    Anmelden
                </NuxtLink>
            </div>
            <div v-else-if="!optinData.email_confirmation">
                Bitte bestätige Deine E-Mail, bevor Du von uns kontaktiert wirst.
                <NuxtLink :to="localePath('contact')">
                    Bestätigung anfragen
                </NuxtLink>
            </div>
            <div v-else>
                <div v-for="(entry, index) in optinData.subscriptions" :key="index" class="optin-entry">
                    <strong>{{ entry.name }}</strong>
                    <p v-if="entry.status === 'SUBSCRIBED'" role="link" @click="unsubscribe(index)">
                        Abmelden
                    </p>
                    <p v-else>
                        Abgemeldet
                    </p>
                </div>
                <p class="mt-2">
                    Hier kannst Du Dich wieder anmelden
                </p>
                <div id="hubspotForm" class="form" />
            </div>
        </div>
    </section>
</template>

<script setup>
// common composables
const localePath = useLocalePath()
const toast = useToast()
const { $backendApi } = useNuxtApp()

const isHubspotLoaded = ref(false)
const { data: optinData, pending, error } = useIFetch('/hubspot/email-subscriptions')

onBeforeMount(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
        if (window.hbspt) {
            window.hbspt.forms.create({
                region: 'eu1',
                portalId: '26998599',
                formId: 'bb85a50d-bc3c-4d78-917d-f56ebf56d30e',
                target: '#hubspotForm'
            })
        }
        isHubspotLoaded.value = true
    })
})

async function unsubscribe (index) {
    try {
        const entry = optinData.value.subscriptions[index]
        await $backendApi(`/hubspot/unsubscribe/${entry.id}`)
        optinData.value.subscriptions[index].status = 'UNSUBSCRIBED'
        toast.success('Anfrage erfolgreich.')
    } catch (error) {
        toast.error('Anfrage fehlgeschalgen.')
    }
}

definePageMeta({
    middleware: 'auth'
})
useHead({
    title: 'Optin Einstellungen',
    meta: [{ key: 'noindex', name: 'robots', content: 'noindex' }]
})
</script>

<style scoped>
.optin-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
.optin-entry {
    display: grid;
    grid-template-columns: auto 6rem;
    column-gap: 0.5rem;
}
.optin-entry p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
