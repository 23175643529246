<template>
    <section>
        <StaticContentLoader name="ueber-uns" />
        <h2 class="mt-2">
            Unternehmensstandort
        </h2>
        <iframe
            class="location-map"
            style="border:0"
            loading="lazy"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            :src="locationForMaps"
        />
    </section>
</template>

<script setup>
import { GOOGLE_MAPS_PUBLIC_API_KEY } from '~/constants'

const locationForMaps = `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_PUBLIC_API_KEY}&q=Wiedner+Gürtel+13,+1100,+Wien`

useHead({
    title: 'Über uns'
})
</script>

<style scoped>
.location-map {
    width: 100%;
    height: 25rem;
}
</style>
