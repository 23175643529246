<template>
    <div>
        <h2>Leider konnten wir keine Treffer finden</h2>
        <p>
            Lass uns eine Anfrage da, wir melden uns bei dir! 🤝
            Es ist uns wichtig, dass du das findest, was du suchst. ❤️
        </p>
        <p class="mb-05">
            Das hilft uns, deine Anfrage schnell zu bearbeiten:
        </p>
        <ul>
            <li>✅ Was suchst du?</li>
            <li>✅ In welchem Ort suchst du?</li>
            <li>✅ Sonstige Angaben</li>
        </ul>
        <FormHubspotContact />
    </div>
</template>

<script setup>
const { $captureEvent } = useNuxtApp()
onMounted(() => {
    $captureEvent('no-search-results')
})
</script>

<style scoped>
ul {
    padding-left: 0;
}
ul li {
    list-style: none;
}
</style>
