export function useImpressionTracking (servicesToWatchRef) {
    const { $backendApi } = useNuxtApp()

    function postImpressions (services) {
        if (!services || services?.length === 0) {
            return
        }
        try {
            $backendApi('/mobile-services/impressions', {
                method: 'POST',
                body: { serviceIds: services.map(s => s.id) }
            })
        } catch (e) {
            console.error('Failed to send impressions', e)
            // Add more detailed error logging
            if (e.response) {
                console.error('Response data:', e.response.data)
                console.error('Response status:', e.response.status)
                console.error('Response headers:', e.response.headers)
            } else if (e.request) {
                console.error('Request data:', e.request)
            } else {
                console.error('Error message:', e.message)
            }
        }
    }

    onMounted(() => {
        postImpressions(servicesToWatchRef.value)
    })

    watch(() => servicesToWatchRef.value, () => {
        postImpressions(servicesToWatchRef.value)
    })
}
