<template>
    <p
        v-if="status === 'pending'"
        aria-busy="true"
        class="centered mt-2"
    >
        Lade Kategorie...
    </p>
    <p
        v-else-if="error"
        class="centered mt-2"
    >
        Kategorie kann nicht angezeigt werden 😔
    </p>
    <section v-else>
        <NavigationBreadCrumbs :breadcrumbs="breadcrumbs" />
        <h1>{{ category.customH1 || title }}{{ pageString }}</h1>
        <p v-if="category.topSnippet">
            {{ category.topSnippet }}
        </p>
        <SearchResultsPage
            :page-title="`Mobile ${category.name} Services auf allekommen.at`"
            :mandatory-search-parameters="mandatorySearchParameters"
            :attributes="attributes"
        />
        <section
            v-if="showLinksToLocations"
            class="mt-2"
        >
            <h2>Finde {{ category.name }} auch in anderen Städten</h2>
            <LinksToCategoryInLocationsContainer
                :category-name="category.name"
                :category-permanent-name="categoryName"
                :locations="cities[locale]"
            />
            <div v-if="locale === 'at'">
                <h2 class="mt-1">
                    Finde {{ category.name }} auch in anderen Wiener Bezirken
                </h2>
                <LinksToCategoryInLocationsContainer
                    :category-name="category.name"
                    :category-permanent-name="categoryName"
                    :locations="viennaDistricts"
                />
            </div>
        </section>
        <FaqsList
            class="mt-2"
            :faqs-list="faqs"
        />
        <div
            v-if="category.longSeoText && !route.query._page"
            class="mt-2"
            v-html="category.longSeoText"
        />
    </section>
</template>

<script setup>
definePageMeta({
    middleware: defineNuxtRouteMiddleware((to) => {
        if (import.meta.client) {
            return
        }
        const categoryName = to.params.categoryName
        if (specialLookup[categoryName]) {
            return navigateTo(`/${specialLookup[categoryName]}`, { redirectCode: 301 })
        }
    })
})

const route = useRoute()
const categoryName = route.params.categoryName

// fetch data from backend
const { data, status, error } = await useIFetch(`/categories/${categoryName}`)
if (error.value) {
    throw error.value
}
const { category, faqs, attributes } = data.value

const showLinksToLocations = !nationalCategories.includes(categoryName)

const { pageString } = useQueryPageString()
const title = `${category.name} in deiner Nähe`

const breadcrumbs = [{ name: category.name }]
const mandatorySearchParameters = { categoryId: category.id }

const config = useRuntimeConfig().public
const SPACES_URL = config.spacesUrl
const locale = config.locale

/** The CDN path to the category image. */
const categoryImage = `${SPACES_URL}/images/categories/${category.image}`

const { $captureEvent } = useNuxtApp()
onMounted(() => $captureEvent('category-view', { category: categoryName }))

const schemaOrgNodes = [defineBreadcrumb({ itemListElement: breadcrumbs })]
if (category.totalReviews > 0) {
    schemaOrgNodes.push(
        defineProduct({
            name: category.name,
            image: [categoryImage],
            aggregateRating: {
                '@type': 'AggregateRating',
                'ratingValue': category.averageRating,
                'bestRating': 5,
                'ratingCount': category.totalReviews
            }
        })
    )
}
useSchemaOrg(schemaOrgNodes)
useHead({
    title: `${category.seoTitle || title}${pageString.value}`,
    meta: [
        { name: 'og:image', content: categoryImage },
        { name: 'description', content: category.metaDescription || `✓ große Auswahl ✓ Beste Anbieter ✓ Kundenbewertungen ✓ Zuverlässig ✓ Bequem - ${category.name}, die zu deinem Standort kommen ✓ nach Hause ✓ ins Büro ✓ zu Verwandten` }
    ]
})
</script>
