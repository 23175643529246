<template>
    <div class="slider-wrapper">
        <div class="images-content">
            <img
                class="navigation-arrow left"
                src="assets/icons/arrows/chevron-left-border.svg"
                alt="Left arrow"
                loading="lazy"
                @click="slideLeft"
            >
            <div class="fade" :style="{ backgroundImage: `url(${currentImg})` }" />
            <img
                class="navigation-arrow right"
                src="assets/icons/arrows/chevron-right-border.svg"
                alt="Right arrow"
                loading="lazy"
                @click="slideRight"
            >
        </div>
        <div class="dots">
            <div
                v-for="i in images.length"
                :key="'image-' + i"
                class="dot"
                :class="{ active: currentIndex === i - 1 }"
            />
        </div>
    </div>
</template>

<script setup>
const config = useRuntimeConfig()
const SPACES_URL = config.public.spacesUrl

const props = defineProps({
    /** The array of image objects (image, id) to be displayed in the slider. */
    images: { type: Array, default: () => [] }
})

const currentIndex = ref(0) // The index of the currently displayed image in the slider.

/** Gets the URL of the currently displayed image. */
const currentImg = computed(() => {
    return cdnPath(props.images[currentIndex.value].image)
})

function cdnPath (image) {
    return `${SPACES_URL}/images/mobile_service/${image}`
}
/** Sets the index of the currently displayed image based on the number of images. */
function setIndex () {
    currentIndex.value = (currentIndex.value + props.images.length) % props.images.length
}
/** Moves the slider one image to the left. */
function slideLeft () {
    currentIndex.value--
    setIndex()
}
/** Moves the slider one image to the right. */
function slideRight () {
    currentIndex.value++
    setIndex()
}
</script>

<style scoped>
.images-content {
    width: 100%;
    height: 100%;
    column-gap: var(--spacing);
    display: grid;
    place-items: center;
    grid-template-columns: 1.5rem auto 1.5rem;
}
.fade {
    grid-column: 2;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--border-radius);
    transition: .3s all ease-in-out;
}
.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--spacing);
    gap: var(--spacing);
}
.dot {
    width: .5rem;
    height: .5rem;
    background-color: var(--secondary);
    border-radius: 50%;
    transition: .3s all ease-in-out;
}
.active {
    background-color: var(--primary);
    transform: scale(1.4);
}
.slider-wrapper {
    width: 100%;
    height: 12rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.navigation-arrow {
    background-color: var(--dark-blue);
    border-radius: var(--border-radius);
    padding: 0.3rem;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.navigation-arrow.left {
    left: 0;
}
.navigation-arrow.right {
    right: 0;
}
@media (min-width: 768px) {
    .slider-wrapper {
        height: 18rem;
    }
}
@media (min-width: 1024px) {
    .slider-wrapper {
        height: 24rem;
    }
}
</style>
