<template>
    <div class="ptb-1">
        <SearchResultsBar
            :total-results="services?.total || 0"
            :filter-count="filterCount"
            :page-title="pageTitle"
            :loading="status === 'pending'"
            @show-filters="filters?.toggleFilters()"
        />
        <SearchResultsCategories v-if="!ignoreSearchTerm" />
        <div class="search-page-container">
            <SearchFiltersContainer
                ref="filters"
                v-model="filterCount"
                :attributes="attributes"
            />
            <ServiceGridWrapper
                v-if="status === 'pending'"
                :show-address-note="false"
                :services-wrapper-small="true"
            >
                <template #content>
                    <ServiceSearchCardSkeletonLoader
                        v-for="n in 12"
                        :key="n"
                    />
                </template>
            </ServiceGridWrapper>
            <div v-else-if="error && error.statusCode !== 404">
                Es ist leider zu einem Fehler gekommen. Bitte versuche es später erneut.
            </div>
            <SearchNoResults v-else-if="(error && error.statusCode === 404) || services.total === 0" />
            <div
                v-else
                class="services"
            >
                <ServiceGridWrapper :services-wrapper-small="true">
                    <template #content>
                        <AdsB2BCard />
                        <ServiceSearchCard
                            v-for="(service, index) in services.services"
                            :key="'service-' + index"
                            :service="service"
                            :no-lazy-load="index < 5"
                        />
                        <AdsInFeedAd />
                    </template>
                </ServiceGridWrapper>
                <SearchPaginationLinks
                    v-if="services.total > 22"
                    class="mt-2"
                    :total="services.total"
                    :page="Number(query._page) || 1"
                    :per-page="22"
                />
                <AdsHorizontalAd
                    ad-slot="3913819842"
                    class="mt-2"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ALLEKOMMEN_ROOT } from '~/constants'

const props = defineProps({
    pageTitle: { type: String, required: true },
    mandatorySearchParameters: { type: Object, required: false, default: null },
    searchApiEndpoint: { type: String, required: false, default: '/search/' },
    ignoreSearchTerm: { type: Boolean, required: false, default: true },
    attributes: { type: Array, required: false, default: () => [] }
})

const route = useRoute()
const searchStore = useSearchStore()
const { lat, lon } = storeToRefs(searchStore)

const filters = ref(null)
const filterCount = ref(0)

const query = computed(() => {
    return {
        lat: lat.value,
        lon: lon.value,
        ...props.mandatorySearchParameters,
        ...route.query
    }
})
// fetch data from backend
const { data: services, status, error } = await useIFetch(props.searchApiEndpoint, { query })
const servicesToWatchRef = computed(() => services.value?.services)
useImpressionTracking(servicesToWatchRef)

const SPACES_URL = useRuntimeConfig().public.spacesUrl
const schemaOrgNodes = []
if (servicesToWatchRef.value) {
    schemaOrgNodes.push({
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        'itemListElement': generateItemListElementForServices(servicesToWatchRef.value, SPACES_URL)
    })
}
useSchemaOrg(schemaOrgNodes)

useHead({
    meta: () => {
        // noindex if no services found or filters are set (excluding _page parameter)
        const totalQueryParams = Object.keys(route.query).length
        return services.value?.total === 0 || services.value?.totalQueryParams > 1 || (totalQueryParams === 1 && !route.query._page)
            ? [{ name: 'robots', content: 'noindex' }]
            : []
    },
    link: () => {
        // the paginated version is the canonical link
        const pageQuery = route.query._page > 1 ? `?_page=${route.query._page}` : ''
        return { rel: 'canonical', href: `${ALLEKOMMEN_ROOT}${route.path}${pageQuery}` }
    }
})
</script>

<style scoped>
article {
    position: relative;
    background-color: var(--white);
    width: 100%;
}
.services {
    display: grid;
    align-items: flex-start;
}
@media (min-width: 425px) {
    article {
        width: 30rem;
        padding: var(--spacing);
    }
}
@media (min-width: 1024px) {
    .search-page-container {
        display: grid;
        column-gap: var(--spacing);
        grid-template-columns: 18rem 2fr;
    }
}
</style>
