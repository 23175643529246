<template>
    <img
        class="icon-container close-btn"
        :src="`/icons/xmark-solid-${color}.svg`"
        alt="Close Icon"
        loading="lazy"
        @click="emit('close')"
    >
</template>

<script setup>
const emit = defineEmits(['close'])
defineProps({
    color: { type: String, required: false, default: 'dark' }
})
</script>

<style scoped>
.close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}
</style>
