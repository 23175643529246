<template>
    <p>Du wirst gleich weitergeleitet...</p>
</template>

<script setup>
definePageMeta({
    middleware: defineNuxtRouteMiddleware((to) => {
        if (import.meta.client) {
            return
        }
        const config = useRuntimeConfig()
        return navigateTo(`${config.public.spacesUrl}/sitemaps/${to.params.sitemapName}`,
            { redirectCode: 301, external: true }
        )
    })
})

useHead({
    meta: [{ hid: 'robots', name: 'robots', content: 'noindex' }]
})
</script>
