<template>
    <section class="grid-center">
        <div id="form" class="form">
            <div class="mb-1 space-between">
                <h2>{{ formTitle }}</h2>
                <span v-if="useEmail" role="link" class="back-link" @click="useEmail = false">Zurück</span>
            </div>
            <div v-if="!useEmail">
                <div v-if="!showLogin" class="space-between buttons">
                    <button class="outline" @click="showLogin = true">
                        Ich bin User
                    </button>
                    <a class="outline" role="button" href="https://partner.allekommen.at/anbieter-werden">Ich bin Partner</a>
                </div>
                <div v-else>
                    <SocialLoginButtons />
                    <p class="mt-1 centered">
                        oder
                    </p>
                    <button class="login-btn outline" @click="useEmail = true">
                        <img src="assets/icons/mail-solid.svg" alt="Mit E-Mail anmelden">
                        {{ emailButtonText }}
                    </button>
                </div>
            </div>
            <slot v-else name="form" />
            <RegistrationNotesLinks />
            <p class="mt-2 space-between mb-0">
                <slot name="bottomLink" />
            </p>
        </div>
    </section>
</template>

<script setup>
const { emailButtonText } = defineProps({
    emailButtonText: { type: String, required: true },
    formTitle: { type: String, required: true }
})
const useEmail = ref(false)
const showLogin = ref(false)
</script>

<style scoped>
.grid-center {
    display: grid;
    place-items: center;
    min-height: 60vh;
    background: rgb(0,113,194);
    background: radial-gradient(circle, rgba(0,113,194,1) 0%, rgba(0,53,102,1) 100%);
}
.form {
    min-width: 20rem;
    padding: 1rem;
    background-color: var(--white);
}
h2 { margin-bottom: 0; }
.login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
}
.buttons {
    column-gap: var(--spacing);
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.back-link { cursor: pointer; }
@media (min-width: 768px) {
    .grid-center { height: 80vh; }
    .form {
        box-shadow: var(--shadow);
        border-radius: var(--border-radius);
    }
}
</style>
