export const useSearchStore = defineStore('search', () => {
    function $reset () {
        address.value = null
    }

    const route = useRoute()
    const router = useRouter()
    const { $clientSessionStorage, $captureEvent } = useNuxtApp()
    const setFilterQuery = ref(false)
    const address = ref(null)
    const showAddressModal = ref(false)

    // this does not trigger a refetch on search pages, but populates the address correctly
    /* onMounted(() => {
        address.value = $clientSessionStorage.getItem({ item: 'address', parseJson: true }) || null
    }) */

    const lat = computed(() => address.value?.lat)
    const lon = computed(() => address.value?.lon)

    function setLocationFilters (newValue) {
        address.value = newValue.address
        $clientSessionStorage.setItem({ item: 'address', value: address.value })
        if (newValue.address) {
            $captureEvent('address-set', { address: newValue.address?.displayName })
            if (setFilterQuery.value) {
                setFilterQuery.value = false
                router.replace({ path: route.path, query: { ...route.query, onlyComing: 'true' } })
            }
        }
    }

    function setShowAddressModal (newValue, setFilterInQuery) {
        showAddressModal.value = newValue
        setFilterQuery.value = setFilterInQuery
        newValue === true
            ? document.body.classList.add('no-scroll')
            : document.body.classList.remove('no-scroll')
    }

    const fetchedSuggestions = ref({ services: [], businesses: [], categories: [] })

    const sidebarOpen = ref(false)
    function setSidebarOpen (newValue) { sidebarOpen.value = newValue }

    return {
        $reset,
        lat,
        lon,
        address,
        sidebarOpen,
        showAddressModal,
        fetchedSuggestions,
        setShowAddressModal,
        setLocationFilters,
        setSidebarOpen
    }
})
