export default defineNuxtPlugin(() => {
    return {
        provide: {
            clientSessionStorage: {
                /**
                 * Get an item from session storage and optionally parse it as JSON.
                 * @param {string} item - the key of the item to retrieve from local storage
                 * @param {boolean} parseJson - whether to parse the retrieved item as JSON
                 * @return {any} the retrieved item from local storage, possibly parsed as JSON
                 */
                getItem ({ item, parseJson }) {
                    try {
                        const data = sessionStorage.getItem(item)
                        if (data === null) { return null }
                        return parseJson ? JSON.parse(data) : data
                    } catch (e) {}
                },
                /**
                 * Set an item in the session storage.
                 * @param {string} item - the key of the item
                 * @param {string} value - the value of the item
                 * @return {void}
                 */
                setItem ({ item, value }) {
                    try {
                        if (typeof value === 'object') { value = JSON.stringify(value) }
                        sessionStorage.setItem(item, value)
                    } catch (e) {}
                },
                /**
                 * Remove an item from the session storage.
                 * @param {string} item - the key of the item
                 * @return {void}
                 */
                removeItem ({ item }) {
                    try {
                        sessionStorage.removeItem(item)
                    } catch (e) { console.error(e) }
                }
            }
        }
    }
})
