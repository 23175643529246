<template>
    <div class="vertical-ad">
        <a
            v-if="isUnfilled"
            rel="sponsored"
            href="https://www.awin1.com/cread.php?s=3252353&v=10432&q=448834&r=1256987"
        >
            <img src="https://www.awin1.com/cshow.php?s=3252353&v=10432&q=448834&r=1256987">
        </a>
        <Adsbygoogle
            ref="verticalAd"
            class="google-ads"
            :ad-slot="adSlot"
        />
    </div>
</template>

<script setup>
const { adSlot } = defineProps({
    adSlot: { type: String, required: true }
})
const verticalAd = ref(null)
const isUnfilled = computed(() => verticalAd.value?.isUnfilled)
onMounted(() => {
    console.log('vertical isUnfilled', isUnfilled.value)
})
</script>

<style scoped>
.vertical-ad {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    min-height: 10rem;
    /* background-color: #e5e5e5;
    border-radius: var(--border-radius); */
}
.google-ads {
    width: 100%;
    min-height: 250px;
}
</style>
