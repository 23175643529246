<template>
    <p
        v-if="comingToYou"
        class="coming-to-you"
    >
        <img
            src="/assets/images/allekommen-icon.svg"
            alt="Allekommen Logo Icon"
            loading="lazy"
        >
        Kommt zu dir
    </p>
    <p
        v-else-if="!!address"
        class="coming-to-you"
    >
        <img
            src="assets/icons/location/crossed-location.svg"
            alt="Location crossed"
            loading="lazy"
            class="icon-crossed"
        >
        Kommt nicht zu dir
    </p>
    <p
        v-else
        class="address-not-set"
        @click="searchStore.setShowAddressModal(true)"
    >
        Kommt zu dir?<br>
        Standort angeben!
    </p>
</template>

<script setup>
defineProps({
    comingToYou: { type: Boolean, required: true }
})

const searchStore = useSearchStore()
const { address } = storeToRefs(searchStore)
</script>

<style scoped>
p {
    text-align: center;
    margin-bottom: 0;
    font-weight: bold;
}
.address-not-set {
    cursor: pointer;
    color: var(--primary);
    border: 1px dashed var(--primary);
    padding: 0.5rem;
    border-radius: var(--border-radius);
}
.coming-to-you {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    font-size: 1.5rem;
}
.coming-to-you img {
    height: 2rem;
    margin-right: 0.3rem;
}
</style>
