export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig()
    const { token } = useAuthState()
    const { refresh } = useAuth()

    const defaults = {
        baseURL: config.public.apiBase,
        onRequest ({ options }) {
            options.headers = {
                Authorization: `${token.value}`,
                'lv-api-key': `${config.public.apiHeaderKey}`
            }
        },
        async onResponseError ({ response }) {
            if (response.status === 401) {
                await refresh()
            }
        }
    }
    return {
        provide: {
            backendApi: $fetch.create(defaults)
        }
    }
})
