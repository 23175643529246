<template>
    <p
        v-if="status === 'pending'"
        aria-busy="true"
        class="centered mt-2"
    >
        Lade Kategorie...
    </p>
    <p
        v-else-if="error"
        class="centered mt-2"
    >
        Kategorie kann nicht angezeigt werden 😔
    </p>
    <section v-else>
        <NavigationBreadCrumbs :breadcrumbs="breadcrumbs" />
        <h1>{{ attribute.customH1 || title }}{{ pageString }}</h1>
        <p v-if="attribute.topSnippet">
            {{ attribute.topSnippet }}
        </p>
        <SearchResultsPage
            :page-title="attribute.customH1 || title"
            :mandatory-search-parameters="mandatorySearchParameters"
            :attributes="attributes"
        />
    </section>
</template>

<script setup>
const localePath = useLocalePath()
const route = useRoute()
const categoryName = route.params.categoryName
const attributeName = route.params.attributeName

// fetch data from backend
const { data, status, error } = await useIFetch(`/categories/${categoryName}/attributes`)
if (error.value) { throw error.value }
const { category, attributes } = data.value

const attribute = computed(() => {
    return attributes.find(item => item.permanentName === attributeName)
})

const { pageString } = useQueryPageString()
const title = `${attribute.value.value} in deiner Nähe`
const mandatorySearchParameters = { categoryId: category.id, attributes: [attribute.value.id] }

const breadcrumbs = [
    { name: category.name, item: localePath({ name: 'categoryName', params: { categoryName: categoryName } }) },
    { name: attribute.value.value }
]

useHead({
    title: `${attribute.value.seoTitle || title}${pageString.value}`,
    meta: [
        // { name: 'og:image', content: categoryImage },
        { name: 'description', content: attribute.value.metaDescription || `✓ große Auswahl ✓ Beste Anbieter ✓ Kundenbewertungen ✓ Zuverlässig ✓ Bequem - ${attribute.value.value}, die zu deinem Standort kommen ✓ nach Hause ✓ ins Büro ✓ zu Verwandten` }
    ]
})
</script>

<style scoped>

</style>
