export const useFavoritesStore = defineStore('favorites', () => {
    function $reset () {
        localFavoriteServices.value = []
        $clientSessionStorage.removeItem('localFavoriteServices')
        localFavoriteBusinessCoupons.value = []
        $clientSessionStorage.removeItem('localFavoriteBusinessCoupons')
        favoriteServices.value = []
        favoriteBusinessCoupons.value = []
    }

    const { $clientSessionStorage, $backendApi, $captureEvent } = useNuxtApp()
    const { data } = useAuthState()
    const favoriteServices = ref([])
    const favoritesFetched = ref(false)
    const favoriteBusinessCoupons = ref(data.value?.favoriteBusinessCoupons || [])

    const totalFavorites = computed(() => {
        return (favoriteServices.value?.length || 0) + (favoriteBusinessCoupons.value?.length || 0)
    })

    async function removeServiceFromFavorites (serviceId) {
        await $backendApi(`/favorites/services/${serviceId}`, { method: 'DELETE' })
        favoriteServices.value = favoriteServices.value.filter(service => service.id !== serviceId)
    }
    async function addServiceToFavorites (service) {
        await $backendApi(`/favorites/services/${service.id}`, { method: 'POST' })
        favoriteServices.value.unshift(service)
        $captureEvent('add-service-to-favorites')
    }
    async function removeBusinessCouponFromFavorites (couponId) {
        await $backendApi(`/favorites/coupons/business/${couponId}`, { method: 'DELETE' })
        favoriteBusinessCoupons.value = favoriteBusinessCoupons.value.filter(coupon => coupon.id !== couponId)
    }
    async function addBusinessCouponToFavorites (coupon) {
        await $backendApi(`/favorites/coupons/business/${coupon.id}`, { method: 'POST' })
        favoriteBusinessCoupons.value.unshift(coupon)
        $captureEvent('add-business-coupon-to-favorites')
    }

    async function handleLogin (user, forceRefresh) {
        if ((favoritesFetched.value && !forceRefresh) || !user) { return }
        if (user.favoriteServiceIds) {
            const { services } = await $backendApi('/search/', { query: { favoritesOfUserId: user.id } })
            favoriteServices.value = services
        }
        favoriteBusinessCoupons.value = user.favoriteBusinessCoupons
        $clientSessionStorage.removeItem('localFavoriteServices')
        $clientSessionStorage.removeItem('localFavoriteBusinessCoupons')
        favoritesFetched.value = true
    }

    const localFavoriteServices = ref([])
    const localFavoriteBusinessCoupons = ref([])

    onMounted(async () => {
        await handleLogin(data.value)
        localFavoriteServices.value = $clientSessionStorage.getItem({ item: 'localFavoriteServices', parseJson: true }) || []
        localFavoriteBusinessCoupons.value = $clientSessionStorage.getItem({ item: 'localFavoriteBusinessCoupons', parseJson: true }) || []
    })

    const totalLocalFavorites = computed(() => {
        return localFavoriteServices.value.length + localFavoriteBusinessCoupons.value.length
    })
    function removeServiceFromLocalFavourites (serviceId) {
        localFavoriteServices.value = localFavoriteServices.value.filter(service => service.id !== serviceId)
        $clientSessionStorage.setItem({ item: 'localFavoriteServices', value: localFavoriteServices.value })
    }
    function addServiceToLocalFavourites (service) {
        localFavoriteServices.value.unshift(service)
        $clientSessionStorage.setItem({ item: 'localFavoriteServices', value: localFavoriteServices.value })
        $captureEvent('add-service-to-local-favorites')
    }
    function removeBusinessCouponFromLocalFavourites (couponId) {
        localFavoriteBusinessCoupons.value = localFavoriteBusinessCoupons.value.filter(coupon => coupon.id !== couponId)
        $clientSessionStorage.setItem({ item: 'localFavoriteBusinessCoupons', value: localFavoriteBusinessCoupons.value })
    }
    function addBusinessCouponToLocalFavourites (coupon) {
        localFavoriteBusinessCoupons.value.unshift(coupon)
        $clientSessionStorage.setItem({ item: 'localFavoriteBusinessCoupons', value: localFavoriteBusinessCoupons.value })
        $captureEvent('add-business-coupon-to-local-favorites')
    }

    return {
        $reset,
        totalLocalFavorites,
        localFavoriteServices,
        localFavoriteBusinessCoupons,
        favoriteServices,
        favoriteBusinessCoupons,
        totalFavorites,
        handleLogin,
        removeServiceFromLocalFavourites,
        addServiceToLocalFavourites,
        removeServiceFromFavorites,
        addServiceToFavorites,
        removeBusinessCouponFromFavorites,
        addBusinessCouponToFavorites,
        removeBusinessCouponFromLocalFavourites,
        addBusinessCouponToLocalFavourites
    }
})
