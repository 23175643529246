<template>
    <section class="container ptb-1">
        <div v-if="error.statusCode === 404">
            <h1 class="centered">
                Stand heute gibt es diese Seite nicht!
            </h1>
        </div>
        <h1 v-else class="centered">
            Es ist ein Fehler passiert.
        </h1>
        <!--<pre>{{ error }}</pre>-->
        <button @click="clearError({ redirect: '/' })">
            Zur Startseite
        </button>
    </section>
</template>

<script setup>
import * as Sentry from '@sentry/vue'

const props = defineProps({ error: { type: Object, default: null } })

onMounted(() => {
    if (props.error.statusCode !== 404) {
        Sentry.captureException(props.error)
    }
    if (props.error.message.includes('ENOENT: no such file or directory')) {
        reloadNuxtApp()
    }
})
</script>
