<template>
    <p
        v-if="pending"
        aria-busy="true"
        class="centered mt-2"
    >
        Lade Service...
    </p>
    <p
        v-else-if="error"
        class="centered"
    >
        Service kann nicht angezeigt werden 😔
    </p>
    <section v-else>
        <NavigationBreadCrumbs :breadcrumbs="breadcrumbs" />
        <LazyServiceDataFeedback
            v-if="showFeedback"
            :service="service"
            @close="showFeedback = false"
        />
        <div class="content-container">
            <LazyServiceInactiveNote
                v-if="!service.active"
                :category-name="service.category.permanentName"
            />
            <section class="info-card">
                <div class="top-grid">
                    <div class="top-price">
                        <div
                            class="yellow"
                            role="button"
                            @click="useClickout(service.bookingLink, service.trackingLink)"
                        >
                            Jetzt bestellen
                        </div>
                        <strong v-if="service.minPrice">ab nur {{ service.minPrice.toFixed(2) }} €</strong>
                    </div>
                    <BigComingToYouText :coming-to-you="service.comesToYou" />
                </div>
                <h1>
                    {{ service.name }}
                    <ServiceSustainabilityBadge v-if="service.business.sustainabilityDescription" />
                </h1>
                <h2 class="business-name-servicepage">
                    <NuxtLink :to="businessLink">
                        {{ service.business.name }}
                    </NuxtLink>
                </h2>
                <span class="category-link mt-1">
                    Kategorie:
                    <NuxtLink :to="categoryLink">
                        {{ service.category.name }}
                    </NuxtLink>
                </span>
                <RatingStarsWithCount
                    :average-rating="service.rating"
                    :total-reviews="service.totalReviews"
                />
                <LazyServiceImageSlider
                    v-if="service.otherImages.length > 0"
                    class="mt-1"
                    :images="service.otherImages"
                />
                <LazyServiceOpeningHours
                    v-if="service.openingHours"
                    class="mt-1"
                    :hours="service.openingHours"
                />
            </section>
            <ServiceContactInfo
                class="contact-container"
                :service="service"
                :card-image="cardImage"
                @show-feedback="showFeedback = true"
            />
            <div
                v-if="business.hasOwner && business.enableContactForm"
                class="contact-form mt-2"
            >
                <span id="contactForm" />
                <h2>Kontaktiere den Anbieter</h2>
                <FormContactProvider :business-id="business.id" />
            </div>
            <AdsHorizontalAd
                v-if="showAds"
                ad-slot="8606490315"
                class="mt-1"
            />
            <InstagramEmbedd
                v-if="business.instagramLink"
                :instagram-username="business.instagramLink"
                class="mt-1"
            />
            <section class="mt-1">
                <h2>Beschreibung</h2>
                <div
                    class="description"
                    v-html="service.info"
                />
            </section>
            <section v-if="products.length > 0">
                <h2>Produkte & Dienstleistungen</h2>
                <div class="product-table">
                    <table role="grid">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <strong>Bezeichnung</strong>
                                </th>
                                <th
                                    class="price-td"
                                    scope="col"
                                >
                                    <strong>Preis in €</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(product, index) in products"
                                :key="'product-' + index"
                            >
                                <th scope="row">
                                    {{ product.name }}
                                </th>
                                <td class="price-td">
                                    € {{ product.price }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <section
                v-if="service.business.sustainabilityDescription"
                class="mt-1"
            >
                <h2>
                    Wir achten auf Nachhaltigkeit
                    <ServiceSustainabilityBadge />
                </h2>
                <div v-html="service.business.sustainabilityDescription" />
            </section>
            <FaqsList
                v-if="faqs"
                class="mt-2"
                :faqs-list="faqs"
            />
            <section
                v-if="service.business.coupons.length > 0"
                class="mt-2"
            >
                <h2>Gutscheine & Angebote des Anbieters</h2>
                <LazyBusinessCouponWrapper :coupons="service.business.coupons" />
            </section>
            <div class="reviews">
                <span id="reviews" />
                <ServiceReviewSection
                    class="mt-1"
                    :service="service"
                    @new-review="(review) => service.reviews.unshift(review)"
                />
            </div>
            <AdsHorizontalAd
                v-if="showAds"
                ad-slot="8606490315"
                class="mt-1"
            />
            <div
                v-if="otherServices.length > 0"
                class="mt-1"
            >
                <h2>Weitere Services von diesem Anbieter</h2>
                <LazyServiceGridWrapper :services-wrapper-small="true">
                    <template #content>
                        <ServiceSearchCard
                            v-for="(otherService, index) in otherServices"
                            :key="'service-' + index"
                            :service="otherService"
                        />
                    </template>
                </LazyServiceGridWrapper>
                <AdsHorizontalAd
                    v-if="showAds"
                    ad-slot="8606490315"
                    class="mt-2"
                />
            </div>
            <BusinessLinkBanner class="mt-4" />
        </div>
    </section>
</template>

<script setup>
const SPACES_URL = useRuntimeConfig().public.spacesUrl

const route = useRoute()
const localePath = useLocalePath()
const searchStore = useSearchStore()
const { lat, lon } = storeToRefs(searchStore)

const showFeedback = ref(false)

const faqs = ref([])
// we are not using .at(-1) because of poor browser support
const serviceNameParts = route.params.serviceName.split('-')
const serviceId = serviceNameParts[serviceNameParts.length - 1]
// fetch data from backend
const { data, pending, error } = await useIFetch(`/mobile-services/${serviceId}/public`, {
    query: { lat, lon } // timezone: userTimezone
})
if (import.meta.client && pending.value && data.value === null) {
    console.error('useIFetch pending and data is null service page')
}
if (error.value) { throw error.value }
if (data.value.faqs) { faqs.value = data.value.faqs }

const service = ref(data.value.service)
const otherServices = computed(() => data.value.otherServices)
useImpressionTracking(otherServices)
const products = data.value.products

const business = service.value?.business

const businessLink = localePath({
    name: 'provider-name',
    params: { name: `${business.permanentName}-${business.id}` }
})
const categoryLink = localePath({
    name: 'categoryName',
    params: { categoryName: service.value.category.permanentName }
})

const showAds = business?.productTier < 2

const breadcrumbs = [
    { name: 'Alle Anbieter', item: localePath('provider') },
    { name: business.name, item: businessLink },
    { name: service.value.category.name, item: categoryLink },
    { name: service.value.name }
]

const cardImage = getServiceImagePath(service.value, SPACES_URL)

const schemaOrgNodes = [defineBreadcrumb({ itemListElement: breadcrumbs })]
if (service.value?.totalReviews > 0) {
    schemaOrgNodes.push(
        defineProduct({
            name: service.value?.name,
            description: service.value?.info,
            image: [cardImage],
            aggregateRating: {
                '@type': 'AggregateRating',
                'ratingValue': service.value?.rating,
                'bestRating': 5,
                'ratingCount': service.value?.totalReviews
            }
        })
    )
}

if (otherServices.value) {
    schemaOrgNodes.push({
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        'itemListElement': generateItemListElementForServices(otherServices.value, SPACES_URL)
    })
}

const { city, zipCode } = business?.address
useSchemaOrg(schemaOrgNodes)
useHead({
    titleTemplate: `${service.value?.name} | ${business?.name}`,
    meta: () => {
        const metaTags = [
            { name: 'og:image', content: cardImage },
            { name: 'description', content: `${service.value?.category.name} von ${business.name} aus ${zipCode} ${city}. ✓ Bewertungen und mehr Infos auf allekommen.at` }
        ]
        if (!service.value?.active || !['allekommen', 'lieferando'].includes(service.value?.provider)) { metaTags.push({ name: 'robots', content: 'noindex' }) }
        return metaTags
    }
})
</script>

<style scoped>
@import url('/assets/css/business-service-page.css');
h1 { margin: 0; }
section { margin-bottom: 1rem; }
.category-link {
    display: block;
}
.business-name-servicepage {
    display: flex;
    column-gap: 0.5rem;
    font-weight: normal;
    font-size: 1rem;
    margin: 0;
    width: fit-content;
}
.product-table {
    max-height: 25rem;
    overflow-y: auto;
    background-color: var(--white);
    border-radius: var(--border-radius);
}
.price-td { width: 6rem; }
.reviews{ position: relative; }
#reviews {
    position: absolute;
    top: -7.5rem;
    left: 0;
}
.contact-form { position: relative; }
#contactForm { position: absolute; top: -8rem; }
.top-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.top-price {
    display: flex;
    flex-direction: row;
    column-gap: var(--spacing);
    justify-content: space-between;
    align-items: center;
}
.description { word-break: break-word; }
.span-ad { column-span: 1/4 }
@media (min-width: 425px) {
    .price-td { width: 8rem; }
}
@media (min-width: 768px) {
    .top-grid {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
</style>
