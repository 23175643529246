
// @ts-nocheck


export const localeCodes =  [
  "de"
]

export const localeLoaders = {
  "de": []
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de",
      "language": "de-AT"
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "about-us": {
      "de": "/ueber-uns"
    },
    "coupons": {
      "de": "/gutscheine"
    },
    "homevisits": {
      "de": "/hausbesuche"
    },
    "delivery": {
      "de": "/lieferservices"
    },
    "customer-support/index": {
      "de": "/kundensupport"
    },
    "customer-support/form": {
      "de": "/kundensupport/formular"
    },
    "customer-support/feedback": {
      "de": "/kundensupport/feedback"
    },
    "customer-support/delete-account": {
      "de": "/kundensupport/konto-loeschen"
    },
    "imprint": {
      "de": "/impressum"
    },
    "privacy": {
      "de": "/datenschutz"
    },
    "terms": {
      "de": "/nutzungsbedingungen"
    },
    "agb": {
      "de": "/agb"
    },
    "[categoryName]": {
      "de": "/[categoryName]"
    },
    "[categoryName]/in/[locationName]": {
      "de": "/[categoryName]/in/[locationName]"
    },
    "[categoryName]/[attributeName]": {
      "de": "/[categoryName]/[attributeName]"
    },
    "category/[categoryName]": {
      "de": "/kategorie/[categoryName]"
    },
    "reset-password/index": {
      "de": "/passwort-zuruecksetzen"
    },
    "reset-password/[passwordResetToken]": {
      "de": "/passwort-zuruecksetzen/[passwordResetToken]"
    },
    "register/index": {
      "de": "/registrieren"
    },
    "register/[emailConfirmToken]": {
      "de": "/registrieren/[emailConfirmToken]"
    },
    "search/index": {
      "de": "/suchen"
    },
    "business/[name]": {
      "de": "/business/[name]"
    },
    "locations/[locationName]": {
      "de": "/orte/[locationName]"
    },
    "mobile/service/[serviceName]": {
      "de": "/mobil/service/[serviceName]"
    },
    "mobile/[categoryName]/index": {
      "de": "/mobil/[categoryName]"
    },
    "mobile/[categoryName]/[locationName]": {
      "de": "/mobil/[categoryName]/[locationName]"
    },
    "profile/index": {
      "de": "/profil"
    },
    "profile/favorites": {
      "de": "/profil/favoriten"
    },
    "profile/locations": {
      "de": "/profil/meine-standorte"
    },
    "profile/settings/change-info": {
      "de": "/profil/einstellungen/info-aendern"
    },
    "profile/settings/change-password": {
      "de": "/profil/einstellungen/passwort-aendern"
    },
    "profile/settings/index": {
      "de": "/profil/einstellungen"
    },
    "profile/settings/optin-settings": {
      "de": "/profil/einstellungen/optin-einstellungen"
    },
    "profile/settings/delete-account": {
      "de": "/profil/einstellungen/konto-loeschen"
    },
    "press": {
      "de": "/presse"
    },
    "provider/index": {
      "de": "/anbieter"
    },
    "provider/[name]/index": {
      "de": "/anbieter/[name]"
    },
    "provider/[name]/mobile/[serviceName]": {
      "de": "/anbieter/[name]/mobil/[serviceName]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "language": "de-AT",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
