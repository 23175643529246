<template>
    <button
        class="outline icon-button"
        @click="emit('share')"
    >
        Teilen
        <img
            src="assets/icons/share-icons/share-primary.svg"
            alt="Diese Seite Teilen"
            loading="lazy"
        >
    </button>
</template>

<script setup>
const emit = defineEmits(['share'])
</script>

<style scoped>
.icon-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 0.3rem;
    width: fit-content;
}
</style>
