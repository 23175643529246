<template>
    <section>
        <NavigationGoBack />
        <h1 class="mt-1">
            Favoriten
        </h1>
        <div>
            <ul class="tabs">
                <li class="tab">
                    <button class="small" :class="{ outline: activeTab === 'angebote' }" @click="showTab('services')">
                        Service<span> ({{ favoriteServicesCountString }})</span>
                    </button>
                </li>
                <li class="tab">
                    <button class="small" :class="{ outline: activeTab === 'services' }" @click="showTab('angebote')">
                        Angebote<span> ({{ favoriteCouponsCountString }})</span>
                    </button>
                </li>
            </ul>

            <div v-if="activeTab === 'services'">
                <LazyServiceGridWrapper v-if="favoriteServicesCount > 0">
                    <template #loginInviteCard>
                        <LazyLoginInviteCard v-if="!isAuthenticated" />
                    </template>
                    <template #content>
                        <LazyServiceSearchCard
                            v-for="service in favoriteServicesToUse"
                            :key="'favoriteService-' + service.id"
                            :service="service"
                        />
                    </template>
                </LazyServiceGridWrapper>
                <div v-else>
                    <p>Du hast noch keine Services gespeichert.</p>
                    <button class="fit-content" role="button" @click="navigateTo(localePath('search'))">
                        Zur Inspiration
                    </button>
                </div>
            </div>

            <div v-else-if="activeTab === 'angebote'">
                <LazyBusinessCouponsWrapper v-if="favoriteBusinessCouponsCount > 0">
                    <template #loginInviteCard>
                        <LazyLoginInviteCard v-if="!isAuthenticated" />
                    </template>
                    <template #content>
                        <LazyBusinessCouponCode
                            v-for="coupon in favoriteBusinessCouponsToUse"
                            :key="'coupon-' + coupon.id"
                            :coupon="coupon"
                            :is-length-limited="true"
                        />
                    </template>
                </LazyBusinessCouponsWrapper>
                <div v-else>
                    <p>Du hast noch keine Angebote gespeichert.</p>
                    <button class="fit-content" role="button" @click="navigateTo(localePath('search'))">
                        Zur Inspiration
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
const route = useRoute()
const router = useRouter()
const isAuthenticated = useIsAuthenticated()
const favoritesStore = useFavoritesStore()
const {
    localFavoriteServices,
    localFavoriteBusinessCoupons,
    favoriteServices,
    favoriteBusinessCoupons
} = storeToRefs(favoritesStore)

const activeTab = ref(route.query.tab || 'services')

const favoriteServicesToUse = computed(() => {
    return isAuthenticated.value ? favoriteServices.value : localFavoriteServices.value
})
const favoriteServicesCount = computed(() => {
    return isAuthenticated.value ? favoriteServices.value.length : localFavoriteServices.value.length
})
const favoriteServicesCountString = computed(() => {
    return favoriteServicesCount.value > 20 ? '20+' : favoriteServicesCount.value.toString()
})

const favoriteBusinessCouponsToUse = computed(() => {
    return isAuthenticated.value ? favoriteBusinessCoupons.value : localFavoriteBusinessCoupons.value
})
const favoriteBusinessCouponsCount = computed(() => {
    return isAuthenticated.value ? favoriteBusinessCoupons.value.length : localFavoriteBusinessCoupons.value.length
})
const favoriteCouponsCountString = computed(() => {
    return favoriteBusinessCouponsCount.value > 20 ? '20+' : favoriteBusinessCouponsCount.value.toString()
})

function showTab (tabName) {
    activeTab.value = tabName
    router.replace({ query: { tab: tabName } })
}

useHead({
    title: 'Favoriten',
    meta: [{ hid: 'noindex', name: 'robots', content: 'noindex' }]
})
</script>

<style scoped>
h1 span {
    font-weight: normal;
    font-size: 1.25rem;
}
.tabs {
    padding: 0;
    display: flex;
    gap: var(--spacing);
}
.tab {
    width: 8.2rem;
    list-style-type: none;
}
.fit-content { width: fit-content; }
.outline {
    color: var(--primary);
    background-color: var(--white);
    border: 1px solid var(--primary);
}
@media (min-width: 576px) {
    .tab {
        width: 11.1rem;
    }
}
</style>
