<template>
    <div class="suggestions-container">
        <div
            v-for="(item, index) in items"
            :key="'s-' + index"
            class="suggestion"
            @click="emit('selected', item)"
        >
            <p>{{ item.name }}</p>
            <img
                v-if="item.comesToYou"
                class="suggestion-icon"
                src="/assets/images/allekommen-icon.svg"
                alt="Allekommen Location Icon"
                loading="lazy"
            >
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['selected'])
defineProps({
    items: { type: Array, required: true }
})
</script>

<style scoped>

</style>
