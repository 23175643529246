<template>
    <p
        v-if="pending"
        aria-busy="true"
        class="centered"
    >
        Lade Anbieter...
    </p>
    <div
        v-else-if="error"
        class="centered"
    >
        Anbieter kann nicht angezeigt werden 😔
    </div>
    <section v-else>
        <NavigationBreadCrumbs :breadcrumbs="breadcrumbs" />
        <div class="content-container">
            <div class="info-card">
                <div class="space-between">
                    <LazyBigComingToYouText
                        v-if="business.totalServices > 0"
                        :loading="pending"
                        :coming-to-you="Boolean(comesToYou)"
                    />
                    <ShareButton
                        class="share"
                        @share="toggleShare"
                    />
                </div>
                <h1 class="business-name">
                    {{ h1Heading }}
                </h1>
                <span v-if="business.totalServices > 0">
                    Mobile Services: {{ business.totalServices }}
                </span>
                <span v-if="categories.length > 0">
                    Aus den Kategorien:
                    <span
                        v-for="(object, index) in categories"
                        :key="index"
                    >
                        <NuxtLink
                            :to="localePath({
                                name: 'categoryName',
                                params: { categoryName: object.categoryPermanentName },
                            })"
                        >
                            {{ object.categoryName }}
                        </NuxtLink>
                        <span v-if="index !== categories.length - 1"> | </span>
                    </span>
                </span>
                <span
                    v-else-if="pending"
                    :aria-busy="true"
                >Kategorien...</span>
                <RatingStarsWithCount
                    :average-rating="business.averageRating"
                    :total-reviews="business.totalReviews"
                />
                <span v-if="business.topSnippet">{{ business.topSnippet }}</span>
            </div>
            <BusinessContactInfo
                class="contact-container"
                :business="business"
                :logo-path="logoPath"
            />
            <AdsHorizontalAd
                v-if="showAds"
                ad-slot="8606490315"
                class="mt-1"
            />
            <div
                v-if="business.coupons.length > 0"
                class="mt-2"
            >
                <h2>
                    Gutscheine & Angebote
                </h2>
                <LazyBusinessCouponWrapper :coupons="business.coupons" />
            </div>
            <h2 class="mt-2">
                Mobile Services
            </h2>
            <div class="services-container">
                <LazyServiceGridWrapper
                    v-if="pending"
                    :show-address-note="false"
                >
                    <template #content>
                        <ServiceSearchCardSkeletonLoader
                            v-for="n in 3"
                            :key="n"
                        />
                    </template>
                </LazyServiceGridWrapper>
                <LazyServiceGridWrapper
                    v-else-if="mobileServices.length > 0"
                    :show-address-note="true"
                    :services-wrapper-small="true"
                >
                    <template #content>
                        <LazyServiceSearchCard
                            v-for="(service, index) in mobileServices"
                            :key="'service-' + index"
                            :service="service"
                        />
                    </template>
                </LazyServiceGridWrapper>
                <BusinessNoServicesNote
                    v-else
                    :email="business.email"
                    :has-owner="business.hasOwner"
                    :business-name="business.name"
                />
            </div>
            <div
                v-if="business.hasOwner && business.enableContactForm"
                class="contact-form mt-2"
            >
                <span id="contactForm" />
                <h2>Kontaktiere den Anbieter</h2>
                <FormContactProvider :business-id="business.id" />
            </div>
            <AdsHorizontalAd
                v-if="showAds"
                ad-slot="8606490315"
                class="mt-1"
            />
            <InstagramEmbedd
                v-if="business.instagramLink"
                :instagram-username="business.instagramLink"
            />
            <h2 class="mt-2">
                Über den Anbieter
            </h2>
            <div v-html="business.description" />
            <FaqsList
                v-if="faqs"
                class="mt-2"
                :faqs-list="faqs"
            />
            <div
                id="map"
                class="location-heading mt-2"
            >
                <h2>Unternehmensstandort</h2>
                <a
                    role="button"
                    class="small outline route-link flex-center"
                    :href="`https://maps.google.com/maps?daddr=${businessAddress}`"
                    target="_blank"
                >
                    <img
                        class="route-icon"
                        src="assets/icons/route-solid.svg"
                        :alt="`Route planen nach ${businessAddress}`"
                    >
                    Route planen
                </a>
            </div>
            {{ businessAddress }}
            <iframe
                class="location-map"
                style="border:0"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                :src="locationForMaps"
            />
            <AdsHorizontalAd
                v-if="showAds"
                ad-slot="8606490315"
                class="mt-1"
            />
        </div>
        <BusinessLinkBanner class="mt-2" />
    </section>
</template>

<script setup>
import { GOOGLE_MAPS_PUBLIC_API_KEY } from '~/constants'

const SPACES_URL = useRuntimeConfig().public.spacesUrl

const localePath = useLocalePath()
const route = useRoute()

const searchStore = useSearchStore()
const { lat, lon } = storeToRefs(searchStore)

const business = ref(null)
const categories = ref([])
const faqs = ref([])
const ignoreBusiness = computed(() => business.value !== null)
// fetch data from backend
const { data, pending, error } = await useIFetch(`/businesses/${route.params.name}/public`, {
    query: { lat, lon, ignoreBusiness }
})
if (error.value) { throw error.value }
if (data.value.business) { business.value = data.value.business }
if (data.value.categories) { categories.value = data.value.categories }
if (data.value.faqs) { faqs.value = data.value.faqs }

const showAds = business.value?.productTier < 2

const h1Heading = business.value.customH1 || `${business.value.name} - Mobile Services, Gutscheine, Erfahrungen & Bewertungen`

const mobileServices = computed(() => data.value.services)
const comesToYou = computed(() => mobileServices.value?.some(service => service.comesToYou === true))
useImpressionTracking(mobileServices)

const logoPath = business.value?.logo ? `${SPACES_URL}/images/business/${business.value?.logo}` : null
const breadcrumbs = [
    { name: 'Alle Anbieter', item: localePath('provider') },
    // TODO: add location link
    { name: business.value.name }
]
const { streetName, houseNumber, zipCode, city, lat: latitude, lon: longitude } = business.value.address
const businessAddress = `${streetName} ${houseNumber}, ${zipCode}, ${city}`
const locationForMaps = `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_PUBLIC_API_KEY}&q=${businessAddress}`

function toggleShare() {
    handleShare(
        business.value.name + ' auf allekommen.at.\n' + business.value.description,
        window.location,
        business.value.name
    )
}

function getMetaDescription() {
    if (business.value.metaDescription) { return business.value.metaDescription }
    const uniqueCategoriesNames = categories.value?.map(object => object.categoryName)
    const category = uniqueCategoriesNames.length > 0 ? uniqueCategoriesNames[0] : ''
    return `${business.value?.name} bei dir vor Ort: ✔ ${business.value?.totalServices} Angebote ✔ Servicegebiet ✔ Bewertungen ➤ Beste ${category} Anbieter ⇒ allekommen.at`
}
const seoTitle = business.value?.seoTitle || `➤ Check: Kommt ${business.value?.name} zu dir? 🚚`

const schemaOrgNodes = [defineBreadcrumb({ itemListElement: breadcrumbs })]
if (business.value?.totalReviews > 0) {
    schemaOrgNodes.push(
        defineProduct({
            name: business.value?.name,
            description: business.value?.description,
            image: [logoPath],
            aggregateRating: {
                '@type': 'AggregateRating',
                'ratingValue': business.value?.averageRating,
                'bestRating': 5,
                'ratingCount': business.value?.totalReviews
            }
        })
    )
}
// TODO: add openingHoursSpecification if available
// https://unhead.unjs.io/schema-org/schema/local-business
schemaOrgNodes.push(
    defineLocalBusiness({
        name: business.value?.name,
        image: logoPath,
        telephone: business.value?.phone,
        address: {
            streetAddress: `${streetName} ${houseNumber}`,
            addressLocality: city,
            addressCountry: 'Austria', // TODO: add country
            postalCode: zipCode
        },
        geo: { latitude, longitude },
        url: business.value?.websiteLink
    })
)

if (mobileServices.value) {
    schemaOrgNodes.push({
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        'itemListElement': generateItemListElementForServices(mobileServices.value, SPACES_URL)
    })
}

useSchemaOrg(schemaOrgNodes)
useHead({
    titleTemplate: seoTitle,
    meta: [
        { name: 'og:image', content: logoPath },
        { name: 'description', content: getMetaDescription() }
    ]
})
</script>

<style scoped>
@import url('/assets/css/business-service-page.css');
h1 { margin: 0; }
section { margin-bottom: 1rem; }
.share { margin-left: auto; }
.business-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    width: fit-content;
    cursor: default;
}
.services-container {
    display: flex;
    flex-direction: column;
    row-gap: calc(2 * var(--spacing));
}
.location-map {
    width: 100%;
    height: 20rem;
    margin-top: 1rem;
}
.location-heading h2 { margin-bottom: 0; }
.location-heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    row-gap: 0.5rem;
    column-gap: var(--spacing);
}
.route-icon { width: 1rem; height: 1rem; }
.route-link {  column-gap: 0.3rem; }
.contact-form { position: relative; }
#contactForm { position: absolute; top: -8rem; }
</style>
