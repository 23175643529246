<template>
    <h1 class="centered mt-2">
        Diese Seite ist umgezogen... :)
    </h1>
</template>

<script setup>
definePageMeta({
    middleware: defineNuxtRouteMiddleware(async (to) => {
        if (process.client) { return }
        const categoryName = parseUrlParameter(to.params.categoryName).toLowerCase()
        let categoryRedirectName = categoryName
        if (specialLookup[categoryName]) {
            categoryRedirectName = specialLookup[categoryName]
        } else if (categoriesPermanentNameMapping[categoryName]) {
            categoryRedirectName = categoriesPermanentNameMapping[categoryName]
        }
        let locationRedirectName = to.params.locationName
        const locationParam = parseUrlParameter(to.params.locationName)
        if (!locationParam.includes('-') || locationParam.includes(' ')) {
            // no - means no id at the end = old location name -> fetch Backend for new name param
            const { data, error } = await useIFetch(`/locations/redirect/${encodeURIComponent(locationParam)}`)
            if (error.value) { throw error.value }
            locationRedirectName = data.value.locationName
        }
        return navigateTo(`/${categoryRedirectName}/in/${locationRedirectName}`, { redirectCode: 301 })
    })
})
</script>
