<template>
    <p v-if="comesToYou" class="coming-to-you">
        <img
            src="/assets/images/allekommen-icon.svg"
            alt="Allekommen logo"
            loading="lazy"
            class="comes-to-you-icon"
        >
        <strong>Kommt zu dir</strong>
    </p>
    <p v-else-if="searchStore.address" class="not-coming">
        <img
            src="assets/icons/location/crossed-location.svg"
            alt="Location crossed"
            loading="lazy"
            class="icon"
        >
        Kommt nicht zu dir
    </p>
</template>

<script setup>
defineProps({
    comesToYou: { type: Boolean, required: true }
})
const searchStore = useSearchStore()
</script>

<style scoped>
.comes-to-you-icon { height: 1.7rem; margin-right: 0.3rem; }
.not-coming { color: var(--dark-blue); }
.icon { height: 1.3rem; }
.coming-to-you {
    font-size: 1.1rem;
    color: var(--primary);
}
</style>
