import { EMPTY_HEART_ICON, FILLED_HEART_ICON } from '~/constants'

export function useFavoriteService (service) {
    const toast = useToast()
    const isAuthenticated = useIsAuthenticated()
    const favoritesStore = useFavoritesStore()
    const { localFavoriteServices, favoriteServices } = storeToRefs(favoritesStore)

    /** Indicates whether the service is a favorite for the logged-in user. */
    const isFavorite = computed(() => {
        return isAuthenticated.value
            ? favoriteServices.value.some(s => s.id === service.id)
            : localFavoriteServices.value.some(s => s.id === service.id)
    })
    /** Gets the icon path for favorites based on whether the service is a favorite or not. */
    const favoritesIcon = computed(() => {
        return isFavorite.value
            ? FILLED_HEART_ICON
            : EMPTY_HEART_ICON
    })

    /** Adds or removes the service from the user's favorites and handles authentication checks */
    async function toggleFavorites () {
        if (!isAuthenticated.value) {
            if (process.client && typeof sessionStorage === 'undefined') {
                toast.error(
                    'Zugriff auf lokalen Speicher nicht erlaubt, manche Funktionen sind nicht verfügbar. Bitte ändere deine Browsereinstellungen.',
                    { timeout: 6000 }
                )
                return
            }
            // save or remove service to local favorites
            isFavorite.value
                ? favoritesStore.removeServiceFromLocalFavourites(service.id)
                : favoritesStore.addServiceToLocalFavourites(service)
            return
        }
        try {
            // save or remove service to user favorites in DB
            isFavorite.value
                ? await favoritesStore.removeServiceFromFavorites(service.id)
                : await favoritesStore.addServiceToFavorites(service)
        } catch (error) {
            handleBackendError({ error, toast })
        }
    }
    return { favoritesIcon, isFavorite, toggleFavorites }
}
