<template>
    <section>
        <h1>{{ h1 }}</h1>
        <p>{{ topSnippet }}</p>
        <SearchBar v-model="searchTerm" />
        <div class="mt-1 grid-wrapper">
            <CategorySearchCard
                v-for="category in data.categories"
                :key="'category' + category.id"
                :category="category"
            />
        </div>
        <AdsHorizontalAd ad-slot="8606490315" class="mt-2" />
    </section>
</template>
<script setup>
import { ALLEKOMMEN_ROOT } from '~/constants'

const { categoryType, h1, topSnippet } = defineProps({
    categoryType: { type: String, required: true },
    h1: { type: String, required: true },
    topSnippet: { type: String, required: true }
})

const searchTerm = ref('')
const query = computed(() => {
    return {
        searchTerm: searchTerm.value,
        categoryType
    }
})
const { data, error } = await useIFetch('/categories/', { query })
if (error.value) { throw error.value }

const SPACES_URL = useRuntimeConfig().public.spacesUrl
const itemListElement = data.value?.categories.map((category, index) => {
    const item = {
        '@type': category.totalReviews > 0 ? 'Product' : 'Service',
        url: `${ALLEKOMMEN_ROOT}/${category.permanentName}`,
        name: category.name,
        image: getCategoryImagePath(category.image, SPACES_URL)
    }
    if (category.totalReviews > 0) {
        item.aggregateRating = {
            '@type': 'AggregateRating',
            ratingValue: category.averageRating,
            bestRating: 5,
            ratingCount: category.totalReviews
        }
    }
    return {
        '@type': 'ListItem',
        position: index + 1,
        item
    }
})
useSchemaOrg([{
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement
}])
</script>

<style scoped>
.grid-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;
}
@media (min-width: 768px) {
    .grid-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 1024px) {
    .grid-wrapper {
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>
