<template>
    <NoteBox class="business-link-container">
        <template #text>
            <p class="business-text">
                <strong>
                    Deine mobile Dienstleistung oder Lieferservice fehlt hier?
                </strong>
                Starte jetzt deine gratis Testphase!
            </p>
        </template>
        <template #button>
            <a href="https://partner.allekommen.at/" role="button" target="_blank">
                Jetzt informieren
            </a>
        </template>
    </NoteBox>
</template>

<style scoped>
.business-text {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.5rem;
}
@media (min-width: 768px) {
    .business-link-container {
        width: fit-content;
    }
}
</style>
