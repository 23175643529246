<template>
    <section>
        <NavigationGoBack />
        <h1 class="mt-1 centered">
            Schreib uns
        </h1>
        <FormHubspotContact class="form" />
    </section>
</template>

<script setup>
useHead({
    title: 'Kontakt'
})
</script>

<style scoped>
.form { margin-left: auto; margin-right: auto; }
</style>
