<template>
    <div class="rating-container">
        <RatingStarImages :unique-identifier="String(totalReviews)" :rating="averageRating" />
        <span>{{ truncatedRating }} {{ totalReviewsCount }}</span>
    </div>
</template>

<script setup>
const props = defineProps({
    averageRating: { type: Number, required: true },
    totalReviews: { type: Number, required: true },
    hideLongText: { type: Boolean, default: false }
})
const truncatedRating = computed(() => {
    return props.averageRating.toFixed(1)
})
const totalReviewsCount = computed(() => {
    return props.hideLongText
        ? `(${props.totalReviews})`
        : `(${props.totalReviews} ${props.totalReviews === 1 ? 'Bewertung' : 'Bewertungen'})`
})
</script>

<style scoped>
.rating-container {
    display: flex;
    flex-direction: row;
    column-gap: 0.3rem;
}
</style>
