<template>
    <LoginPage email-button-text="Mit E-Mail anmelden" form-title="User Login">
        <template #form>
            <FormEmailAndPassword />
        </template>
        <template #bottomLink>
            Noch kein Login?
            <NuxtLink :to="localePath('register')">
                Profil erstellen
            </NuxtLink>
        </template>
    </LoginPage>
</template>

<script setup>
definePageMeta({
    auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: '/'
    }
})

const localePath = useLocalePath()

useHead({
    title: 'Login'
})
</script>

<style scoped>
.grid-center {
    display: grid;
    place-items: center;
    padding: 2rem 0;
}
</style>
