<template>
    <div
        v-if="categories.length > 0"
        class="categories-search-results mb-1"
        :class="{
            'right-gradient': showRightArrow && showArrows,
            'left-gradient': showLeftArrow && !$device.isMobile && showArrows
        }"
    >
        <transition name="fade">
            <img
                v-if="showLeftArrow && !$device.isMobile && showArrows"
                class="arrow-left arrow"
                src="assets/icons/arrows/chevron-left-solid.svg"
                alt="Nach links scrollen"
                title="Nach links scrollen"
                loading="lazy"
                @click="scrollLeft(400)"
            >
        </transition>
        <div
            ref="categorySearchResult"
            class="text-cards-container"
        >
            <CategoryTextCard
                v-for="category in categories"
                :key="'search-suggestion-' + category.id"
                :category="category"
            />
        </div>
        <transition name="fade">
            <img
                v-if="showRightArrow && !$device.isMobile && showArrows"
                class="arrow-right arrow"
                src="assets/icons/arrows/chevron-right-solid.svg"
                alt="Nach rechts scrollen"
                title="Nach rechts scrollen"
                loading="lazy"
                @click="scrollRight(400)"
            >
        </transition>
    </div>
</template>

<script setup>
const showRightArrow = ref(true)
const showLeftArrow = ref(false)
const categorySearchResult = ref(null)

const searchStore = useSearchStore()
const { fetchedSuggestions } = storeToRefs(searchStore)

const locale = useRuntimeConfig().public.locale
const categories = computed(() => {
    return fetchedSuggestions.value.categories || trendingCategories[locale]
})
const showArrows = computed(() => {
    return categories.value.length > 4
})

/**
 * Scrolls the scroll container horizontally.
 * @param {number} scrollPx - The number of pixels to scroll.
 */
function scroll (scrollPx) {
    const container = categorySearchResult.value
    categorySearchResult.value.scrollBy(scrollPx, 0)
    const maxScrollLeft = container.scrollWidth - container.clientWidth
    setTimeout(() => {
        // use a 3px approximation for debounce
        if (Math.abs(container.scrollLeft - maxScrollLeft) < 3) {
            showRightArrow.value = false
        } else if (container.scrollLeft < 3) {
            showLeftArrow.value = false
        }
    }, 200)
}
function scrollRight (scrollPx) {
    showLeftArrow.value = true
    scroll(scrollPx)
}
function scrollLeft (scrollPx) {
    showRightArrow.value = true
    scroll(-scrollPx)
}
</script>

<style scoped>
.categories-search-results {
    position: relative;
}
.left-gradient:before, .right-gradient:after {
    top: 0;
    width: 2rem;
    height: 100%;
    position: absolute;
    content: "";
    display: block;
    z-index: 1;
    pointer-events: none;
    transition: opacity .2s ease-in-out;
}
.left-gradient:before {
    background: linear-gradient(270deg,#fff0 0,#fff 90%,#fff);
    left: -0.1rem;
}
.right-gradient:after {
    background: linear-gradient(90deg,#fff0 0,#fff 90%,#fff);
    right: -0.1rem;
}
.text-cards-container::-webkit-scrollbar {
    display: none;
}
.text-cards-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    overflow-x: auto;
    justify-content: flex-start;
    align-items: center;
    scroll-behavior: smooth;
}
.text-cards-container a {
    min-width: fit-content;
}
.arrow {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 2;
}
.arrow-left {
    left: 0;
    padding: 0.7rem 1rem 0.7rem 0.5rem;
}
.arrow-right {
    right: 0;
    padding: 0.7rem 0.5rem 0.7rem 1rem;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
@media (min-width: 768px) {
    .left-gradient:before, .right-gradient:after {
        width: 8rem;
    }
    .text-cards-container {
        padding-right: 3rem;
    }
}
@media (min-width: 1600px) {
    .text-cards-container {
        mask-image: none;
        padding-right: 0;
    }
}
</style>
