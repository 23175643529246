<template>
    <div
        :style="{ 'z-index': zIndex }"
        class="dark-overlay"
        @click="searchStore.setSidebarOpen(false)"
    />
</template>

<script setup>
defineProps({
    zIndex: { type: Number, required: false, default: 9 }
})
const searchStore = useSearchStore()
</script>

<style scoped>
.dark-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    z-index: 9;
}
</style>
