<template>
    <div>
        <LazySearchDarkOverlay v-if="localMenuOpen" @click="localMenuOpen = false" />
        <div class="search-input-container">
            <input
                v-model.trim="tempSearchTerm"
                type="search"
                name="search"
                maxlength="70"
                placeholder="Wer kommt zu mir?"
                autocomplete="off"
                :class="{ 'no-bg-image': tempSearchTerm?.length > 0 }"
                @keyup.enter="runSearch"
                @input="updateSearchTermAndSuggestions"
                @focus="localMenuOpen = true"
            >
            <button
                class="search-button"
                :class="{ yellow: address !== null }"
                @click="runSearch"
            >
                <img :src="`/icons/magnifying-glasses/magnifying-glass-${!address ? 'white' : 'solid'}.svg`" alt="Suchen" title="Suchen">
            </button>
            <LazySearchSuggestionsMenu
                v-if="localMenuOpen"
                :suggestions="fetchedSuggestions"
                :loading="loading"
                :show-suggestions="showSuggestions"
                @close="localMenuOpen = false"
            />
        </div>
    </div>
</template>

<script setup>
import { SEARCH_SUGGESTIONS_DEBOUNCE } from '~/constants'

const searchStore = useSearchStore()
const { address, fetchedSuggestions } = storeToRefs(searchStore)

const localePath = useLocalePath()
const route = useRoute()
const { $captureEvent, $backendApi } = useNuxtApp()
const router = useRouter()

onMounted(() => {
    // setting the search input value based on the query parameter
    const searchTerm = route.query.searchTerm
    if (searchTerm && searchTerm !== 'null') {
        tempSearchTerm.value = searchTerm
    }
})

function runSearch () {
    const value = tempSearchTerm.value
    localMenuOpen.value = false
    $captureEvent('search', { term: value })
    const newPath = localePath({
        name: 'search',
        query: {
            ...route.query,
            searchTerm: value || undefined
        }
    })
    route.path !== localePath('search')
        ? router.push(newPath)
        : router.replace(newPath)
}

const localMenuOpen = ref(false)
const tempSearchTerm = ref('')
const lastLength = ref(0)
const loading = ref(false)
const searchDebounce = ref(null)
const stopSearchSuggestions = ref(false)

const showSuggestions = computed(() => tempSearchTerm.value.length > 0)

function updateSearchTermAndSuggestions () {
    clearTimeout(searchDebounce.value)
    searchDebounce.value = setTimeout(async () => {
        const delta = tempSearchTerm.value.length - lastLength.value
        // check if suggestions are still possible (if searchTerm gets longer and with a
        // shorter one there were no suggestions already, there is no point in fetching further
        if (delta > 0 && stopSearchSuggestions.value) { return }
        lastLength.value = tempSearchTerm.value.length
        stopSearchSuggestions.value = false
        if (!tempSearchTerm.value) {
            loading.value = false
            fetchedSuggestions.value = { services: [], businesses: [], categories: [] }
            return
        }
        loading.value = true // this toggles the loading in SearchSuggestions
        if (lastLength.value.length > 3) {
            $captureEvent('search', { term: tempSearchTerm.value })
        }
        try {
            // get autocomplete values from backend
            fetchedSuggestions.value = await $backendApi('/search/suggestions', {
                query: {
                    searchTerm: tempSearchTerm.value,
                    lat: address.value?.lat,
                    lon: address.value?.lon
                }
            })
        } catch (err) {
            console.log(err)
        }
        loading.value = false // this toggles the loading in SearchSuggestions
    }, SEARCH_SUGGESTIONS_DEBOUNCE)
}
</script>

<style scoped>
.search-input-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
input[type=search] {
    margin: 0;
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    border: none;
}
.search-button {
    display: grid;
    place-items: center;
    width: fit-content;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    height: calc(1rem * var(--line-height) + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
    padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
}
.search-button img {
    height: 1.5rem;
    width: 1.5rem;
}
input[type=search].no-bg-image {
    background-image: none;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color);
    opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color);
}
::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--color);
}
@media (min-width: 1024px) {
    .search-input-container {
        min-width: 18rem;
    }
}
@media (min-width: 1440px) {
    .search-input-container {
        min-width: 30rem;
    }
}
</style>
