export const specialLookup = {
    'mobile-haushaltshilfe': 'haushaltshilfe',
    'mobiler-pflegedienst': 'personenbetreuung',
    'pflegedienst': 'personenbetreuung',
    'mobile-babysitter': 'babysitter',
    'mobiles-catering-koch': 'catering',
    'mobiler-koch': 'catering',
    'mobile-gaertner': 'gartenpflege',
    'mobiler-reinigungsservice': 'reinigungsfirma',
    'mobile-schaedlingsbekaempfung': 'schaedlingsbekaempfung',
    'mobiler-arzt': 'arzt-hausbesuch',
    'mobiler-nagelservice': 'mobiles-nagelstudio',
    'lebensmittel-lieferservice': 'lebensmittel-bestellen',
    'mobile-vermessungstechniker': 'vermessungstechniker',
    'mobile-tierbetreuung': 'tierbetreuung',
    'mobile-agentur': 'marketing-agentur',
    'mobiler-hochzeitsfotograf': 'hochzeitsfotograf',
    'reinigungsservice': 'reinigungsfirma',
    'blumen-lieferservice': 'blumen-liefern-lassen',
    'mobile-fitnesstrainer': 'personal-trainer',
    'mobile-nachhilfe': 'nachhilfe',
    'mobile-bestattung': 'bestattung',
    'drogerie-parfuemerie-lieferservice': 'kosmetik'
}

export const nationalCategories = [
    'tickets-gutscheine-kaufen', 'elektronik-technik-lieferservice', 'shopping-angebote', 'lampen'
]

export const trendingCategories = {
    at: [
        {
            id: 10,
            name: 'Haushaltshilfe',
            image: 'haushaltshilfe-hausbesuch-lieferplattform-10.webp',
            permanentName: 'haushaltshilfe'
        },
        {
            id: 133,
            name: 'Essen bestellen',
            image: 'essen-bestellen-hausbesuch-lieferplattform.webp',
            permanentName: 'essen-bestellen'
        },
        {
            id: 71,
            name: 'Personenbetreuung',
            image: 'personenbetreuung-hausbesuch-lieferplattform-71.webp',
            permanentName: 'personenbetreuung'
        },
        {
            id: 68,
            name: 'Nachhilfe',
            image: 'mobile-nachhilfe-hausbesuch-lieferplattform.webp',
            permanentName: 'nachhilfe'
        },
        {
            id: 16,
            name: 'Babysitter',
            image: 'babysitter-hausbesuch-lieferplattform-16.webp',
            permanentName: 'babysitter'
        },
        {
            id: 114,
            name: 'Tierbetreuung',
            image: 'tierbetreuung-hausbesuch-lieferplattform-114.webp',
            permanentName: 'tierbetreuung'
        },
        {
            id: 17,
            name: 'Kinderbetreuung',
            image: 'mobile-kinderbetreuung-hausbesuch-lieferplattform-17.webp',
            permanentName: 'mobile-kinderbetreuung'
        },
        {
            id: 56,
            name: 'Catering',
            image: 'catering-hausbesuch-lieferplattform-56.webp',
            permanentName: 'catering'
        },
        {
            id: 107,
            name: 'Lebensmittel bestellen',
            image: 'lebensmittel-bestellen-hausbesuch-lieferplattform-107.webp',
            permanentName: 'lebensmittel-bestellen'
        },
        {
            id: 83,
            name: 'Mobile Massage',
            image: 'mobile-massage-hausbesuch-lieferplattform.webp',
            permanentName: 'mobile-massage'
        },
        {
            id: 11,
            name: 'Reinigungsfirma',
            image: 'reinigungsservice-hausbesuch-lieferplattform-11.webp',
            permanentName: 'reinigungsfirma'
        },
        {
            id: 72,
            name: 'Schädlingsbekämpfung',
            image: 'schaedlingsbekaempfung-hausbesuch-lieferplattform-72.webp',
            permanentName: 'schaedlingsbekaempfung'
        },
        {
            id: 7,
            name: 'Mobile Gärtner',
            image: 'gartenpflege-hausbesuch-lieferplattform-7.webp',
            permanentName: 'gartenpflege'
        },
        {
            id: 121,
            name: 'Vermessungstechniker',
            image: 'vermessungstechniker-hausbesuch-lieferplattform-121.webp',
            permanentName: 'vermessungstechniker'
        },
        {
            id: 18,
            name: 'Mobiler Fotograf',
            image: 'mobiler-fotograf-hausbesuch-lieferplattform-18.webp',
            permanentName: 'mobiler-fotograf'
        },
        {
            id: 92,
            name: 'Mobile Physiotherapie',
            image: 'mobile-physiotherapie-hausbesuch-lieferplattform-92.webp',
            permanentName: 'mobile-physiotherapie'
        },
        {
            id: 45,
            name: 'Hochzeitsfotograf',
            image: 'mobiler-hochzeitsfotograf-hausbesuch-lieferplattform.webp',
            permanentName: 'hochzeitsfotograf'
        },
        {
            id: 134,
            name: 'Handwerker',
            image: 'handwerker-hausbesuch-lieferplattform-134.webp',
            permanentName: 'handwerker'
        },
        {
            id: 1,
            name: 'Blumen liefern lassen',
            image: 'blumen-lieferservice-hausbesuch-lieferplattform-1.webp',
            permanentName: 'blumen-liefern-lassen'
        },
        {
            id: 50,
            name: 'Marketing Agentur',
            image: 'mobile-agentur-hausbesuch-lieferplattform.webp',
            permanentName: 'marketing-agentur'
        }
    ],
    de: [
        {
            id: 1,
            name: 'Haushaltshilfe',
            image: 'haushaltshilfe-hausbesuch-lieferplattform.webp',
            permanentName: 'haushaltshilfe'
        },
        {
            id: 2,
            name: 'Essen bestellen',
            image: 'essen-bestellen-hausbesuch-lieferplattform.webp',
            permanentName: 'essen-bestellen'
        },
        {
            id: 6,
            name: 'Blumen liefern lassen',
            image: 'blumen-liefern-lassen-hausbesuch-lieferplattform.webp',
            permanentName: 'blumen-liefern-lassen'
        },
        {
            id: 3,
            name: 'Babysitter',
            image: 'babysitter-hausbesuch-lieferplattform.webp',
            permanentName: 'babysitter'
        },
        {
            id: 4,
            name: 'Lebensmittel bestellen',
            image: 'lebensmittel-bestellen-hausbesuch-lieferplattform.webp',
            permanentName: 'lebensmittel-bestellen'
        }
    ]
}

export const deliverServiceCategories = [
    {
        id: 20,
        name: 'Getränke Lieferservice',
        image: 'getraenke-lieferservice-hausbesuch-lieferplattform.webp',
        permanentName: 'getraenke-lieferservice'
    },
    {
        id: 1,
        name: 'Blumen liefern lassen',
        image: 'blumen-lieferservice-hausbesuch-lieferplattform-1.webp',
        permanentName: 'blumen-liefern-lassen'
    },
    {
        id: 59,
        name: 'Elektronik & Technik',
        image: 'elektronik-technik-lieferservice-hausbesuch-lieferplattform-59.webp',
        permanentName: 'elektronik-technik-lieferservice'
    },
    {
        id: 133,
        name: 'Essen bestellen',
        image: 'essen-bestellen-hausbesuch-lieferplattform.webp',
        permanentName: 'essen-bestellen'
    },
    {
        id: 107,
        name: 'Lebensmittel bestellen',
        image: 'lebensmittel-bestellen-hausbesuch-lieferplattform-107.webp',
        permanentName: 'lebensmittel-bestellen'
    },
    {
        id: 136,
        name: 'Tickets & Gutscheine',
        image: 'tickets-gutscheine-kaufen-hausbesuch-lieferplattform-136.webp',
        permanentName: 'tickets-gutscheine-kaufen'
    },
    {
        id: 135,
        name: 'Shopping-Angebote',
        image: 'shopping-angebote-hausbesuch-lieferplattform-135.webp',
        permanentName: 'shopping-angebote'
    }
]

export const categoriesPermanentNameMapping = {
    'schädlingsbekämpfung': 'schaedlingsbekaempfung',
    'videoproduktion': 'mobiler-videofilmer',
    'kinderbetreuung': 'mobile-kinderbetreuung',
    'fotograf': 'mobiler-fotograf',
    'lebensberatung': 'mobile-lebensberatung',
    'einrichtungsberatung': 'mobile-einrichtungsberatung',
    'finanzberatung': 'mobile-finanzberatung',
    'anlageberatung': 'mobile-finanzberatung',
    'bankberatung': 'mobile-finanzberatung',
    'musikstunden': 'mobiler-musikunterricht',
    'cocktails': 'mobile-barkeeper-cocktails',
    'arzt': 'arzt-hausbesuch',
    'friseur': 'mobiler-friseur',
    'pilates': 'personal-trainer',
    'tanzen': 'mobiler-tanzlehrer',
    'tierarzt': 'mobiler-tierarzt',
    'tiertrainer': 'mobiles-tiertraining',
    'umzugsdienst': 'mobiler-umzugsdienst',
    'yoga': 'mobiles-yoga',
    'babywellness': 'mobile-babywellness',
    'drogerie & parfümerieartikel': 'kosmetik',
    'drogerie-parfuemerieartikel': 'kosmetik',
    'hausbetreuung': 'mobile-hausbetreuung',
    'coaching': 'mobiles-coaching',
    'bewerbungstraining': 'mobiles-coaching',
    'dj': 'mobiler-dj',
    'haushaltsgeräte': 'haushaltsgeraete-lieferservice',
    'haushaltsgeraete': 'haushaltsgeraete-lieferservice',
    'haushaltsartikel': 'haushaltsartikel-lieferservice',
    'technik & service': 'mobile-it-techniker',
    'technik-service': 'mobile-it-techniker',
    'agentur': 'marketing-agentur',
    'abschleppdienst': 'mobiler-abschleppdienst',
    'catering & koch': 'catering',
    'catering-koch': 'catering',
    'entsorgung': 'mobile-entsorgung',
    'fitnesstrainer': 'personal-trainer',
    'naturheilkunde': 'mobile-naturheilkunde',
    'optiker': 'mobile-optiker',
    'pflege': 'personenbetreuung',
    'altenbetreuung': 'personenbetreuung',
    'schaedlingsbekaempfung': 'schaedlingsbekaempfung',
    'fahrzeugreinigung': 'mobile-fahrzeugreinigung',
    'homöopathie': 'mobile-homoeopathie-behandlung',
    'homoeopathie': 'mobile-homoeopathie-behandlung',
    'getränke': 'getraenke-lieferservice',
    'getraenke': 'getraenke-lieferservice',
    'mobiler-hochzeitsfotograf': 'hochzeitsfotograf',
    'karriereberatung': 'mobile-karriereberatung',
    'elektronik & technik': 'elektronik-technik-lieferservice',
    'elektronik-technik': 'elektronik-technik-lieferservice',
    'schreibwaren': 'schreibwaren-lieferservice',
    'sicherheit': 'mobile-sicherheit-dienstleistungen',
    'musikband': 'mobile-musiker',
    'sportartikel': 'sportartikel-lieferservice',
    'montage': 'mobiler-monteur',
    'zimmerer': 'mobiler-zimmerer',
    'kleidung': 'kleidung-lieferservices',
    'styling': 'mobiler-stylist',
    'universalhandel': 'universal-lieferservice',
    'fliesenleger': 'mobile-fliesenleger',
    'nägel': 'mobiles-nagelstudio',
    'naegel': 'mobiles-nagelstudio',
    'versicherungsberatung': 'mobile-versicherungsberatung',
    'massage': 'mobile-massage',
    'schornsteinfeger': 'mobiler-schornsteinfeger',
    'reparatur & service': 'mobiler-bootsreparaturservice',
    'reparatur-service': 'mobiler-bootsreparaturservice',
    'bootsreinigung': 'mobile-bootsreinigung',
    'renovierung': 'mobile-renovierer',
    'medikamente': 'medikamente-lieferservice',
    'energetik': 'mobile-energetiker',
    'fassade': 'mobile-fassadenmontage',
    'heimwerkerbedarf': 'heimwerkerbedarf-lieferservice',
    'physiotherapie': 'mobile-physiotherapie',
    'psychologe': 'mobiler-psychologe',
    'vorhangservice': 'mobiler-vorhangservice',
    'bodenleger': 'mobile-bodenleger',
    'dachdecker': 'mobile-dachdecker',
    'glaser': 'mobile-glaser',
    'stilberatung': 'mobile-stilberatung',
    'installateur': 'mobiler-installateur',
    'maler': 'mobiler-maler',
    'ofenbau': 'mobiler-ofenbau',
    'ernährungscoach': 'mobile-ernaehrungscoach',
    'ernaehrungscoach': 'mobile-ernaehrungscoach',
    'kosmetiker': 'mobile-kosmetiker',
    'hochzeit': 'mobile-hochzeitsplanung',
    'reinigung': 'reinigungsservice',
    'elektriker': 'mobile-elektriker',
    'solaranlage': 'mobiler-solaranlagen-techniker',
    'lebensmittel': 'lebensmittel-bestellen',
    'kurier': 'mobiler-kurier',
    'fußpflege': 'mobile-fusspflege',
    'fusspflege': 'mobile-fusspflege',
    'geschenkartikel': 'geschenkartikel-lieferservice',
    'textilreinigung': 'mobile-textilreinigung',
    'eventzubehör': 'mobiles-eventzubehoer',
    'eventzubehoer': 'mobiles-eventzubehoer',
    'gärtner': 'gartenpflege',
    'gaertner': 'gartenpflege',
    'fahrzeugreparatur': 'mobile-fahrzeugreparatur',
    'gerätereparatur': 'mobile-geraetereparatur',
    'geraetereparatur': 'mobile-geraetereparatur',
    'blumen': 'blumen-liefern-lassen',
    'bäckerei & konditorei': 'baeckerei-konditorei-lieferservice',
    'baeckerei-konditorei': 'baeckerei-konditorei-lieferservice'
}
