<template>
    <div v-if="hasSuggestions" class="all-suggestions-container">
        <div v-if="suggestions.categories.length > 0">
            <p class="small-title">
                Kategorien
            </p>
            <SearchCategorySuggestions :categories="suggestions.categories" key-prefix="c-suggestions" @close="emit('close')" />
        </div>
        <div v-if="suggestions.services.length > 0">
            <p class="small-title">
                Mobile Dienstleistungen & Lieferservices
            </p>
            <LazySearchSuggestionItems :items="suggestions.services" @selected="selectServiceSuggestion" />
        </div>
        <div v-if="suggestions.businesses.length > 0">
            <p class="small-title">
                Anbieter
            </p>
            <LazySearchSuggestionItems :items="suggestions.businesses" @selected="selectBusinessSuggestion" />
        </div>
    </div>
    <p v-else class="centered">
        Keine Treffer
    </p>
</template>

<script setup>
const props = defineProps({
    suggestions: { type: Object, required: true }
})
const emit = defineEmits(['close'])

const hasSuggestions = computed(() => {
    return Object.values(props.suggestions).some(value => value.length > 0)
})

const router = useRouter()
const localePath = useLocalePath()
function selectServiceSuggestion (service) {
    emit('close')
    router.push(localePath({
        name: 'provider-name-mobile-serviceName',
        params: {
            name: `${service.businessPermanentName}-${service.businessId}`,
            serviceName: `${service.permanentName}-${service.id}`
        },
        query: { coming: service.comingToYou }
    }))
}
function selectBusinessSuggestion (business) {
    emit('close')
    router.push(localePath({
        name: 'provider-name',
        params: { name: `${business.permanentName}-${business.id}` }
    }))
}
</script>

<style scoped>
.all-suggestions-container {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing);
}
</style>
