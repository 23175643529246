<template>
    <section v-if="faqs.length">
        <h2>Häufig gestellte Fragen</h2>
        <AccordionComponent
            v-for="faq in faqs"
            :key="faq.id"
            :title="faq.question"
            :use-layout="false"
            :white-theme="false"
        >
            <template #links>
                <div v-html="faq.answer" />
            </template>
        </AccordionComponent>
    </section>
</template>

<script setup>
const { faqsList, faqScope } = defineProps({
    faqsList: { type: Array, required: false, default: null },
    faqScope: { type: String, required: false, default: null }
})

const faqs = ref(faqsList)

if (!faqsList) {
    const { data } = await useIFetch(`/content/faqs/${faqScope}`)
    faqs.value = data.value.faqs
}

if (faqs.value.length) {
    useSchemaOrg(
        faqs.value.map(faq => defineQuestion({ name: faq.question, answer: faq.answerWithoutTags }))
    )
}
</script>

<style scoped>

</style>
