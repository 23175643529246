<template>
    <NuxtLink :to="link" role="button" class="outline">
        {{ category.name }}
    </NuxtLink>
</template>

<script setup>
const props = defineProps({
    category: { type: Object, required: true }
})

const localePath = useLocalePath()
const link = localePath({
    name: 'categoryName',
    params: { categoryName: props.category.permanentName }
})
</script>

<style scoped>
a {
    background-color: var(--primary-100);
    color: var(--primary-hover)
}
</style>
