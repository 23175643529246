<template>
    <section class="page">
        <NavigationGoBack />
        <div class="space-between mt-1">
            <h1>Meine Standorte</h1>
            <button v-if="!addressForm" @click="addressForm = true">
                Hinzufügen
            </button>
            <button v-else class="outline" @click="addressForm = false">
                Abbrechen
            </button>
        </div>
        <div v-if="addressForm">
            <label for="street-name">
                Bezeichnung
                <input
                    id="street-name"
                    v-model="name"
                    placeholder="z.B. Zuhause, Büro..."
                    type="text"
                >
            </label>
            <SearchAddressFromApi :allow-submit="name.length > 1" @address-selected="createNewUserLocation" />
        </div>
        <div class="mt-2">
            <div
                v-for="location in data?.savedLocations"
                :key="location.id"
                class="deletable-line mt-1"
            >
                <span class="address-line">📍 {{ location.name }}, {{ location.address.displayName }}</span>
                <button class="outline small" @click="deleteLocation(location.id)">
                    Löschen
                </button>
            </div>
        </div>
    </section>
</template>

<script setup>
definePageMeta({
    middleware: 'auth'
})

const toast = useToast()
const { getSession } = useAuth()
const { data } = useAuthState()
const { $backendApi } = useNuxtApp()
const addressForm = ref(false)
const name = ref('')

async function createNewUserLocation (selectedAddress) {
    // return if the name is not filled in
    if (name.value.length < 2) {
        return toast.error('Bitte Bezeichnung eingeben!')
    }
    // return if a location with this name already exists
    if (data.value.savedLocations.find(loc => loc.name === name.value)) {
        return toast.error('Du hast bereits einen Standort mit dieser Bezeichnung.')
    }
    addressForm.value = false
    try {
        await $backendApi('/locations/user-location/new', {
            method: 'POST',
            body: { ...selectedAddress, name: name.value }
        })
        toast.success('Standort erfolgreich gespeichert!')
        await getSession()
    } catch (error) {
        handleBackendError({ error, toast })
    }
    name.value = ''
}

async function deleteLocation (locationId) {
    try {
        await $backendApi(`/locations/user-location/${locationId}`, { method: 'DELETE' })
        toast.success('Standort erfolgreich gelöscht!')
        await getSession()
    } catch (error) {
        handleBackendError({ error, toast })
    }
}

useHead({
    title: 'Meine Standorte',
    meta: [{ hid: 'noindex', name: 'robots', content: 'noindex' }]
})
</script>

<style scoped>
h1 { margin-bottom: 0; }
.deletable-line {
    display: grid;
    grid-template-columns: auto 5.5rem;
    column-gap: 0.5rem;
}
.deletable-line span, .deletable-line a, .deletable-line p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.deletable-line { align-items: center; }
.space-between button { width: fit-content; }
</style>
