<template>
    <section class="page">
        <NavigationGoBack />
        <h1 class="mt-1">
            Konto Einstellungen
        </h1>
        <p>Hier kannst du dein Profil konfigurieren.</p>
        <div class="flex-row">
            <NuxtLink role="button" class="outline" :to="localePath('profile-settings-change-password')">
                Passwort ändern
            </NuxtLink>
            <NuxtLink role="button" class="outline" :to="localePath('profile-settings-optin-settings')">
                Optin Einstellungen
            </NuxtLink>
            <NuxtLink role="button" :to="localePath('profile-settings-change-info')">
                Profildaten ändern
            </NuxtLink>
            <NuxtLink :to="localePath('profile-settings-delete-account')">
                Konto löschen
            </NuxtLink>
        </div>
    </section>
</template>

<script setup>
const localePath = useLocalePath()

useHead({
    title: 'Einstellungen',
    meta: [{ name: 'robots', content: 'noindex' }]
})
</script>

<style scoped>
.flex-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: var(--spacing);
    row-gap: var(--spacing);
}
</style>
